import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate result block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateResultBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	const outputNames = block.config.output.fields.map((output) => {
		return output.alias.toLowerCase();
	});

	const checkedOutputs = [];
	// get all block inputs
	const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](block.guid);
	
	// check if atleast one field is choosen or full_output is checked
	if(!Validation.isNonEmptyArray(block.config.output.fields) && !block.config.full_output){
		setBlockError(
			t('fb.flowErrors.noFieldsOrFullOutput', {
				block: t('fb.blockTypes.result'),
			})
		);
	} else {
		// for loop used becouse if we use await inside
		for (const output of block.config.output.fields) {
			// check if alias is duplicated or used more than one time
			if (
				!checkedOutputs.includes(output.alias.toLowerCase()) &&
				!Validation.stringIsUniqueInList(output.alias.toLowerCase(), outputNames)
			) {
				setBlockError(
					t('fb.flowErrors.duplicatedAlias', {
						fieldName: Field.getNameAsPath(output.name),
						block: t('fb.blockTypes.result'),
					})
				);
			}
	
			// check if output alias exist
			if (!Validation.stringNotEmpty(output.alias)) {
				setBlockError(
					t('fb.flowErrors.aliasIsEmpty', {
						block: t('fb.blockTypes.result'),
					})
				);
			}
	
			// check if output alias has atleast 2 characters
			if (!Validation.stringHasAtleastTwoCharacters(output.alias)) {
				setBlockError(
					t('fb.flowErrors.aliasIsShort', {
						fieldName: Field.getNameAsPath(output.name),
						block: t('fb.blockTypes.result'),
					})
				);
			}
	
			// check if output alias without whitspacing
			if (!Validation.stringWithoutSpacing(output.alias)) {
				setBlockError(
					t('fb.flowErrors.aliasContainsSpace', {
						fieldName: Field.getNameAsPath(output.name),
						block: t('fb.blockTypes.result'),
					})
				);
			}
	
			// check if output field exist in the flow vars
			if (!Validation.outputExists(output, blockInputs)) {
				setBlockError(
					t('fb.flowErrors.inputOfOutputNotExist', {
						mappingObject: Field.getNameAsPath(output.name),
						block: t('fb.blockTypes.result'),
					})
				);
			}
	
			// add output to already checked outputs array
			checkedOutputs.push(output.alias.toLowerCase());
		}
	}
	return output;
};
