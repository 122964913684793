import clone from './cloneObject';
import isObject from './isObject';
import { isEmpty } from 'lodash';

const PROP_DIVIDER = '|';
const DEFAULT_RETURN = false;

/**
 * Gets property value on given object
 *
 * @param {String} prop
 *  Name of the requested property. Parents and children are
 *  separated by '|'. So 'parent|child' checks for the existence
 *  of object[parent][child]
 *
 * @param {Object} object
 *  Object to check for property
 *
 * @param {Any} defaultReturn
 *  Return value to use if property is not found
 *
 * @returns {Any}
 *  String (property value) if property found, otherwise
 *  the given value of defaultReturn
 */
const getProp = (prop, object, defaultReturn = false) => {
	if (!propExists(prop, object)) return defaultReturn;

	let result = defaultReturn;

	// set scope to check for property to given object
	let scope = object;

	// loop over props
	splitProps(prop).forEach((value) => {
		// update scope to found property, to be able
		// to search new scope for children if applicable
		scope = scope[value];

		// set result to scope, this can be overwritten again
		// in next every() step if applicable
		result = scope;
	});

	return result;
};

/**
 * Sets property to given value on given object
 *
 * @param {String} prop
 *  Name of the property to set. Parents and children are
 *  separated by '|'. So 'parent|child' sets value
 *  of object[parent][child]
 *
 * @param {Object} object
 *  Object to set property on
 *
 * @param {Any} value
 *  Value to use for property
 *
 * @returns {Boolean}
 *  Returns true if property was successfully updated, false if not
 */
const setProp = (prop, object, value, createIfNotExist = false) => {
	// return false if prop does not exist and should not be created
	if (!propExists(prop, object) && !createIfNotExist) return false;

	let result = true;

	// get split props
	const props = splitProps(prop);

	// remove and save last prop from array
	const lastProp = props.pop();

	// set scope to check for property to given object
	let scope = object;

	// loop over props (minus the last popped one)
	props.forEach((value) => {
		// check if error has not yet occured
		if (result) {
			// check for prop
			if (typeof scope[value] !== 'object') {
				// if needed, create prop as object
				if (createIfNotExist) {
					scope[value] = {};
				} else {
					// if not, set result to false
					result = false;
				}
			}

			if (result) {
				// update scope to found property, to be able
				// to search new scope for children if applicable
				scope = scope[value];
			}
		}
	});

	if (
		result &&
		(createIfNotExist || typeof scope[lastProp] !== 'undefined')
	) {
		// set property on scope to given value
		// scope is an object at this point, so changing
		// a property affects the original object parameter
		scope[lastProp] = value;
	} else {
		result = false;
	}

	// return result
	return result;
};

const getPropUsingMeta = (objMeta, prop, object) => {
	let defaultReturn = DEFAULT_RETURN;
	const props = [];
	let scope = objMeta;

	const propExists = splitProps(prop).every((value) => {
		let meta = getProp(value, scope);
		if (meta && meta.name) {
			scope = meta.children || {};
			defaultReturn = getDefaultValue(meta);
			props.push(meta.name);
			return true;
		} else {
			return false;
		}
	});

	if (!propExists || props.length < 1) return false;

	return getProp(joinProps(props), object, defaultReturn);
};

/**
 * Sets property to given value on given object
 *
 * @param {Object} objMeta
 *  Object that contains the naming of properties of
 *  an object that is retrieved from the Flowbuilder API
 *
 * @param {String} prop
 *  Name of the property to set. Parents and children are
 *  separated by '|'. So 'parent|child' sets value
 *  of object[parent][child]
 *
 * @param {Object} object
 *  Object to set property on
 *
 * @param {Any} value
 *  Value to use for property
 *
 * @returns {Boolean}
 *  Returns true if property was successfully updated, false if not
 */
const setPropUsingMeta = (
	objMeta,
	prop,
	object,
	value,
	createIfNotExist = false
) => {
	const props = [];
	let scope = objMeta;

	const propExists = splitProps(prop).every((value) => {
		let meta = getProp(value, scope);
		if (meta && meta.name) {
			scope = meta.children || {};
			props.push(meta.name);
			return true;
		} else {
			return false;
		}
	});

	// return false if prop does not exist on meta object
	if (!propExists || props.length < 1) return false;

	// return result
	return setProp(joinProps(props), object, value, createIfNotExist);
};

const normalizeObject = (obj, objMeta) => {
	const newObject = {};

	for (const key in objMeta) {
		const prop = key;
		const meta = objMeta[key];
		let value = clone(getPropUsingMeta(objMeta, key, obj));
		
		if (meta.children) {
			const childMeta = clone(meta.children);

			if (meta.recursive) {
				childMeta[key] = meta;
			}

			value = normalize(value, childMeta);
		}

		newObject[prop] = value;
	}

	return newObject;
};

const normalizeArray = (objects, objMeta) => {
	const newArray = [];

	objects.forEach((obj) => {
		newArray.push(normalize(obj, objMeta));
	});

	return newArray;
};

const normalize = (input, objMeta) => {
	let result = input;

	if (isObject(input)) {
		result = normalizeObject(input, objMeta);
	} else if (Array.isArray(input)) {
		result = normalizeArray(input, objMeta);
	}

	return result;
};

/**
 * Constructs and returns an object based on the
 * meta 'blueprint' and the given normalized version
 *
 * @param {Object} obj
 *  Normalized object to make constructed
 *  version of
 *
 * @param {Object} objMeta
 *  Meta description of wanted object
 *
 * @param {String} selector
 *  A selector word to omit certain properties
 *  that are only relevant in specific circumstances
 *
 * @returns {Object}
 *  Constructed Object
 */
const constructObject = (obj, objMeta, selector = false) => {
	const newObject = {};

	// loop over properties in meta description
	for (const key in objMeta) {
		// key in meta is property name
		// in normalized object
		const prop = key;
		// get options
		const meta = objMeta[key];
		// get prop name to use in result
		const name = meta.name;

		// omit props if it is marked to always be omitted, or
		// if it should only be used for certain occasions different
		// from current one
		// N.B.: if NO SELECTOR is given, props that are restricted to
		// certain selectors will NOT be omitted, but added as any other prop
		if (
			meta.omitOnConstruct ||
			(selector &&
				Array.isArray(meta.restrictToSelectors) &&
				meta.restrictToSelectors.indexOf(selector) === -1)
		)
			continue;

		// get value of prop on normalized object
		let value = clone(getProp(prop, obj, meta.default));

		if (meta.children) {
			const childMeta = clone(meta.children);

			if (meta.recursive) {
				childMeta[key] = meta;
			}

			// get constructed children if applicable
			value = construct(value, childMeta, selector);
		}

		// check if prop should be omitted because it is empty
		if (!propShouldBeOmitted(meta, value)) {
			// set prop on result object
			setProp(name, newObject, value, true);
		}
	}
	return newObject;
};

const constructArray = (objects, objMeta, selector = false) => {
	const newArray = [];

	objects.forEach((obj) => {
		newArray.push(construct(obj, objMeta, selector));
	});

	return newArray;
};

const construct = (input, objMeta, selector = false) => {
	let result = input;

	if (isObject(input)) {
		result = constructObject(input, objMeta, selector);
	} else if (Array.isArray(input)) {
		result = constructArray(input, objMeta, selector);
	}

	return result;
};

/**
 * Creates and returns a new object based on
 * given meta data and the starting object
 *
 * @param {Object} objMeta
 *  Meta object to create object for
 *
 * @param {Object} starter
 *  Object with props to add to created object
 *
 * @returns {Object}
 */
const create = (objMeta, starter = {}) => {
	const newObject = normalize({}, objMeta);

	if (starter && !isEmpty(starter)) {
		Object.assign(newObject, starter);
	}

	return newObject;
};

/********************/
/* INTERNAL HELPERS */
/********************/
/**
 * Returns array of prop names, based on given
 * string of prop names concatted with '|'
 *
 * @param {String} prop
 *  String of concatted prop names
 *
 * @returns {Array}
 *  Props split by '|'
 */
const splitProps = (prop) => {
	return prop.split(PROP_DIVIDER);
};

/**
 * Returns string of concatted prop names,
 * based on given array of prop names
 *
 * @param {Array} props
 *  Array of prop names
 *
 * @returns {String}
 *  Props joined by '|'
 */
const joinProps = (props) => {
	return props.join(PROP_DIVIDER);
};

/**
 * Checks if property exists on given object
 *
 * @param {String} prop
 *  Name of the requested property. Parents and children are
 *  separated by '|'. So 'parent|child' checks for the existence
 *  of object[parent][child]
 *
 * @param {Object} object
 *  Object to check for property
 *
 * @returns {Boolean}
 *  Returns true if property exists, false if not
 */
const propExists = (prop, object) => {
	if (!prop || !object) return false;

	let result = false;

	// set scope to check for property to given object
	let scope = object;

	// loop over props
	splitProps(prop).every((value) => {
		// check if prop exists on object definition and if the prop
		// name retrieved from definition in turn exists on current scope
		// and is not null
		if (typeof scope[value] === 'undefined' || scope[value] === null) {
			// if not set result to false
			result = false;
			// break every() by returning false
			return false;
		}

		// update scope to found property, to be able
		// to search new scope for children if applicable
		scope = scope[value];

		// set result to scope, this can be overwritten again
		// in next every() step if applicable
		result = true;
		return true;
	});

	return result;
};

const propShouldBeOmitted = (meta, value) => {
	if (!meta || !meta.omitIfEmpty) return false;

	const type = meta.type || 'string';

	if (
		typeof value === 'undefined' ||
		value === '' ||
		value === null ||
		(value === false && type !== 'boolean') ||
		(type === 'array' && (!Array.isArray(value) || value.length < 1)) ||
		(type === 'object' && (!isObject(value) || isEmpty(value)))
	)
		return true;

	return false;
};

const getDefaultValue = (meta) => {
	if (typeof meta.default === 'undefined') return DEFAULT_RETURN;
	else return meta.default;
};

export default {
	getProp,
	setProp,
	getPropUsingMeta,
	setPropUsingMeta,
	normalize,
	construct,
	create,
};
