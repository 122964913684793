import {
	getStoreMutation,
	getStoreAction,
	getStoreGetter,
} from '@assets/scripts/store/config';
import setLoader from '@assets/scripts/store/loader';
import {
	createNewConnection,
	validateConnection,
} from '@modules/ConnectionBuilder/components/connection';
import { log, debug } from '@assets/scripts/components/notifications';
import Helpers from '@assets/scripts/helpers';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_CONNECTION,
	GET_CREDENTIAL_TYPES,
	POST_CONNECTION,
	DELETE_CONNECTION,
} from '@modules/ConnectionBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	RESET: 'reset',
	UPDATE_CONNECTION_LIST: 'updateConnectionList',
	TOGGLE_CREATE_NEW: 'toggleCreateNew',
	NEW_CONNECTION: 'newConnection',
	UNSET_CONNECTION: 'unsetConnection',
	CREDENTIAL_TYPE: 'credentialType',
	LOAD_AND_SHOW_CONNECTION: 'loadAndShowConnection',
	CONNECTION_UPDATED: 'connectionUpdated',
	DELETE_CONNECTION: 'deleteConnection',
	PUBLISH_CURRENT_CONNECTION: 'publishCurrentConnection',
	VALIDATE_CURRENT_CONNECTION: 'validateCurrentConnection',
};

export default {
	/**
	 * Action triggered to load an existing connection
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of connection to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_CONNECTION]({ commit }, guid) {
		// load flow from api
		const connection = await useApiAsync(GET_CONNECTION, {
			keys: {
				guid,
			}
		});

		if (!connection) {
			log(t('cb.error.loadConnection'), 'danger');
			return;
		}
		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_CONNECTION', 'CB'), Helpers.cloneObject(connection), {
			root: true,
		});
	},
	/**
	 * Get credential type object in normalized way
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} type
	 *  type of credential
	 *
	 * @returns {Object}
	 * Credential object
	 */
	async [names.CREDENTIAL_TYPE]({ dispatch }, type) {
		let result = false;

		const credentialTypes = await useApiAsync(GET_CREDENTIAL_TYPES);

		if (credentialTypes) {
			result = credentialTypes.find((obj) => {
				return obj.name === type;
			});
		}

		return result;
	},
	[names.UNSET_CONNECTION]({ commit }) {
		// reset current connection
		commit(getStoreMutation('UNSET_CONNECTION', 'CB'), null, { root: true });

		// unset
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CB'), false, {
			root: true,
		});
	},
	[names.TOGGLE_CREATE_NEW]({ commit }, status = true) {
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CB'), !!status, {
			root: true,
		});
	},
	[names.RESET]({ commit }) {
		// reset state of store
		commit(getStoreMutation('RESET', 'CB'), null, { root: true });
	},
	[names.UPDATE_CONNECTION_LIST]() {
		// do nothing
		// only exists so components can subscribe to it
	},
	/**
	 * Action triggered when a new Document must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} settings
	 *  Settings for the new Document
	 *
	 * @returns {void}
	 */
	async [names.NEW_CONNECTION]({ commit, dispatch }, settings) {
		// show loader
		setLoader('new-connection');

		// create new connection object with given settings
		const newConnection = createNewConnection(settings);

		// commit mutation in store to set new connection
		commit(getStoreMutation('SET_CURRENT_CONNECTION', 'CB'), newConnection, {
			root: true,
		});

		// unset create new
		commit(getStoreMutation('TOGGLE_CREATE_NEW', 'CB'), false, {
			root: true,
		});

		// mark new connection as modified so it can be saved immediately
		commit(
			getStoreMutation('MARK_MODIFIED', 'CB'),
			{},
			{
				root: true,
			}
		);

		// remove loader
		setLoader('new-connection', false);

		// show success message
		log(t('cb.newConnection.createdSuccess'), 'success');
	},
	[names.CONNECTION_UPDATED]({ dispatch }) {
		// dispatch action to reload connection list
		dispatch(
			getStoreAction('UPDATE_CONNECTION_LIST', 'CB'),
			{},
			{ root: true }
		);
	},
	/**
	 * Action triggered to save the current Connection
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.PUBLISH_CURRENT_CONNECTION]({ dispatch }) {
		// get current connection from store
		const connection = this.getters[getStoreGetter('CURRENT_CONNECTION', 'CB')];

		// post connection through api
		const result = await useApiAsync(POST_CONNECTION, {
			parameters: {
				...connection,
			}
		});

		if (result !== false) {
			debug('successfully published', connection);
	
			// show success message
			log(t('cb.connDetails.publish.success'), 'success');
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('CONNECTION_UPDATED', 'CB'),
				null,
				{ root: true }
			);
	
			// dispatch action to unset connection
			dispatch(getStoreAction('UNSET_CONNECTION', 'CB'), null, { root: true });
		}
	},
	/**
	 * Action triggered to delete a connection with a
	 * given GUID
	 *
	 * @param {String} guid
	 *  GUID of document
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.DELETE_CONNECTION]({ dispatch }, { guid }) {
		// delete connection
		const result = await useApiAsync(DELETE_CONNECTION, {
			keys: {
				guid,
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				result,
			});
	
			// dispatch action to take needed steps after publishing
			dispatch(
				getStoreAction('CONNECTION_UPDATED', 'CB'),
				null,
				{ root: true }
			);
	
			// show success message
			log(t('cb.connDelete.success'), 'success');
		} else {
			// show error message
			log(t('cb.connDelete.error'), 'danger');
		}
	},
	/**
	 * Action triggered to validate the current connection
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_CONNECTION]({ commit, dispatch }) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// get current connection from store
		const currentConn = this.getters[getStoreGetter('CURRENT_CONNECTION', 'CB')];

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateConnection(currentConn);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});

			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
};
