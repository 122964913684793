import Validation from '@assets/scripts/components/validationChecks';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_EXTERNAL_CONNECTOR_BY_ID } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate external connector block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateExternalConnectorBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	// check if connection is selected
	if (!Validation.stringNotEmpty(block.config.method_guid)) {
		setBlockError(
			t('fb.flowErrors.externalConnectorNotChosen', {
				block: t('fb.blockTypes.external'),
			})
		);
	} else {
		const externalConnector = await useApiAsync(GET_EXTERNAL_CONNECTOR_BY_ID, {
			keys: {
				guid: block.config.method_guid
			}
		});

		if (!Validation.isNonEmptyObject(externalConnector)) {
			setBlockError(
				t('fb.flowErrors.externalConnectorNotExist', {
					block: t('fb.blockTypes.external'),
					externalConnectorName: Field.getNameAsPath(
						block.config.method_name
					),
				})
			);
		} else {
			// check if requirds field are mapped in external connector of a field
			if (
				!Validation.requiredFieldsMappedInExternalConnector(
					externalConnector,
					block.config.mapping || []
				)
			) {
				setBlockError(
					t('fb.flowErrors.noMappingFields', {
						externalConnectorName: Field.getNameAsPath(
							block.config.method_name
						),
						block: t('fb.blockTypes.external'),
					})
				);
			}
			// get all block inputs
			const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
				block.guid
			);

			block.config.mapping.forEach((mappingObject) => {
				const { fromField, toField } = Helpers.getFromToFields(
					blockInputs,
					mappingObject,
					externalConnector
				);

				// check if mapped field exist in the flow vars
				if (!Validation.mappedFieldExists(mappingObject, blockInputs)) {
					setBlockError(
						t('fb.flowErrors.ExternalConnectorMappingInputNotExist', {
							mappingObject: Field.getNameAsPath(
								mappingObject.from
							),
							externalConnectorName: Field.getNameAsPath(
								block.config.method_name
							),
							block: t('fb.blockTypes.external'),
						})
					);
				}

				// check if block input type match the external connector input type
				if (!Validation.mappedFieldTypesMatch(fromField, toField)) {
					setBlockError(
						t('fb.flowErrors.typeNotMatchInExternalConnector', {
							mappingObject: Field.getNameAsPath(
								mappingObject.to
							),
							block: t('fb.blockTypes.external'),
							externalConnectorName: Field.getNameAsPath(
								block.config.method_name
							),
						})
					);
				}

				// check if block input max length match the external connector input max length and regex
				if (fromField) {
					if (
						!Validation.fieldValidationsMaxLengthMatch(
							fromField.validation.max,
							toField.validation.max,
							true
						)
					) {
						setBlockError(
							t(
								'fb.flowErrors.maxLengthNotMatchInExternalConnector',
								{
									fromField: Field.getNameAsPath(fromField.name),
									fromFieldMaxLength: fromField.validation.max || t('general.none'),
									toField: Field.getNameAsPath(toField.name),
									toFieldMaxLength: toField.validation.max,
									block: t('fb.blockTypes.external'),
								}
							)
						);
					}
				}
			});
		}
	}
	return output;
};
