import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

import { getKeyFieldForFunctionList } from '@assets/scripts/components/function-lists';

import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_FUNCTION_LIST_BY_ID } from '@assets/scripts/api/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate start block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateStartBlock = async (block) => {
	const output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};
	
	const inputNames = block.config.input.map((input) => {
		return input.name.toLowerCase();
	});
	const checkedInputs = [];
	
	const outputNames = block.output.map((output) => {
		return output.name.toLowerCase();
	});
	const checkedOutputs = [];

	// for loop used becouse of the await inside
	for (const input of block.config.input) {
		// check if a field has type
		if (!Validation.fieldHasType(input)) {
			setBlockError(
				t('fb.flowErrors.fieldTypeMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if static default of a field has value
		if (!Validation.fieldHasStaticDefault(input)) {
			setBlockError(
				t('fb.flowErrors.defaultValueOfStaticMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if name is duplicated or used more than one time
		if (
			!checkedInputs.includes(input.name.toLowerCase()) &&
			!Validation.stringIsUniqueInList(input.name.toLowerCase(), inputNames)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedName', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name exist
		if (!Validation.stringNotEmpty(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameIsEmpty', {
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name has atleast 2 characters
		if (!Validation.stringHasAtleastTwoCharacters(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameIsShort', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if input name without whitspacing
		if (!Validation.stringWithoutSpacing(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameContainsSpace', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
				})
			);
		}

		// check if type exist or known in the app
		if (!Validation.fieldTypeExists(input)) {
			setBlockError(
				t('fb.flowErrors.typeNotExist', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.start'),
					type: input.type,
				})
			);
		}
		if (input.validation.element.ref.guid) {
			// get functionList
			const functionList = await useApiAsync(GET_FUNCTION_LIST_BY_ID, {
				keys: {
					guid: input.validation.element.ref.guid
				}
			});

			// check if function list exists
			if (!Validation.isNonEmptyObject(functionList)) {
				setBlockError(
					t('fb.flowErrors.functionListNotExist', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.start'),
						fieldFunction: input.validation.element.ref.key,
					})
				);
			} else {
				// check if connection guid match the connection guid in de function list
				if (
					!Validation.stringsMatch(
						functionList.conn_guid,
						input.validation.element.ref.conn_guid
					)
				) {
					setBlockError(
						t('fb.flowErrors.connectionGuidNotMatch', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							fieldFunction: functionList.name,
						})
					);
				}

				// check if input type match the function list key input type
				if (
					!Validation.fieldTypeMatchesFunctionListKeyFieldType(
						functionList,
						input
					)
				) {
					setBlockError(
						t('fb.flowErrors.typeNotMatchInFunctionList', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							fieldFunction: functionList.name,
						})
					);
				}

				// check if input max length match the function list max length and regex
				if (
					!Validation.fieldTypeMaxLengthMatchesFunctionListKeyFieldMaxLength(
						functionList,
						input
					)
				) {
					const keyField = getKeyFieldForFunctionList(functionList);
					setBlockError(
						t('fb.flowErrors.maxLengthNotMatchInFunctionList', {
							fieldName: Field.getNameAsPath(input.name),
							block: t('fb.blockTypes.start'),
							objectDefinition: functionList.name,
							objectDefinitionMaxLength : keyField.validation.max,
							fieldMaxLength : input.validation.max,
						})
					);
				}
			}
		}
		// add input to already checked inputs array
		checkedInputs.push(input.name.toLowerCase());
	}

	block.output.forEach(output => {
		// check if name of output field is duplicated or used more than one time
		if (
			!checkedOutputs.includes(output.name.toLowerCase()) &&
			!Validation.stringIsUniqueInList(output.name.toLowerCase(), outputNames)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedOutputName', {
					fieldName: Field.getNameAsPath(output.name),
					block: t('fb.blockTypes.start'),
				})
			);

			// add output to already checked outputs array
			checkedOutputs.push(output.name.toLowerCase());
		}
	});

	return output;
};
