<template>
	<VField
		:isFloating="isFloating"
		:label="label"
		:class="[{ 'is-active': isActive }, classes]"
		:isError="showError"
	>
		<VInput
			v-model="value"
			:placeholder="label"
			type="number"
			step="1"
			:min="min"
			@change="inputChanged"
			pattern="\d*"
			:disabled="isDisabled"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="error"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'VInputInteger',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		min: {
			type: Number,
		},
		label: {
			type: String,
		},
		isFloating: {
			type: Boolean,
			default: true,
		},
		classes: {
			type: String,
		}
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'number' ? this.valueModel : '',
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.value !== '';
		},
		isValid: function () {
			return (
				(!this.isRequired && !this.isActive) ||
				(this.isActive &&
					(typeof this.min === 'undefined' || this.value >= this.min))
			);
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
		error: function () {
			if (this.min) {
				return this.$t('error.enterIntegerWithMin', { min: this.min });
			} else {
				return this.$t('error.enterInteger');
			}
		},
	},
	methods: {
		inputChanged: function (ev) {
			this.modified = true;
			this.value = Helpers.forceInt(ev.target.value);
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>
