export default {
	SCREEN_SIZES: 'screenSizes',
	IS_LOADING: 'isLoading',
	IS_LOGGED_IN: 'isLoggedIn',
	DRAWERS: 'drawers',
	DRAWER_COUNT: 'drawerCount',
	CONFIRMATION: 'confirmation',
	CONFIRMATION_ACTIVE: 'confirmationActive',
	DATA_LOSS_POSSIBLE: 'dataLossPossible',
	GLOBAL_SAVE_POSSIBLE: 'globalSavePossible',
	VALIDATION_MODE: 'validationMode',
	VALIDATION_IN_PROGRESS: 'validationInProgress',
	IS_VALID: 'isValid',
	ERRORS_LIST_COLLAPSED: 'errorsListCollapsed',
	VALIDATION_ERRORS: 'validationErrors',
};
