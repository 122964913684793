import i18n from '@assets/i18n';
import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { connectionDocumentMeta, actionDocumentReferenceMeta } from '@modules/DocumentBuilder/endpoints';
import { debug } from '@assets/scripts/components/notifications';
import Field from '@assets/scripts/components/field';
import actionDocumentDefaultFields from './action-document-default-fields';
import Validation from '@assets/scripts/components/validationChecks';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_DOCUMENTS,
	GET_DOCUMENT,
} from '@modules/DocumentBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

export const docTypes = {
	ACTION: 'Action',
	LIST: 'List',
};

// possible statuses for documents
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Returns a newly created document
 *
 * @param {String} name
 *  Name of the Document
 *
 * @param {String} description
 *  Description of the Document
 *
 * @param {String} type
 *  Type of Document
 *
 * @param {String} conn_guid
 *  GUID of COnnection that Document will be part of
 *
 * @returns {Object}
 *  New Document
 */
export const createNewDocument = ({ name, description, type, conn_guid }) => {
	// get current date
	const dateIso = new Date().toISOString();

	// create and return new flow
	return Helpers.obj.create(connectionDocumentMeta, {
		name,
		description,
		type: docTypes[type],
		guid: Helpers.newGuid(),
		conn_guid,
		created: dateIso,
		modified: dateIso,
	});
};

/**
 * Gets and returns default fields to be added to a
 * newly created Document. Needed custom name changes and other
 * settings are done before result is returned
 *
 * @param {Object} document
 *  Newly created document to get default fields for
 *
 * @returns {Object}
 *  Default fields, keyed by name
 */
export const getDefaultDocumentFields = (document) => {
	const type = getDocType(document.type);

	// switch over document type to return
	// correct fields
	switch (type) {
		case 'ACTION':
			return getDefaultActionDocumentFields(document.name);
		default:
			return {};
	}
};

/**
 * Gets and returns names of default fields to be added to a
 * newly created Document
 *
 * @param {Object} document
 *  Newly created document to get default field names for
 *
 * @returns {Array}
 *  Default field names
 */
const getDefaultDocumentFieldNames = (document) => {
	const fieldNames = [];
	const fields = getDefaultDocumentFields(document);

	if (fields) {
		Object.values(fields).forEach((field) => {
			fieldNames.push(field.name.toLowerCase());
		});
	}

	return fieldNames;
};

/**
 * Gets and returns default fields to be added to a
 * newly created Action Document. Needed custom name changes and other
 * settings are done before result is returned
 *
 * @param {Object} document
 *  Newly created document to get default fields for
 *
 * @returns {Object}
 *  Default fields, keyed by name
 */
const getDefaultActionDocumentFields = (name) => {
	// get default fields
	const fields = Helpers.cloneObject(actionDocumentDefaultFields);

	// set names for ID & GUID fields
	fields.id.name = `${name}Id`;
	fields.guid.name = `${name}Guid`;

	return fields;
};

/**
 * Returns whether a given field can de edited,
 * or viewed only
 *
 * @param {Object} field
 *  Field to check
 *
 * @param {Object} document
 *  Document to which the field belongs
 *
 * @returns {Boolean}
 */
export const fieldCanBeEdited = (field, document) => {
	// get array of default field names for document
	const defaultFields = getDefaultDocumentFieldNames(document);

	// check if document can be edited
	const canBeModified = userCanModifyDoc(document);

	// get field name
	const fieldName = field.name ? field.name.toLowerCase() : false;

	return !(
		// check if field is a default field and user is not admin
		(defaultFields.includes(fieldName) && !usePermission('Upsert admin', 'DocumentBuilder'))
		// check if doc can not be edited, and field is not new
		|| (!canBeModified && !field.is_new)
	);
};

/**
 * Find simple document type based on full type
 *
 * @param {String} type
 *  Full type to find simple type for
 *
 * @returns {String/Boolean}
 *  Found type or false
 */
export const getDocType = (type) => {
	return Helpers.getKeyByValue(docTypes, type);
};

/**
 * Returns translated name of given doc type
 *
 * @param {String} type
 *  Doc type as used as value in 'docTypes' object
 *
 * @returns {String}
 *  Translated name or false
 */
export const translateDocType = (type) => {
	let result = false;

	if (typeof docTypes[type] === 'undefined') {
		type = getDocType(type);
	}

	if (type) result = t('db.docTypes.' + type.toLowerCase());

	return result;
};

/**
 * Gets the status of a given document
 *
 * @param {Object} document
 *  Document to get status of (normalized)
 *
 * @returns {String}
 *  Status of given document
 */
const getDocStatus = (document) => {
	// determine status
	let status = statuses.DRAFT;

	// check if document is published
	if (document.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

/**
 * Gets the status of a given document as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} document
 *  Document to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getDocStatusName = (document) => {
	let output;

	// determine status
	switch (getDocStatus(document)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Check if a given document can be modified by current user
 *
 * @param {Object} document
 *  Document to check
 *
 * @returns {Boolean}
 */
export const userCanModifyDoc = (document) => {
	return (
		(getDocStatus(document) === statuses.DRAFT && usePermission('Upsert', 'DocumentBuilder')) || // document is a Draft and user can edit doc builder
		usePermission('Upsert admin', 'DocumentBuilder') // user is admin
	);
};

/**
 * Check if a given document name can be modified by current user
 *
 * @param {Object} document
 *  Document to check
 *
 * @returns {Boolean}
 */
export const userCanModifyDocName = (document) => {
	return userCanModifyDoc(document) && getDocStatus(document) === statuses.DRAFT;
};

/**
 * Check if a given document can be extended by current user
 *
 * @param {Object} document
 *  Document to check
 *
 * @returns {Boolean}
 */
export const userCanExtendDoc = (document) => {
	return (
		getDocStatus(document) === statuses.PUBLISHED && // document is published
		usePermission('Upsert', 'DocumentBuilder') // user can edit docs
	);
};

/**
 * Check if a given document can be deleted by
 * the current user
 *
 * @param {Object} document
 *  Document to check
 *
 * @returns {Boolean}
 */
const userCanDeleteDoc = (document) => {
	return (
		(
			usePermission('Delete', 'DocumentBuilder') && 
			getDocStatus(document) === statuses.DRAFT
		) 
		|| usePermission('Delete published', 'DocumentBuilder')
	);
};

/**
 * Constructs a Document Object from a given document
 * as can be used in communication with the Nominow API
 *
 * @param {Object} document
 *  Normalized version of document to construct
 *
 * @returns {Object}
 *  Fully constructed Document Object
 */
export const constructFullDocument = (document) => {
	// return empty object if no document is given
	if (!document || isEmpty(document)) return {};

	// make clone of document, because we are going to move
	// some fields around and do not want to change the
	// given normalized document
	document = Helpers.cloneObject(document);

	// sort fields based on 'sort' attribute
	document.fields.sort((a, b) => {
		return Field.orderParentChildArrayBySortOrder(a, b, document.fields);
	});

	// return fully constructed document
	return Helpers.obj.construct(document, connectionDocumentMeta, getDocType(document.type).toLowerCase());
};

/**
 * Returns the key field from the Action Document
 *
 * @param {Object} document
 *  Action Document
 *
 * @returns {Object}
 *  Found key field or false
 */
const getKeyFieldForActionDocument = (document) => {
	let result = false;

	document.fields.some((field) => {
		if (field.is_key) {
			result = field;
			return true;
		}
	});

	return result;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about documents to use in Table in DocumentList component
 *
 * @param {Array} documents
 *  Array of document objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (documents) => {
	const result = [];

	// loop over fields
	documents.forEach((document, key) => {
		// get last modified time
		const lastModified = document.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: document.guid,
			// name used for sorting
			doc_name: document.name,
			// type of the document
			type: document.type,
			// translated type of the document
			type_name: translateDocType(document.type),
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			status: getDocStatusName(document),
			edit: userCanModifyDoc(document) || userCanExtendDoc(document) ? 'edit' : 'view',
			delete: userCanDeleteDoc(document),
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in ActionDocumentDetails component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @param {Object} document
 *	Document to which the fields belong
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatFieldsForActionDocumentTable = (fields, document) => {
	const result = [];

	// loop over fields
	fields.forEach((field, key) => {
		field.order = field.order ?? key;

		// check if field can be edited
		const isEditable = fieldCanBeEdited(field, document);

		result.push({
			order: field.order,
			key, // key, useful for handling clicks
			// full name used for sorting
			name: field.name,
			// name of the field
			field_name: Field.getChildName(field.name),
			// type of the field
			field_type: field.type,
			// default value of the field, if any
			default_value: Field.getDefaultValue(field),
			// output types of validation
			validation: Field.getValidation(field),
			// indicator whether field is required
			required_field: Helpers.trueish(field.validation.required)
				? t('general.yes')
				: t('general.no'),
			edit: isEditable ? 'edit' : 'view',
			delete: isEditable,
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in ListDocumentDetails component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @param {Boolean} canBeEdited
 *	Indicated whether fields can be edited
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatFieldsForListDocumentTable = (fields, document) => {
	const result = [];

	// loop over fields
	fields.forEach((field, key) => {

		// check if field can be edited
		const isEditable = fieldCanBeEdited(field, document);

		result.push({
			key, // key, useful for handling clicks
			// name used for sorting
			sort_name: field.name,
			// name of the field
			field_name: Field.getChildName(field.name),
			// type of the field
			field_type: field.type,
			// default value of the field, if any
			default_value: Field.getDefaultValue(field),
			// output types of validation
			validation: Field.getValidation(field),
			// indicator whether field is required
			required_field: Helpers.trueish(field.validation.required)
				? t('general.yes')
				: t('general.no'),
			// indicator whether field is returnable
			is_returnable: Helpers.trueish(field.is_returnable)
				? t('general.yes')
				: t('general.no'),
			edit: isEditable ? 'edit' : 'view',
			delete: isEditable,
		});
	});

	return result;
};

/**
 * Get info about Documents formatted for
 * use in ActionDocumentReferenceDrawer component table
 *
 * @param {Array} documents
 *  Array of Document definitions
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForReferenceTable = (documents) => {
	const result = [];

	// loop over documents
	documents.forEach((document) => {
		let type, name = false;

		const keyField = getKeyFieldForActionDocument(document);

		if (keyField) {
			type = keyField.type || false;
			name = keyField.name || false;
		}

		result.push({
			guid: document.guid,
			name: document.name,
			key: name,
			type: type,
			ref: Helpers.obj.create(actionDocumentReferenceMeta, {
				...document,
				key: name,
			})
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given document field and returns errors array
 *
 * @param {Object} field
 *  Field to validate
 * 
 * @param {Array} fields
 *  List of fields of the document
 *
 * @param {Object} document
 *  Document to which the field belongs
 *
 * @returns {Array}
 *	array of errors
 */
const validateField = async (field, fields, document) => {
	const errors = [];

	const setError = (description) => {
		errors.push(createErrorObject(description));
	};

	// check if field name set
	if (!Validation.stringNotEmpty(field.name)) {
		setError(t('db.validation.field.fieldNameEmpty'));
	}
	// check if field name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(field.name)) {
		setError(
			t('db.validation.field.fieldNameIsShort', {
				name: field.name,
			})
		);
	}
	// check if field name is unique
	else if (!Validation.propIsUnique(fields, 'name', field.name)) {
		setError(
			t('db.validation.field.multipleFieldsWithSameName', {
				name: field.name,
			})
		);
	} else {
		// check if field name without white spacing
		if (!Validation.stringWithoutSpacing(field.name)) {
			setError(
				t('db.validation.field.fieldNameContainsSpace', {
					name: field.name,
				})
			);
		}

		// check if field has type
		if (!Validation.fieldHasType(field)) {
			setError(
				t('db.validation.field.fieldTypeMissing', {
					name: field.name,
				})
			);
		}
		// check if type exist or known in the app
		else if (!Validation.fieldTypeExists(field)) {
			setError(
				t('db.validation.field.fieldTypeUnknown', {
					name: field.name,
					type: field.type,
				})
			);
		} else if (field.type === 'String' && !Validation.fieldMaxLengthSet(field)) {
			setError(
				t('db.validation.field.maxLengthEmpty', {
					name: field.name,
				})
			);
		}

		// check if field uses List Function
		if (field.validation.element.ref.guid) {
			// get functionList
			const functionList = await useApiAsync(GET_DOCUMENT, {
				keys: {
					guid: field.validation.element.ref.guid,
					connection: document.conn_guid,
				}
			});

			// check if function list exists
			if (!Validation.isNonEmptyObject(functionList)) {
				setError(
					t('db.validation.field.functionListUnknown', {
						name: field.name,
					})
				);
			} else {
				// check if input type match the function list key input type
				if (
					!Validation.fieldTypeMatchesFunctionListKeyFieldType(
						functionList,
						field
					)
				) {
					setError(
						t('db.validation.field.typesNotMatchInFunctionList', {
							name: field.name,
						})
					);
				}

				// check if input max length match the function list max length and regex
				if (
					!Validation.fieldTypeMaxLengthMatchesFunctionListKeyFieldMaxLength(
						functionList,
						field
					)
				) {
					setError(
						t('db.validation.field.maxLengthsNotMatchInFunctionList', {
							name: field.name,
						})
					);
				}
			}
		}

		// check if static default of the field has value
		if (!Validation.fieldHasStaticDefault(field)) {
			setError(
				t('db.validation.field.defaultValueForStaticMissing', {
					name: field.name,
				})
			);
		}
	}

	return errors;
};

/**
 * Validates given document and returns errors array
 *
 * @param {Object} document
 *  Document to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateDocument = async (document) => {
	debug('Validating', { document });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	// check for empty document name
	if (!Validation.stringNotEmpty(document.name)) {
		setError(
			t('db.validation.docNameEmpty')
		);
	} 
	// check if document name has atleast 2 characters
	else if(!Validation.stringHasAtleastTwoCharacters(document.name)){
		setError(
			t('db.validation.docNameIsShort')
		);	
	}
	else {
		// check if field name without white spacing
		if (!Validation.stringWithoutSpacing(document.name)) {
			setError(t('db.validation.documentNameContainsSpace'));
		}
		// get all documents for connection
		const connDocuments = await useApiAsync(GET_DOCUMENTS, {
			keys: {
				connection: document.conn_guid
			}
		}); 

		if (connDocuments) {
			const list = [];

			// create list of document names, excluding document that is
			// being validated
			connDocuments.forEach((doc) => {
				if (doc.guid !== document.guid) list.push(doc.name.toLowerCase());
			});

			// check if document name already exists
			if (Validation.stringExistsInList(document.name.toLowerCase(), list)) {
				setError(
					t('db.validation.docNameNotUnique')
				);
			}
		}
	}

	const type = getDocType(document.type);
	
	// switch over document type to do type
	// specific validation
	switch (type) {
		case 'ACTION':
			output = output.concat(await validateActionDocument(document));
			break;
		case 'LIST':
			output = output.concat(await validateListDocument(document));
			break;
	}

	return output;
};

/**
 * Validates given action document and returns errors array
 *
 * @param {Object} document
 *  Action Document to validate
 *
 * @returns {Array}
 *	array of errors
 */
const validateActionDocument = async (document) => {
	let errors = [];

	const setError = (description) => {
		errors.push(createErrorObject(description));
	};

	// add document fields to variable
	const fields = document.fields;

	// loop over references, using for loop because of
	// the await inside
	for (const reference of document.references) {
		// check if name is set
		if (!Validation.stringNotEmpty(reference.name)) {
			setError(
				t('db.validation.action.referenceNameMissing')
			);			
		}
		// check if guid is set
		else if (!Validation.stringNotEmpty(reference.guid)) {
			setError(
				t('db.validation.action.referenceGuidMissing', {
					name: reference.name
				})
			);			
		}
		// check if key field is set
		else if (!Validation.stringNotEmpty(reference.key)) {
			setError(
				t('db.validation.action.referenceKeyMissing', {
					name: reference.name
				})
			);			
		}
		// check if foreign key is set
		else if (!Validation.stringNotEmpty(reference.child)) {
			setError(
				t('db.validation.action.referenceChildMissing', {
					name: reference.name
				})
			);			
		} else {
			// get referenced document within connection
			const refDoc = await useApiAsync(GET_DOCUMENT, {
				keys: {
					guid: reference.guid,
					connection: document.conn_guid,
				}
			});
			
			// check if referenced document exists
			if (!Validation.isNonEmptyObject(refDoc)) {
				setError(
					t('db.validation.action.refDocMissing', {
						name: reference.name
					})
				);
			} else {
				// get key field of referenced document
				const keyField = getKeyFieldForActionDocument(refDoc);

				// check if keyfield exists
				if (!Validation.isNonEmptyObject(keyField)) {
					setError(
						t('db.validation.action.referencedDocHasNoKeyfield', {
							name: reference.name,
						})
					);	
				}
				// check if key field is configured correct
				else if (!Validation.stringsMatch(keyField.name, reference.key)) {
					setError(
						t('db.validation.action.keyFieldIncorrect', {
							name: reference.name,
							keyField: keyField.name,
							configured: reference.key,
						})
					);	
				}
				// check if configured foreign key field exists in document fields
				else if (!Validation.fieldExistsInList(reference.child, fields)) {
					setError(
						t('db.validation.action.childFieldDoesNotExist', {
							name: reference.name,
							foreignKey: reference.child,
						})
					);	
				}
				else {
					// get field object of configured foreign key
					const foreignKeyField = fields.find((field) => field.name === reference.child);

					// check if referencing field types match
					if (!Validation.referencingFieldTypesMatch(keyField, foreignKeyField)) {
						setError(
							t('db.validation.action.referencingFieldTypesDoNotMatch', {
								name: reference.name,
								keyField: keyField.name,
								foreign: reference.child,
							})
						);
					}
					// check if referencing fields max length match
					else if (!Validation.fieldValidationsMaxLengthMatch(keyField.validation.max, foreignKeyField.validation.max)) {
						setError(
							t('db.validation.action.referencingFieldMaxLengthsDoNotMatch', {
								name: reference.name,
								keyField: keyField.name,
								foreign: reference.child,
							})
						);
					}
				}
			}

		}
	};

	// check if at least 1 field is set
	if (!Validation.isNonEmptyArray(fields)) {
		setError(
			t('db.validation.noFieldsSet')
		);	
	} else {
		// check if more than 1 field is marked as Identifier
		if (Validation.countObjectsWithProperty(fields, 'is_id') > 1) {
			setError(
				t('db.validation.action.multipleFieldsMarkedAsId')
			);	
		}

		// check if at least 1 field is marked as Key
		if (Validation.countObjectsWithProperty(fields, 'is_key') < 1) {
			setError(
				t('db.validation.action.noKeyFields')
			);	
		}

		// loop over fields, using for loop because of
		// the await inside
		for (const field of fields) {
			errors = errors.concat(await validateField(field, fields, document));
		}
	}

	return errors;
};

/**
 * Validates given list document and returns errors array
 *
 * @param {Object} document
 *  List Document to validate
 *
 * @returns {Array}
 *	array of errors
 */
const validateListDocument = async (document) => {
	let errors = [];

	const setError = (description) => {
		errors.push(createErrorObject(description));
	};

	// add document fields to variable
	const fields = document.fields;

	// check if at least 1 field is set
	if (!Validation.isNonEmptyArray(fields)) {
		setError(
			t('db.validation.noFieldsSet')
		);	
	} else {
		// loop over fields, using for loop because of
		// the await inside
		for (const field of fields) {
			errors = errors.concat(await validateField(field, fields, document));
		}
	}

	// check if exactly 1 field is marked as Key
	if (Validation.countObjectsWithProperty(fields, 'is_key') !== 1) {
		setError(
			t('db.validation.list.singleKeyFieldNeeded')
		);	
	}

	// check if max 1 field is marked as Mapping
	if (Validation.countObjectsWithProperty(fields, 'is_map') > 1) {
		setError(
			t('db.validation.list.multipleMappingFields')
		);	
	}

	return errors;
};
