export const scriptMeta = {
	guid: {
		name: 'FlowScriptGuid',
		type: 'string',
		default: '',
	},
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	type: {
		name: 'Type',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	commit: {
		name: 'CommitNote',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	host_name: {
		name: 'ServiceHostName',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	script_type: {
		name: 'ScriptTypeName',
		type: 'string',
		default: '',
		omitIfEmpty: true,
	},
	script: {
		name: 'Script',
		type: 'string',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
};

// File exports all available Endpoints
const apiBase = 'UIServices/Script';

export const GET_SCRIPTS = {
	path: 'GetScripts',
	realm: apiBase,
	meta: scriptMeta,
};

export const GET_SCRIPT_TYPES = {
	path: 'GetScriptTypes',
	realm: apiBase,
};

export const GET_SCRIPT_BY_ID = {
	path: 'GetScriptByGuid/{guid}',
	realm: apiBase,
	meta: scriptMeta,
};

// Delete Script
export const DELETE_SCRIPT = {
	path: 'DeleteScript/{guid}',
	method: 'delete',
	realm: apiBase,
};

// Save or publish Script
export const POST_SCRIPT = {
	path: 'PostScript',
	method: 'post',
	realm: apiBase,
	meta: scriptMeta
};

// Check compilation
export const CHECK_COMPILATION = {
	path: 'CheckCompilation',
	method: 'post',
	realm: apiBase,
	headers: {
		'Content-Type': 'text/plain',
	}
};
