/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'Method Builder',
	methodList: {
		title: 'Method Builder',
		addMethod: 'Add method',
		table: {
			noResults: 'No methods found',
			columns: {
				name: 'Name',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this method?',
				body: 'Are you sure you want to delete this method?',
				confirmButtonText: 'Delete',
			},
		},
	},
	methodDelete: {
		error: 'Something went wrong while deleting method. Please try again.',
		success: 'Method was succesfully deleted',
	},
	error: {
		loadMethod: 'Method was not found',
	},
	methodDetails: {
		backToOverview: 'Back to overview',
		methodName: 'Method name',
		methodSettings: 'Method settings',
		description: 'Description',
		addField: 'Add field',
		refreshFields: 'Refresh fields',
		refreshButtonToolTip: 'Refresh fields to get chosen stored procedure inputs',
		fullOutput: 'Full output',
		fullOutputExplanation: 'Use all available variables as output',
		fullOutputOn: 'All available fields will be added to output, because the Full output option is enabled',
		close: {
			confirm: {
				title: 'Go back to method overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		table: {
			noResults: 'No fields',
		},
		delete: {
			confirm: {
				title: 'Delete this field?',
				body: 'Are you sure you want to delete this field? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
		savedSuccessfully: 'Method was successfully saved',
		publish: {
			success: 'Method was successfully published',
			confirm: {
				title: 'Publish the method?',
				body: 'Are you sure you want to publish the method? This action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
		settings: {
			storedProcedure: 'Stored procedure',
			is_read: 'Is read',
			is_read_info: 'Is this method only retrieving data? When true then action in rest flow is a read action.',
		},
	},
	fieldDetails: {
		saveButton: 'Save',
		close: {
			confirm: {
				title: 'Go back to method?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
	},
	newMethod: {
		title: 'Create new method',
		whatName: 'Give your method a name',
		giveDescription: 'Add a description for the new method',
		nameInputLabel: 'Method name',
		whatConnection: 'Which connection will the method be part of?',
		selectConnection: 'Select connection',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Method',
	},
	validation: {
		nameEmpty: 'Method name can not be empty',
		nameIsShort: 'Method name should be at least 2 characters',
		nameNotUnique: 'Method name already exists within connection',
		noInputSet: 'No input fields added',
		noOutputSet: 'No output fields added',
		scriptEmpty: 'No stored procedure set',
		field: {
			fieldNameEmpty: 'Field name is empty',
			fieldNameIsShort: 'Field <strong>{name}</strong> name should be at least 2 characters',
			multipleFieldsWithSameName: 'Multiple fields with name <strong>{name}</strong> are set',
			fieldNameContainsSpace: 'Field name <strong>{name}</strong> contains a space',
			fieldTypeMissing: 'Field <strong>{name}</strong> has no type set',
			fieldTypeUnknown: 'Field type <strong>{type}</strong> of field <strong>{name}</strong> is unknown',
			functionListUnknown: 'Used validation tool for field <strong>{name}</strong> is unknown within current connection',
			typesNotMatchInFunctionList:
				'Type of key input in validation tool for field <strong>{name}</strong> does not match the type of the field',
			maxLengthsNotMatchInFunctionList:
				'Max length of key input in validation tool for field <strong>{name}</strong> does not match the max length of the field',
			defaultValueForStaticMissing: 'No default value is set for field <strong>{name}</strong>',
			noMaxlengthOutputField: 'Field <strong>{name}</strong> has no max length set',
		},
	},
	permissions: {
		label: 'Method builder',
		info: 'Permissions connected to the Method builder',
		readMethod: {
			label: 'Read method',
			info: 'Permission to view all methods',
		},
		editMethod: {
			label: 'Edit method',
			info: 'Permission to create, update and publish methods',
		},
		deleteMethodDraft: {
			label: 'Delete method draft',
			info: 'Permission to delete any method draft',
		},
	}
};
