<template>
	<div class="flow-history">
		<VTitle
			class="flow-history-title has-bottom-divider"
			:size="2"
			:text="$t('fb.flowHistory.flowHistory')"
		/>
		<SortableDataTable
			:scrollable="false"
			:stickyHeader="false"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="versions"
			tableClasses="flow-history-table"
			:columns="columns"
			:emptyText="$t('fb.flowHistory.table.noResults')"
		/>
	</div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import {
	formatForVersionTable,
	flowHasDraft,
} from '@modules/FlowBuilder/components/flow';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'FlowHistory',
	props: {
		/**
		 * Object of the flow
		 */
		flow: {
			type: Object,
			default: function () {
				return {};
			},
		},
		drawerId: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			// definition of table columns
			columns: [
				{
					label: this.$t('fb.flowHistory.table.columns.publish_date'),
					field: 'publish_date',
					sortable: true,
					customSort: this.sortByPublished,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.flowHistory.table.columns.last_time_edited'
					),
					field: 'last_time_edited',
					sortable: true,
					customSort: this.sortByModified,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.flowHistory.table.columns.status'),
					field: 'status',
					sortable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'preview',
					sortable: false,
					default: '',
					component: 'VLink',
					args: {
						href: '',
						text: this.$t('fb.flowHistory.table.inspectFlow'),
						class: 'is-alternative flow-history-option-link',
						icon: 'eye',
						noIconBorder: true,
					},
					click: this.inspectClicked,
				},
				{
					label: '',
					field: 'duplicate',
					sortable: false,
					default: '',
					component: 'VLink',
					args: {
						href: '',
						text: this.$t('fb.flowHistory.table.duplicateFlow'),
						class: 'is-alternative flow-history-option-link',
						icon: 'plus',
						noIconBorder: true,
					},
					click: this.duplicateClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VLink',
					args: {
						href: '',
						title: this.$t('fb.flowHistory.table.deleteFlow'),
						class: 'is-alternative flow-history-option-link',
						icon: 'delete',
						noIconBorder: true,
					},
					click: this.deleteClicked,
				},
			],
		};
	},
	computed: {
		versions: function () {
			return formatForVersionTable(this.flow);
		},
	},
	watch: {
		versions() {
			if (this.versions.length === 0) {
				this.$store.commit(
					getStoreMutation('CLOSE_DRAWER'),
					this.drawerId
				);
			}
		},
	},
	methods: {
		inspectClicked(row) {
			// dispatch action to show clicked version
			// of flow on the canvas
			this.$store.dispatch(getStoreAction('LOAD_AND_SHOW_FLOW', 'FLOW'), {
				guid: row.guid,
				versionGuid: row.version_guid || false,
			});
		},
		duplicateClicked(row) {
			// flow can not be duplicated if flow already
			// has a Draft/Working version
			if (flowHasDraft(this.flow)) {
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('fb.flowDuplicate.blocked.title'),
					body: this.$t('fb.flowDuplicate.blocked.body'),
					confirmButtonText: this.$t(
						'fb.flowDuplicate.blocked.confirmButtonText'
					),
					cancelButtonText: false,
				});
			} else {
				// ask confirmation to duplicate flow
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('fb.flowDuplicate.confirm.title'),
					body: this.$t('fb.flowDuplicate.confirm.body'),
					confirmButtonText: this.$t(
						'fb.flowDuplicate.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// duplicate after confirmation
						this.$store.dispatch(
							getStoreAction('DUPLICATE_FLOW', 'FLOW'),
							{
								guid: row.guid,
								versionGuid: row.version_guid,
							}
						);
					},
				});
			}
		},
		deleteClicked(row) {
			// ask confirmation to delete flow
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('fb.flowDelete.confirm.title'),
				body: this.$t('fb.flowDelete.confirm.body'),
				confirmButtonText: this.$t(
					'fb.flowDelete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.$store.dispatch(
						getStoreAction('DELETE_FLOW', 'FLOW'),
						{
							guid: row.guid,
							versionGuid: row.version_guid,
						}
					);
				},
			});
		},
		sortByPublished(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.publish_time, b.publish_time, isAsc);
		},
		sortByModified(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.edited_time, b.edited_time, isAsc);
		},
	},
};
</script>
