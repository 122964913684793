import i18n from '@assets/i18n';
const TheConnectionBuilder = () => import('@modules/ConnectionBuilder/materials/TheConnectionBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/connectionbuilder',
		component: TheConnectionBuilder,
		name: 'connectionBuilder',
		meta: {
			title: t('cb.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'result',
			parent: 'builder',
			module: 'ConnectionBuilder',
			order: 4,
		},
	},
];