import { createApp } from 'vue';
import registerComponentsGlobally from '@assets/scripts/global-components';
import i18n from '@assets/i18n';
import App from './App.vue';
import store from '@assets/scripts/store';
import router from '@assets/scripts/router';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { init as initStoreProxy } from '@assets/scripts/components/store-proxy';
import '@styles/toolkit.scss';

initStoreProxy(store); // register store to proxy store

const app = createApp(App); // create App
registerComponentsGlobally(app); // register all global components
app.use(store); // add Vuex store
app.use(i18n); // add internationalisation
app.use(router); // add router
app.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: 'Nominow UI',
    serverUrl:'https://apm.thevalley.nl:8200',
    environment: 'Development' // Must start with capital letter
  },
  captureErrors: true,
})
app.mount('#app'); // mount app element
