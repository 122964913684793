<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.predefinedFields.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="addSelectedFields"
	>
		<div class="content-header has-padding has-bottom-divider">
			<div class="field is-grouped is-flex-grow-1">
				<VField
					:label="$t('fb.predefinedFields.chooseConnection')"
					:isFloating="true"
					:class="{ 'is-active': connection }"
					class="has-padding-right-1"
				>
					<VSelect
						:options="connectionOptions"
						v-model="connection"
						@change="() => connectionUpdated()"
						:disabled="selectedCount > 0"
					/>
				</VField>
				<VField
					:label="$t('fb.predefinedFields.document')"
					:isFloating="true"
					class="has-margin-right-1"
					:class="{ 'is-active': documentFilter }"
				>
					<VSelectWithSearch
						v-model:modelValue="documentFilter"
						:options="documentOptions"
						:placeholder="documentFilter || ''"
						:disabled="!connection"
						:searchPlaceholder="$t('vueSelect.placeholder')"
					/>
				</VField>
			</div>
		</div>

		<BTable
			narrowed
			sticky-header
			scrollable
			hoverable
			@click="rowClicked"
			default-sort="field_name"
			default-sort-direction="ASC"
			:data="filteredData"
			:rowClass="getRowClass"
			:isRowCheckable="setRowCheckable"
			:checkable="true"
			:headerCheckable="false"
			v-model:checkedRows="checkedRows"
			@check="handleCheckedRows"
			@update:checkedRows="checkedRowsUpdated"
			bTableClasses="scrollable-content"
		>
			<BTableColumn
				v-for="(column, index) in columns"
				v-bind:key="index"
				:width="column.width || null"
				:label="column.label"
				:field="column.field"
				:sortable="column.sortable"
				:cellClass="column.cellClass"
				:customSort="column.customSort || null"
			>
				<template v-slot="props">
					<div
						:class="[{
							'is-indent':
								column.leveled && props.row.level > 0,
						},{'is-document':
								column.leveled && props.row.level === 0
						}]"
					>
						<template
							v-if="column.leveled && props.row.level > 0"
						>
							<span
								v-for="item in props.row.level + 1"
								:key="item"
								class="spacer"
							></span>
							<VIcon name="subdir" />
						</template>
						<template v-if="column.component">
							<Component
								:is="column.component"
								v-bind="column.args"
								@click.prevent.stop="
									column.click(props.row)
								"
								v-if="props.row[column.field]"
							/>
							<template v-else>&nbsp;</template>
						</template>
						<template v-else>
							{{ props.row[column.field] || column.default }}
						</template>
					</div>
				</template>
			</BTableColumn>

			<template #empty>
				<div class="has-text-centered">
					{{ $t('fb.predefinedFields.table.noResults') }}
				</div>
			</template>
		</BTable>

		<template #footer>
			{{
				$t('fb.predefinedFields.fieldsSelected', { count: selectedCount })
			}}
			<VButton
				:disabled="selectedCount < 1"
				class="button-modal-footer"
				:text="$t('fb.predefinedFields.addButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="addSelectedFields"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { mapGetters } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { formatForPredefinedFieldsTable } from '@assets/scripts/components/field';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_DOCUMENTS } from '@modules/FlowBuilder/endpoints';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'PredefinedFieldsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			connectionOptions: [],
			connection: false,
			documents: [],
			checkedRows: [],
			localCheckedRows: [],
			documentFilter: 0,
			calculating: false,
			documentOptions: [],
			columns: [
				{
					label: this.$t('fb.predefinedFields.table.columns.field_name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					leveled: true,
					default: this.$t('general.dash'),
					customSort: this.sortByFieldName,
				},
				{
					label: this.$t('fb.predefinedFields.table.columns.field_type'),
					field: 'field_type',
					sortable: false,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'disabled',
					sortable: false,
					default: '',
					width: '120', // to keep the popup within the screen
					component: 'VTooltip',
					cellClass: 'is-button-tool',
					args: {
						text: this.$t(
							'fb.predefinedFields.table.disabledExplanation'
						),
					},
				},
			],
		};
	},
	mounted: async function () {
		// get all available connections
		const connections = await useApiAsync(GET_CONNECTIONS);

		if (connections && connections.length > 0) {
			// map Connection objects to use in select field
			this.connectionOptions = Helpers.mapObjectArrayForSelect(connections, {
				key: 'conn_guid',
				text: 'name',
			})
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Get start block fields of block that is currently viewed/edited
			 */
			startFields: getStoreGetter('START_BLOCK_FIELDS', 'BLOCKS'),
		}),
		tableFields: function () {
			return formatForPredefinedFieldsTable(
				this.documents,
				this.startFields
			);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			let matches = [];

			if (this.documentFilter && this.documentFilter !== 0) {
				// loop over all table rows
				matches = this.tableFields.filter((row) => {
					// filter on Document
					return Helpers.filterByValue(
						row,
						'document',
						this.documentFilter,
						0
					);
				});

				// return if no rows match filter
				if (matches.length < 1) return matches;
			}

			// filter on search string
			matches = Helpers.filterByString(
				matches,
				this.searchableCols
			);

			// also add ancestors of matches to matches
			if (matches.length < this.tableFields.length) {
				Field.addAncestorsOfMatches(
					matches,
					this.tableFields,
					'sort_name'
				);
			}

			return matches;
		},
		selectedCount: function () {
			return this.localCheckedRows.filter((row) => row.key !== 'document')
				.length;
		},
	},
	methods: {
		connectionUpdated: async function () {
			// reset document select options
			this.documentOptions = [];
			this.documents = [];
			// set chosen document to null because we are using vue-select and vue-select accept null as no value 
			this.documentFilter = null,

			// get documents from api
			this.documents = await useApiAsync(GET_DOCUMENTS , {
				keys: {
					connection: this.connection
				}
			});

			if (this.documents && this.documents.length > 0) {
				// add mapped options to select field
				this.documentOptions.push(
					...Helpers.mapObjectArrayForSelect(this.documents, {
						key: 'guid',
						text: 'name',
					})
				);
			}
		},
		setCalculating: function (isActive = true) {
			this.calculating = isActive;
		},
		addSelectedFields: function () {
			if (this.checkedRows.length < 1) return false;

			const fields = [];

			this.checkedRows.forEach((row) => {
				if (row.key === 'document' || !row.document) return;

				const docFields = this.getFieldsForDocument(row.document);

				if (docFields && docFields[row.key]) {
					fields.push(docFields[row.key]);
				}
			});

			if (fields.length < 1) return false;

			// dispatch action to actually add the fields
			this.$store.dispatch(
				getStoreAction('ADD_PREDEFINED_START_FIELDS', 'BLOCKS'),
				fields
			);

			this.closeDrawer(true);
		},
		closeDrawer: function (force = false) {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (force || this.selectedCount < 1) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
		sortByFieldName(a, b, isAsc) {
			return Field.orderParentChildList(a.sort_name, b.sort_name, isAsc);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
		setRowCheckable: function (row) {
			return !row.disabled && !this.calculating;
		},
		getFieldsForDocument: function (guid) {
			if (!Array.isArray(this.documents) || this.documents.length < 1)
				return false;

			const doc = this.documents.find(
				(document) => document.guid === guid
			);

			return doc.fields || false;
		},
		rowClicked: function (row) {
			if (row.disabled || this.calculating) return false;

			this.setCalculating();

			// find index of row in currently checked rows
			const i = this.getRowIndex(row, this.localCheckedRows);

			// N.B.: make SHALLOW copy here
			// two-way binding for checked rows only works that
			// way with Buefy Table
			const newCheckedRows = Helpers.cloneObject(this.checkedRows, false);

			// check row
			if (i === -1) this.selectRow(row, newCheckedRows);
			// uncheck row
			else this.unselectRow(row, newCheckedRows);

			this.localCheckedRows = Helpers.cloneObject(
				this.checkedRows,
				false
			);

			this.setCalculating(false);
		},
		/**
		 * Called by BTable whenever a row checkbox gets (un)checked
		 *
		 * @param {Array} rows
		 *  Array of rows that are checked
		 *
		 * @param {Object} row
		 *  Newly (un)checked row by user
		 */
		handleCheckedRows(rows, row) {
			this.rowClicked(row);
		},
		checkedRowsUpdated: function () {
			this.checkedRows = Helpers.cloneObject(
				this.localCheckedRows,
				false
			);
		},
		/**
		 * Called for a row that is now checked while it
		 * was not checked before
		 *
		 * @param {Object} row
		 *  Newly checked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		selectRow: function (row, checkedRows) {
			const check = (row) => {
				if (row.disabled) return;
				const i = this.getRowIndex(row, checkedRows);
				if (i < 0) checkedRows.push(row);
			};

			if (row.key === 'document') {
				// check all document fields if document is checked
				this.tableFields.forEach((field) => {
					if (field.document === row.document) {
						check(field);
					}
				});
			} else {
				// check self
				check(row);

				// check all ancestors
				this.getRowAncestors(row, this.tableFields).forEach((field) => {
					check(field);
				});
			}

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		/**
		 * Called for a row that is now unchecked while it
		 * was checked before
		 *
		 * @param {Object} row
		 *  Newly unchecked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		unselectRow: function (row, checkedRows) {
			const uncheck = (i) => {
				if (i > -1) checkedRows.splice(i, 1);
			};

			// uncheck all document children if document gets unchecked
			if (row.key === 'document') {
				// loop over all rows
				this.tableFields.forEach((field) => {
					// check if document matches
					if (field.document === row.document) {
						// uncheck row (no need to check if it is actually checked)
						uncheck(this.getRowIndex(field, checkedRows));
					}
				});
			} else {
				// uncheck Document when any field of the doc gets unchecked
				uncheck(this.getDocumentRowIndex(row.document, checkedRows));

				// uncheck children
				this.getRowChildren(row, checkedRows).forEach((field) => {
					uncheck(this.getRowIndex(field, checkedRows));
				});

				// uncheck self
				uncheck(this.getRowIndex(row, checkedRows));
			}

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		getRowIndex: function (row, checkedRows = []) {
			let result = -1;

			checkedRows.some((checkedRow, i) => {
				if (
					checkedRow.document === row.document &&
					checkedRow.key === row.key
				) {
					result = i;
					return true;
				}
			});

			return result;
		},
		getDocumentRowIndex: function (guid, checkedRows = []) {
			let result = -1;

			const docRow = this.tableFields.find(
				(row) => row.document === guid && row.key === 'document'
			);

			return docRow ? this.getRowIndex(docRow, checkedRows) : result;
		},
		getRowChildren: function (field, fields = []) {
			return fields.filter((row) => {
				const rowName = row.sort_name.toLowerCase();
				return rowName.startsWith(
					Field.getNameWithSplitter(field.sort_name).toLowerCase()
				);
			});
		},
		getRowAncestors: function (field, fields = []) {
			const fieldName = field.sort_name.toLowerCase();

			return fields.filter((row) => {
				if (row.key === 'document') return false;
				const rowName = row.sort_name.toLowerCase();
				return fieldName.startsWith(Field.getNameWithSplitter(rowName));
			});
		},
	},
};
</script>
