import Helpers from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { metadataDocumentMeta } from '@modules/MetaDocumentBuilder/endpoints';
import Validation from '@assets/scripts/components/validationChecks';
import { debug } from '@assets/scripts/components/notifications';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_METADATA_DOCUMENTS,
	GET_REFERENCE_LISTS,
	GET_FOREIGN_REFERENCE_LIST,
} from '@modules/MetaDocumentBuilder/endpoints';

import { GET_FUNCTION_LISTS_BY_CONNECTION } from '@assets/scripts/api/config'

// translate function of vue-i18n
const { t } = i18n.global;

// possible statuses for documents
const statuses = {
	PUBLISHED: 'published',
	DRAFT: 'draft',
};

/**
 * Returns a newly created meta document
 *
 * @param {String} name
 *  Name of the Meta Document
 *
 * @param {String} description
 *  Description of the Meta Document
 *
 * @param {String} type
 *  SchemaType of Meta Document
 *
 * @returns {Object}
 *  New Meta Document
 */
export const createNewMetaDocument = ({ name, description, type }) => {
	// create and return new meta document
	return Helpers.obj.create(metadataDocumentMeta, {
		name,
		description,
		schemaType: type,
		guid: Helpers.newGuid(),
	});
};

/**
 * Gets the status of a given document as human readable text,
 * i.e.: Draft, Published etc.
 * Meant for display purposes, not for checking conditions
 *
 * @param {Object} document
 *  Document to get status of (normalized)
 *
 * @returns {String}
 *  Translated human readable status
 */
const getDocStatusName = (document) => {
	let output;

	// determine status
	switch (getDocStatus(document)) {
		case statuses.DRAFT:
			output = t('general.draft');
			break;
		case statuses.PUBLISHED:
			output = t('general.published');
			break;
	}

	return output;
};

/**
 * Gets the status of a given document
 *
 * @param {Object} document
 *  Document to get status of (normalized)
 *
 * @returns {String}
 *  Status of given document
 */
const getDocStatus = (document) => {
	// determine status
	let status = statuses.DRAFT;

	// check if document is published
	if (document.is_published === true) {
		status = statuses.PUBLISHED;
	}

	return status;
};

/**
 * Check if a given meta document can be modified by current user
 *
 * @returns {Boolean}
 */
export const userCanModifyDoc = () => {
	return usePermission('Upsert', 'MetaDocumentBuilder');
};

/**
 * Constructs a Meta Document Object from the currently active document
 * as can be used in communication with the Nominow API
 *
 * @param {Object} document
 *  Normalized version of document to construct
 *
 * @returns {Object}
 *  Fully constructed Meta Document Object
 */
export const constructFullMetaDocument = (document) => {
	// return empty object if no document is currently active
	if (!document || isEmpty(document)) return {};

	// make clone of document, because we are going to move
	// some fields around and do not want to change the
	// given normalized document
	document = Helpers.cloneObject(document);

	// sort fields based on 'sort' attribute
	document.elements.sort((a, b) => {
		return Field.orderParentChildArrayBySortOrder(a, b, document.elements);
	});

	// change the configured fields from dot notated parent-child
	// relations, to a multi-dimensional array
	const fields = Field.nestChildFields(document.elements);

	// return fully constructed document
	return Helpers.obj.construct({
		...document,
		elements: fields,
	}, metadataDocumentMeta, 'metadata');
};

/**
 * Flatten fields from multi-dimensional array to linear array
 *
 * @param {Object} document
 *  Normalized metadata document to change field setup for
 *
 * @returns {Object}
 *  Normalized metadata document with flat field setup
 */
export const flattenMetaDocumentFields = (document) => {
	// flatten fields from multi-dimensional structure
	// to linear array
	Field.flattenFields(document.elements);

	return document;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about metadataDocument to use in Table in metdataList component
 *
 * @param {Array} documents
 *  metadataDocuments (normalized)
 *
 * @returns {Array}
 * 	Array with info about given documents
 */
export const formatForTable = (documents) => {
	const result = [];

	// loop over fields
	documents.forEach((document, key) => {
		// get last modified time
		const lastModified = document.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: document.guid,
			// name used for sorting
			name: document.name,
			// schemaType of the metadataDocument
			type: document.schemaType,
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			status: getDocStatusName(document),
			edit: userCanModifyDoc() ? 'edit' : 'view',
			delete: usePermission('Delete', 'MetaDocumentBuilder'),
		});
	});

	return result;
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about fields formatted for
 * use in metadocument detail component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForMetaDocumentTable = (fields) => {
	const result = [];

	// loop over fields
	fields.forEach((field, key) => {
		field.order = field.order ?? key;

		result.push({
			order: field.order,
			key, // key, useful for handling clicks
			// name used for sorting
			name: field.name,
			level: Field.getFieldLevel(field.name),
			// name of the field
			field_name: Field.getChildName(field.name),
			// type of the field
			field_type: field.type,
			// default value of the field, if any
			default_value: Field.getDefaultValue(field)
				? Field.getDefaultValue(field)
				: t('general.dash'),
			// output types of validation
			validation: Field.getValidation(field)
				? Field.getValidation(field)
				: t('general.dash'),
			// indicator whether field is required
			required_field: Helpers.trueish(field.validation.required)
				? t('general.yes')
				: t('general.no'),
			edit: true,
			// do not show delete button for fields with children
			delete: !Field.hasChildren(field.name, fields),
		});
	});

	return result;
};

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given meta document field and returns errors array
 *
 * @param {Object} field
 *  Field to validate
 * 
 * @param {Array} fields
 *  List of fields of the document
 *
 * @param {Object} document
 *  Document to which the field belongs
 *
 * @returns {Array}
 *	array of errors
 */
const validateField = async (field, fields, document) => {
	const errors = [];

	const setError = (description) => {
		errors.push(createErrorObject(description));
	};

	// check if field name set
	if (!Validation.stringNotEmpty(field.name)) {
		setError(
			t('mb.validation.field.fieldNameEmpty')
		);	
	}
	// check if field name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(field.name)) {
		setError(
			t('mb.validation.field.fieldNameIsShort', {
				name: field.name,
			})
		);
	}
	// check if field name is unique
	else if (!Validation.propIsUnique(fields, 'name', field.name)) {
		setError(
			t('mb.validation.field.multipleFieldsWithSameName', {
				name: field.name,
			})
		);
	} else {
		// check if field name without white spacing
		if (!Validation.stringWithoutSpacing(field.name)) {
			setError(
				t('mb.validation.field.fieldNameContainsSpace', {
					name: field.name,
				})
			);
		}

		// check if field has type
		if (!Validation.fieldHasType(field)) {
			setError(
				t('mb.validation.field.fieldTypeMissing', {
					name: field.name,
				})
			);
		}
		// check if type exist or known in the app
		else if (!Validation.fieldTypeExists(field)) {
			setError(
				t('mb.validation.field.fieldTypeUnknown', {
					name: field.name,
					type: field.type,
				})
			);
		}

		// check if field uses List Function
		if (field.validation.element.ref.guid) {
			// get function lists
			const functionLists = await useApiAsync(GET_FUNCTION_LISTS_BY_CONNECTION, {
				keys: {
					connection: field.validation.element.ref.conn_guid
				}
			});

			// get specific function list
			const functionList = functionLists.find((el) => el.guid === field.validation.element.ref.guid);

			// check if function list exists
			if (!Validation.isNonEmptyObject(functionList)) {
				setError(
					t('mb.validation.field.functionListUnknown', {
						name: field.name,
					})
				);
			} else {
				// check if input type match the function list key input type
				if (
					!Validation.fieldTypeMatchesFunctionListKeyFieldType(
						functionList,
						field
					)
				) {
					setError(
						t('mb.validation.field.typesNotMatchInFunctionList', {
							name: field.name,
						})
					);
				}

				// check if input max length match the function list max length and regex
				if (
					!Validation.fieldTypeMaxLengthMatchesFunctionListKeyFieldMaxLength(
						functionList,
						field
					)
				) {
					setError(
						t('mb.validation.field.maxLengthsNotMatchInFunctionList', {
							name: field.name,
						})
					);
				}
			}
		}

		if (field.validation.type === 'referencelist') {
			// get functionList
			const referenceLists = await useApiAsync(GET_REFERENCE_LISTS);

			// check if function list exists
			if (!Validation.checkIfReferenceListExist(referenceLists, field.validation.regex)) {
				setError(
					t('mb.validation.field.referenceListsUnknown', {
						name: field.name,
					})
				);
			}
		}

		if (field.validation.type === 'foreignreference') {
			// get functionList
			const foreignReferenceLists = await useApiAsync(GET_FOREIGN_REFERENCE_LIST);

			// check if function list exists
			if (!Validation.checkIfForeignReferenceListExist(foreignReferenceLists.items, field.validation.regex)) {
				setError(
					t('mb.validation.field.foreignReferenceListsUnknown', {
						name: field.name,
					})
				);
			}
		}

		// check if static default of the field has value
		if (!Validation.fieldHasStaticDefault(field)) {
			setError(
				t('mb.validation.field.defaultValueForStaticMissing', {
					name: field.name,
				})
			);
		}

		if(Field.getVarType(field.type) === 'BASICTYPEARRAY'){
			// check if field array element type is set
			if (!Validation.stringNotEmpty(field.arrayElementType)) {
				setError(
					t('mb.validation.field.fieldArrayElementTypeNotChosen', {
						name: field.name,
					})
				);
			}
		}

		if(Field.getVarType(field.type) === 'DOCUMENTTYPE' || Field.getVarType(field.type) === 'DOCUMENTARRRAY' ){
			// check if field metadata doc is set
			if (!Validation.stringNotEmpty(field.document)) {
				setError(
					t('mb.validation.field.fieldArrayElementTypeNotChosen', {
						name: field.name,
					})
				);
			} else {
				// get meta data docs
				const metadataDocs =  await useApiAsync(GET_METADATA_DOCUMENTS);

				// filter all meta documents and get the meta document with the same name as field meta document
				const metaDoc = metadataDocs.find(doc => doc.name === field.document && doc.is_published)
				// check if metadata document exists
				if (!Validation.isNonEmptyObject(metaDoc)) {
					setError(
						t('mb.validation.field.metadatDocumentUnknown', {
							name: field.name,
						})
					);
				}
			}
		}
	}

	return errors;
};

/**
 * Validates given meta document and returns errors array
 *
 * @param {Object} document
 *  Metadata document to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateMetaDocument = async (document) => {
	debug('Validating', { document });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	// check for empty meta document name
	if (!Validation.stringNotEmpty(document.name)) {
		setError(t('mb.validation.docNameEmpty'));
	}
	// check if document name has atleast 2 characters
	else if (!Validation.stringHasAtleastTwoCharacters(document.name)) {
		setError(t('mb.validation.docNameIsShort'));
	} else {
		// check if document name without white spacing
		if (!Validation.stringWithoutSpacing(document.name)) {
			setError(t('mb.validation.docNameContainsSpace'));
		}
		// get all meta documents
		const metaDocuments = await useApiAsync(GET_METADATA_DOCUMENTS);

		if (metaDocuments) {
			const list = [];

			// create list of meta document names, excluding document that is
			// being validated
			metaDocuments.forEach((doc) => {
				if (doc.guid !== document.guid) list.push(doc.name.toLowerCase());
			});

			// check if meta document name already exists
			if (Validation.stringExistsInList(document.name.toLowerCase(), list)) {
				setError(
					t('mb.validation.docNameNotUnique')
				);
			}
		}
	}
	// add metta document fields to variable
	const fields = document.elements;

	// check if at least 1 field is set
	if (!Validation.isNonEmptyArray(fields)) {
		setError(
			t('mb.validation.noFieldsSet')
		);	
	} else {
		// loop over fields, using for loop because of
		// the await inside
		for (const field of fields) {
			output = output.concat(await validateField(field, fields, document));
		}
	}

	return output;
}