<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.actionSortingFields.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="addSelectedFields"
	>
		<div
			class="content-header has-padding has-bottom-divider is-align-right"
		>
			<VSearch v-model="search" />
		</div>

		<BTable
			narrowed
			sticky-header
			scrollable
			hoverable
			@click="rowClicked"
			default-sort="field_name"
			default-sort-direction="ASC"
			:data="filteredData"
			:rowClass="getRowClass"
			:isRowCheckable="setRowCheckable"
			:checkable="true"
			:headerCheckable="false"
			v-model:checkedRows="checkedRows"
			bTableClasses="scrollable-content"
		>
			<BTableColumn
				v-for="(column, index) in columns"
				v-bind:key="index"
				:width="column.width || null"
				:label="column.label"
				:field="column.field"
				:sortable="column.sortable"
				:cellClass="column.cellClass"
				:customSort="column.customSort || null"
			>
				<template v-slot="props">
					<div
						:class="{
							'is-indent':
								column.leveled && props.row.level > 0,
						}"
					>
						<template
							v-if="column.leveled && props.row.level > 0"
						>
							<span
								v-for="item in props.row.level + 1"
								:key="item"
								class="spacer"
							></span>
							<VIcon name="subdir" />
						</template>
						<template v-if="column.component">
							<Component
								:is="column.component"
								v-bind="column.args"
								@click.prevent.stop="
									column.click(props.row)
								"
								v-if="props.row[column.field]"
							/>
							<template v-else>&nbsp;</template>
						</template>
						<template v-else>
							{{ props.row[column.field] || column.default }}
						</template>
					</div>
				</template>
			</BTableColumn>

			<template #empty>
				<div class="has-text-centered">
					{{ $t('fb.actionSortingFields.table.noResults') }}
				</div>
			</template>
		</BTable>

		<template #footer>
			{{
				$t('fb.actionSortingFields.fieldsSelected', {
					count: selectedCount,
				})
			}}
			<VButton
				:disabled="selectedCount < 1"
				class="button-modal-footer"
				:text="$t('fb.actionSortingFields.addButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="addSelectedFields"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getStoreMutation } from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';

export default {
	name: 'ActionSortingFieldsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
		/**
		 * Config for the drawer
		 */
		config: {
			default: {
				action: {
					output: [],
				},
				callback: () => {},
				current: [],
			},
		},
	},
	data: function () {
		return {
			search: '',
			checkedRows: [],
			calculating: false,
			fields: [],
			columns: [
				{
					label: this.$t(
						'fb.actionSortingFields.table.columns.field_name'
					),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.actionSortingFields.table.columns.field_type'
					),
					field: 'field_type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'disabled',
					sortable: false,
					default: '',
					width: '120', // to keep the popup within the screen
					component: 'VTooltip',
					cellClass: 'is-button-tool',
					args: {
						text: this.$t(
							'fb.actionSortingFields.table.disabledExplanation'
						),
					},
				},
			],
		};
	},
	computed: {
		tableFields: function () {
			const result = [];

			// loop over fields
			this.config.action.output.forEach((field, key) => {
				result.push({
					key, // key, useful for handling clicks
					// name used for sorting
					sort_name: field.name,
					// name of the field
					field_name: Field.getNameAsPath(field.name),
					// type of the field
					field_type: Field.translateVarType(field.type),
					disabled:
						this.config.current.filter(
							(item) => item.field === field.name
						).length > 0,
				});
			});

			return result;
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			let matches = this.tableFields;

			// return if no rows match
			if (this.search.length === 0) return matches;

			// filter on search string
			matches = Helpers.filterByString(
				matches,
				this.search,
				this.searchableCols
			);

			return matches;
		},
		selectedCount: function () {
			return this.checkedRows.length;
		},
	},
	methods: {
		addSelectedFields: function () {
			if (this.checkedRows.length < 1) return false;

			const fields = [];

			this.checkedRows.forEach((row) => {
				fields.push(this.config.action.output[row.key]);
			});

			if (
				fields.length > 0 &&
				typeof this.config.callback === 'function'
			) {
				// use callback fn with fields
				// as param
				this.config.callback(fields);

				this.closeDrawer(true);
			}
		},
		closeDrawer: function (force = false) {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (force || this.selectedCount < 1) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
		sortByFieldName(a, b, isAsc) {
			return Field.orderParentChildList(a.sort_name, b.sort_name, isAsc);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
		setRowCheckable: function (row) {
			return !row.disabled && !this.calculating;
		},
		rowClicked: function (row) {
			if (row.disabled) return false;

			// find index of row in currently checked rows
			const i = this.getRowIndex(row, this.checkedRows);

			// N.B.: make SHALLOW copy here
			// two-way binding for checked rows only works that
			// way with Buefy Table
			const newCheckedRows = Helpers.cloneObject(this.checkedRows, false);

			// check row
			if (i === -1) this.selectRow(row, newCheckedRows);
			// uncheck row
			else this.unselectRow(row, newCheckedRows);
		},
		/**
		 * Called for a row that is now checked while it
		 * was not checked before
		 *
		 * @param {Object} row
		 *  Newly checked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		selectRow: function (row, checkedRows) {
			const check = (row) => {
				if (row.disabled) return;
				const i = this.getRowIndex(row, checkedRows);
				if (i < 0) checkedRows.push(row);
			};

			// check self
			check(row);

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		/**
		 * Called for a row that is now unchecked while it
		 * was checked before
		 *
		 * @param {Object} row
		 *  Newly unchecked row
		 *
		 * @param {Array} checkedRows
		 *  Array of currently checked rows
		 */
		unselectRow: function (row, checkedRows) {
			const uncheck = (i) => {
				if (i > -1) checkedRows.splice(i, 1);
			};

			// uncheck self
			uncheck(this.getRowIndex(row, checkedRows));

			// set checkedRows prop
			this.checkedRows = checkedRows;
		},
		getRowIndex: function (row, checkedRows = []) {
			let result = -1;

			checkedRows.some((checkedRow, i) => {
				if (checkedRow.key === row.key) {
					result = i;
					return true;
				}
			});

			return result;
		},
	},
};
</script>
