import axios from 'axios';
import Helpers from '@assets/scripts/helpers';
import { log, debug } from '@assets/scripts/components/notifications';
import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import i18n from '@assets/i18n';
import useJWT from '@assets/scripts/composables/useJWT';

const { removeJWT, token } = useJWT();

// translate function of vue-i18n
const { t } = i18n.global;

// the number of hours a JSON Web Token is valid
export const tokenHoursValid = 24;

const get = ({
	endpoint,
	keys = false,
	parameters = {},
	headers = {},
}) => {
	debug('get', { endpoint, keys, parameters, headers });

	return axios.get(
		getFullUrl(endpoint, keys, parameters),
		{
			params: getFullParams(endpoint, parameters),
			headers,
		}
	);
};

const post = ({
	endpoint,
	keys = false,
	parameters,
	headers = {},
}) => {
	debug('post', { endpoint, keys, parameters, headers });

	return axios.post(
		getFullUrl(endpoint, keys),
		getFullParams(endpoint, parameters),
		{ headers }
	);
};

const doDelete =({
	endpoint,
	keys = false,
	parameters,
	headers = {},
}) => {
	debug('delete', { endpoint, keys, parameters, headers });

	return axios.delete(
		getFullUrl(endpoint, keys),
		{ headers }
	);
};

const call = ({
	endpoint,
	keys = false,
	parameters,
	headers = {},
	handleErrors = true,
}) => {
	
	return new Promise((resolve, reject) => {
		let endpointFound = false;

		// check if endpoint object given
		if (endpoint && (typeof endpoint === 'object')) {
			endpointFound = true;
		}		
		
		if (!endpointFound) {
			debug('Endpoint not given', null, 'danger');
			throw new Error('Endpoint not given');
		}

		const {
			meta = false,
			method = 'get',
			useAuth = true,
		} = endpoint;

		let doCall = get;

		// determine http method to use
		switch (method.toLowerCase()) {
			case 'post':
				doCall = post;
				break;
			case 'delete':
				doCall = doDelete;
				break;
		}

		// check if endpoint has fixed headers configured
		if (endpoint.headers) {
			// clone endpoint headers to not modify them
			let fullHeaders = Helpers.cloneObject(endpoint.headers);

			// check if call specific headers are given
			if (typeof headers === 'object') {
				// merge specific headers into endpoint headers
				fullHeaders = Object.assign(
					fullHeaders,
					headers,
				);
			}

			// set headers to newly constructed headers
			headers = fullHeaders;
		}

		if (useAuth) headers = addAuthHeader(headers);

		// call function will always
		// return a promise
		doCall({
			endpoint,
			keys,
			parameters,
			headers,
		})
		.then((response) => {
			// show debug message in console
			debug('successful fetch', {
				parameters,
				response,
			});

			let result = response.data;

			// normalize result if meta description was given
			if (meta) {
				result = Helpers.obj.normalize(result, meta);

				debug('normalized result', result);
			}

			// resolve Promise
			resolve(result);
		})
		.catch((error) => {
			// http status not within 200-299

			if (handleErrors) {
				// handle error
				handleResponseError(error.response, error, true);
			}

			// show debug message in console
			debug('error in call', {
				parameters,
				error,
			});

			// reject promise
			reject(error);
		});
	});
};

const addAuthHeader = (headers = {}) => {
	if (typeof headers !== 'object') headers = {};
	return {
		...headers,
		Authorization: `Bearer ${token.value}`,
	};
};

const getFullUrl = (endpoint, keys = false) => {
	const removeSlashes = (value) => {
		return value.replace(/^\/+/, '').replace(/\/+$/, '');
	};

	const joinPath = (elements) => {
		return elements.map(removeSlashes).join('/');
	};

	let path = '';

	// check if endpoint is defined
	if (endpoint) {
		// use defined path of endpoint
		path = endpoint.path || '';
		
		// check if the endpoint defines a realm
		if (endpoint.realm) {
			// use the realm as prefix for the path
			path = joinPath([
				endpoint.realm,
				path,
			]);
		}
	}

	// check if keys are given
	if (keys) {
		// loop over keys
		for (const key in keys) {
			// replace placeholders in path with key value
			path = path.replaceAll(`{${key}}`, keys[key]);
		}
	}
	
	// add base path and encode
	return encodeURI(
		joinPath(
			[
				process.env.VUE_APP_API_PATH,
				path,
			]
		)
	);
};

const getFullParams = (endpoint, parameters = false) => {
	// if no parameters given, or endpoint not found,
	// return false
	if (!parameters || !endpoint)
		return false;

	// return full given parameters if no specific parameters
	// are defined for the endpoint
	if (!endpoint.parameters) {
		if (endpoint.meta && endpoint?.method === 'post') {
			// construct parameters first if needed
			return Helpers.obj.construct(parameters, endpoint.meta);
		} else {
			return parameters;
		}
	}
		
	const result = {};
	
	// loop over defined parameters for endpoint
	for (const key in endpoint.parameters) {
		if (typeof parameters[key] !== 'undefined') {
			// add parameter label with given value
			// to result
			result[endpoint.parameters[key]] = parameters[key];
		}
	}

	return result;
};

const handleResponseError = (
	response,
	error = false,
	showError = false
) => {
	const { status, data } = response;
	const message = data.message || false;

	// log actual error in console
	if (error) debug(error, null, 'danger');

	if (status >= 200 && status < 300) {
		// do nothing, all ok
	} else if (status === 401 || status === 403) {
		// unauthorized or forbidden

		// log out user
		removeJWT();
		store.commit(getStoreMutation('RESET_USER'));

		log(t('error.api.pleaseLogin'), 'warning');
	} else if (showError) {
		if (message) {
			// if error message was returned by the server, show that error
			log(message, 'danger');
		} else if (status === 400) {
			// bad request
			log(t('error.api.400'), 'danger');
		} else if (status === 404) {
			// not found
			log(t('error.api.404'), 'danger');
		} else if (status === 500) {
			// internal server error
			log(t('error.api.500'), 'danger');
		} else if (status > 500) {
			// server issues
			log(t('error.api.server'), 'danger');
		} else {
			// all other errors
			log(t('error.api.general'), 'danger');
		}
	}
};

export default call;
