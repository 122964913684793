import Helpers from '@assets/scripts/helpers';
import {
	defaultValueMeta,
	validationMeta,
} from '@assets/scripts/api/config';

const metaValidationMeta = Helpers.cloneObject(validationMeta);
metaValidationMeta.children.type = {
	name: 'Type',
	type: 'string',
	default: null,
	omitIfEmpty: true,
};

const schemaTypesMeta = {
	items: {
		name: 'Items',
		type: 'array',
		default: [],
		children: {
			key: {
				name: 'key',
				type: 'string',
				default: false,
			},
			value: {
				name: 'value',
				type: 'string',
				default: false,
			},
		},
	},
};

const foreignReferenceListMeta = {
	items: {
		name: 'Items',
		type: 'array',
		default: [],
		children: {
			key: {
				name: 'key',
				type: 'string',
				default: false,
			},
			value: {
				name: 'value',
				type: 'string',
				default: false,
			},
		},
	},
};

export const metadocFieldMeta = {
	name: {
		name: 'Name',
	},
	type: {
		name: 'Type',
	},
	description: {
		name: 'Description',
		omitIfEmpty: true,
	},
	order: {
		name: 'SortOrder',
		type: 'number',
		default: null,
		omitOnConstruct: true,
	},
	document: {
		name: 'Document',
		omitIfEmpty: true,
	},
	arrayElementType: {
		name: 'ArrayElementType',
		omitIfEmpty: true,
	},
	validation: metaValidationMeta,
	default: {
		name: 'Default',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		children: {
			...defaultValueMeta.children,
			insert: {
				name: 'Insert',
				type: 'boolean',
				default: false,
			},
			update: {
				name: 'Update',
				type: 'boolean',
				default: false,
			},
		},
	},
};

export const metadataDocumentMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: false,
	},
	description: {
		name: 'Description',
		type: 'string',
		default: false,
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_new: {
		name: 'IsNew',
		type: 'boolean',
		default: false,
		omitOnConstruct: true,
	},
	schemaType: {
		name: 'SchemaType',
		type: 'string',
		default: '',
	},
	guid: {
		name: 'DocumentGuid',
		type: 'string',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	elements: {
		name: 'Elements',
		type: 'array',
		default: [],
		recursive: true,
		omitIfEmpty: true,
		children: {
			...metadocFieldMeta,
		}
	}
};

// File exports all available Endpoints
const apiBase = 'UIServices/MetaDocumentBuilder';

export const GET_REFERENCE_LIST = {
	path: 'GetReferenceLists/{name}',
	realm: apiBase,
	meta: schemaTypesMeta,
};

export const GET_REFERENCE_LISTS = {
	path: 'GetReferenceLists',
	realm: apiBase,
};

export const GET_FOREIGN_REFERENCE_LIST = {
	path: 'GetForeignReferenceList',
	realm: apiBase,
	meta: foreignReferenceListMeta,
};

export const GET_METADATA_DOCUMENTS = {
	path: 'GetMetadataDocuments',
	realm: apiBase,
	meta: metadataDocumentMeta,
};

export const GET_METADATA_DOCUMENT_BY_ID = {
	path: 'GetMetadataDocument/{guid}',
	realm: apiBase,
	meta: metadataDocumentMeta,
};

// Save or publish Metadata Document
export const POST_METADATA_DOCUMENT = {
	path: 'MetaDocument',
	method: 'post',
	realm: apiBase,
};

// Delete Metadata Document
export const DELETE_METADATA_DOCUMENT = {
	path: 'DeleteMetadataDocument/{guid}',
	method: 'delete',
	realm: apiBase,
};
