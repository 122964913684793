import Validation from '@assets/scripts/components/validationChecks';
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_ACTION_BY_ID } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate write block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateWriteBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	if (!Validation.stringNotEmpty(block.config.guid)) {
		setBlockError(
			t('fb.flowErrors.actionNotChosen', {
				block: t('fb.blockTypes.write'),
			})
		);
	} else {
		const action = await useApiAsync(GET_ACTION_BY_ID, {
			keys: {
				guid: block.config.guid,
			}
		});

		if (!Validation.isNonEmptyObject(action)) {
			setBlockError(
				t('fb.flowErrors.actionNotExist', {
					block: t('fb.blockTypes.write'),
					action: `${block.config.name} - ${block.config.method}`,
				})
			);
		} else {
			// check if requirds field are mapped in action of a field
			if (
				!Validation.requiredFieldsMappedInAction(
					action,
					block.config.mapping || []
				)
			) {
				setBlockError(
					t('fb.flowErrors.actionMappingMissing', {
						action: `${block.config.name} - ${block.config.method}`,
						block: t('fb.blockTypes.write'),
					})
				);
			}

			if (action.type !== 'ConnectionMethod') {
				// check if atleast one key filed is mapped
				if (!Validation.keyFieldMapped(action, block.config.mapping)) {
					setBlockError(
						t('fb.flowErrors.noMappingByKeyFieldInWrite', {
							block: t('fb.blockTypes.write'),
						})
					);
				}
	
				// check if atleast two fields are mapped
				if (!Validation.twoFieldsMapped(block.config.mapping)) {
					setBlockError(
						t('fb.flowErrors.noMappingByFields', {
							block: t('fb.blockTypes.write'),
						})
					);
				}
			}

			// get all block inputs
			const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
				block.guid
			);

			block.config.mapping.forEach((mappingObject) => {
				const { fromField, toField } = Helpers.getFromToFields(
					blockInputs,
					mappingObject,
					action
				);

				// check if mapped field exist in the flow vars
				if (!Validation.mappedFieldExists(mappingObject, blockInputs)) {
					setBlockError(
						t('fb.flowErrors.actionMappingInputNotExist', {
							mappingObject: Field.getNameAsPath(
								mappingObject.from
							),
							action: `${block.config.name} - ${block.config.method}`,
							block: t('fb.blockTypes.write'),
						})
					);
				}

				// check if block input type match the action input type
				if (!Validation.mappedFieldTypesMatch(fromField, toField)) {
					setBlockError(
						t('fb.flowErrors.typeNotMatchInAction', {
							mappingObject: Field.getNameAsPath(
								mappingObject.to
							),
							block: t('fb.blockTypes.write'),
							action: `${block.config.name} - ${block.config.method}`,
						})
					);
				}

				// check if block input max length match the action input max length and regex
				if (fromField) {
					if (
						!Validation.fieldValidationsMaxLengthMatch(
							fromField.validation.max,
							toField.validation.max,
							true
						)
					) {
						setBlockError(
							t('fb.flowErrors.maxLengthNotMatchInAction', {
								fromField: Field.getNameAsPath(fromField.name),
								fromFieldMaxLength: fromField.validation.max || t('general.none'),
								toField: Field.getNameAsPath(toField.name),
								toFieldMaxLength: toField.validation.max,
								block: t('fb.blockTypes.write'),
							})
						);
					}
				}
			});
		}
	}
	return output;
};
