import { getStoreGetter } from '@assets/scripts/store/config';
import { IS_DEV } from '@assets/scripts/helpers';

export const names = {
	REST_API_MODIFIED: 'restApiModified',
	API_USER_MODIFIED: 'apiUserModified',
	CURRENT_REST_API: 'currentRestApi',
	CURRENT_API_USER: 'currentApiUser',
	DATA_LOSS_POSSIBLE_HOOK: 'dataLossPossible',
    SHOW_CREATE_NEW: 'showCreateNew',
    REST_API_ACTIVE: 'restApiActive',
};

export default {
	[names.REST_API_MODIFIED]: ({ restApiModified }) => {
		return restApiModified;
	},
	[names.API_USER_MODIFIED]: ({ apiUserModified }) => {
		return apiUserModified;
	},
	[names.CURRENT_REST_API]: ({ currentRestApi }) => {
		return currentRestApi;
	},
	[names.CURRENT_API_USER]: ({ currentApiUser }) => {
		return currentApiUser;
	},
    [names.REST_API_ACTIVE]: ({ currentRestApi }) => {
		return Object.keys(currentRestApi).length > 0;
	},
    [names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.DATA_LOSS_POSSIBLE_HOOK]: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		return (
			rootGetters[getStoreGetter('REST_API_MODIFIED', 'RA')]
		);
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
