import Helpers from '@assets/scripts/helpers';
import { documentFieldMeta } from '@modules/DocumentBuilder/endpoints';

const fields = {
	id: {
		Name: "Id",
		Type: "Longnumber",
		IsKey: true,
		IsUpdatable: false,
		IsIdentity: true,
		Validation: {
			IsRequired: false
		}
	},
	guid: {
		Name: "Guid",
		Type: "Guid",
		IsKey: true,
		IsUpdatable: false,
		Default: {
			Insert: true,
			Update: false,
			Type: "newid"
		},
		Validation: {
			IsRequired: false
		}
	},
	CreatedByUserGuid: {
		Name: "CreatedByUserGuid",
		Type: "Guid",
		IsUpdatable: false,
		Validation: {
			IsRequired: false,
		}
	},
	CreatedDateTime: {
		Name: "CreatedDateTime",
		Type: "DateTime",
		IsUpdatable: false,
		Default: {
			Insert: true,
			Update: false,
			Type: "getdate"
		},
		Validation: {
			IsRequired: false
		}
	},
	LastModifiedByUserGuid: {
		Name: "LastModifiedByUserGuid",
		Type: "Guid",
		IsUpdatable: true,
		Validation: {
			IsRequired: false,
		}
	},
	LastModifiedDateTime: {
		Name: "LastModifiedDateTime",
		Type: "DateTime",
		IsUpdatable: true,
		Default: {
			Insert: true,
			Update: true,
			Type: "getdate"
		},
		Validation: {
			IsRequired: false
		}
	},
};

const normalizedFields = {};
for (const key in fields) {
	normalizedFields[key] = Helpers.obj.normalize(fields[key], documentFieldMeta);
}
export default normalizedFields;
