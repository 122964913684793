<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('db.referenceDrawer.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
	>
		<div class="content-header has-padding has-bottom-divider">
			<VSearch v-model="search" />
		</div>
		<SortableDataTable
			:rowClickedFn="rowClicked"
			defaultSort="name"
			:data="filteredData"
			tableClasses="scrollable-content"
			:columns="columns"
			:emptyText="$t('db.referenceDrawer.table.noResults')"
		/>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import {
	docTypes,
	formatForReferenceTable,
} from '@modules/DocumentBuilder/components/connection-document';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_DOCUMENT, 
	GET_DOCUMENTS_BY_TYPE,
} from '@modules/DocumentBuilder/endpoints';

export default {
	name: 'ActionDocumentReferenceDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
		/**
		 * Variable type the drawer is opened for
		 */
		config: {
			default: {
				callback: () => {},
			},
		},
	},
	data: function () {
		return {
			documents: [],
			search: '',
			columns: [
				{
					label: this.$t('db.referenceDrawer.table.columns.doc_name'),
					field: 'name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('db.referenceDrawer.table.columns.key'),
					field: 'key',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('db.referenceDrawer.table.columns.type'),
					field: 'type',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	mounted: async function () {
		// get all ACTION documents for current connection
		const documents = await useApiAsync(GET_DOCUMENTS_BY_TYPE, {
			keys: {
				connection: this.currentConnection,
			},
			parameters: {
				type: docTypes.ACTION,
			}
		});

		if (documents.length > 0) {
			// loop over found documents
			documents.forEach(async ({ guid }) => {
				// get full version of document
				const fullDoc = await useApiAsync(GET_DOCUMENT, {
					keys: {
						guid,
						connection: this.currentConnection
					}
				});

				// add full version to array of documents
				if (fullDoc) this.documents.push(fullDoc);
			});
		}
	},
	computed: {
		...mapGetters({
			currentConnection: getStoreGetter('CURRENT_CONNECTION', 'DB'),
		}),
		tableFields: function () {
			return formatForReferenceTable(this.documents);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		closeDrawer: function () {
			this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
		},
		rowClicked: function (row) {
			// use callback fn with document reference as param
			this.config.callback(row.ref);

			// close drawer
			this.closeDrawer();
		},
	},
};
</script>
