import ConnectionBuilder from './ConnectionBuilder/routes';
import DocumentBuilder from './DocumentBuilder/routes';
import DocumentEditor from './DocumentEditor/routes';
import FlowBuilder from './FlowBuilder/routes';
import MetaDocumentBuilder from './MetaDocumentBuilder/routes';
import MethodBuilder from './MethodBuilder/routes';
import RestApiBuilder from './RestApiBuilder/routes';
import ScriptBuilder from './ScriptBuilder/routes';
import UserManagement from './UserManagement/routes';
import ComposedDocument from './ComposedDocument/routes';

// menu items in sidebar and on homepage will appear
// in this order:
export default {
    FlowBuilder,
    DocumentBuilder,
    ComposedDocument,
    MethodBuilder,
    ConnectionBuilder,
    RestApiBuilder,
    UserManagement,
    DocumentEditor,
    MetaDocumentBuilder,
    ScriptBuilder,
};