<template>
	<div class="tabs-wrapper">
		<nav class="tabs has-bottom-divider" :class="tabClasses">
			<ul>
				<li
					v-bind:key="`tab-${index}`"
					:class="{ 'is-active': index == selectedIndex }"
					v-for="(tab, index) in tabs"
				>
					<a @click="selectTab(index)">{{ getTitle(tab) }}</a>
				</li>
			</ul>
		</nav>
		<div class="tab-content">
			<slot ref="tabs" />
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { onBeforeMount, onMounted, provide } from '@vue/runtime-core';

export default {
	name: 'VTabs',
	props: {
		tabClasses: {
			type: String,
			default: '',
		},
	},
	setup(props, { slots }) {
		const state = reactive({
			selectedIndex: 0,
			tabs: [],
			count: 0,
		});

		provide('TabsProvider', state);

		const selectTab = (i) => {
			state.selectedIndex = i;
		};

		const getTitle = (tab) => {
			const title = tab.props?.title;

			if (title) {
				return title;
			}

			return '';
		};

		onBeforeMount(() => {
			if (slots.default) {
				const addTab = (tab) => {
					state.tabs.push(tab);
				};

				slots.default().forEach((tab) => {
					if (typeof tab.type === 'symbol') {
						if (tab.children) {
							tab.children.forEach((childTab) => {
								addTab(childTab);
							});
						}
					} else {
						addTab(tab);
					}
				});
			}
		});

		onMounted(() => {
			selectTab(0);
		});

		return { ...toRefs(state), selectTab, getTitle };
	},
};
</script>
