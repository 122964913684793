import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_ACTION_BY_ID } from '@modules/FlowBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Get the defined output for an Action
 * with a given GUID
 *
 * @param {String} guid
 *  GUID of Action
 *
 * @returns {Array}
 */
export const getOutputForAction = async ({ guid }) => {
	let result = false;

	if (guid) {
		// load Action definition
		const action = await useApiAsync(GET_ACTION_BY_ID, {
			keys: {
				guid
			}
		});

		if (action) {
			const output = [];
			const addedParents = [];

			// loop over output
			action.output.forEach((field) => {
				output.push(field);

				// find all ancestor names of the field and loop over it
				Field.getAllAncestorNames(field.name).forEach((ancestor) => {
					// check if ancestor is not added yet, for instance if another
					// field has the same ancestor
					if (addedParents.includes(ancestor.toLowerCase())) return;

					// add ancestor to result
					output.push(Field.createNewParent(ancestor));

					addedParents.push(ancestor.toLowerCase());
				});
			});

			// set result
			result = output;
		}
	}

	return result;
};

/**
 * Returns a descriptive name for a given Action, that
 * can be used for display
 *
 * @param {Object} action
 *  Normalized single-level object of an Action
 *
 * @returns {String}
 *  Name to use for display purposes
 */
export const composeDisplayName = (action) => {
	if (!action || !action.name) return '-';
	else {
		return (
			action.name +
			' - ' +
			t('fb.actionMethod.' + action.method.toLowerCase())
		);
	}
};
