import ConnectionBuilder from './ConnectionBuilder/i18n';
import DocumentBuilder from './DocumentBuilder/i18n';
import DocumentEditor from './DocumentEditor/i18n';
import FlowBuilder from './FlowBuilder/i18n';
import MetaDocumentBuilder from './MetaDocumentBuilder/i18n';
import MethodBuilder from './MethodBuilder/i18n';
import RestApiBuilder from './RestApiBuilder/i18n';
import ScriptBuilder from './ScriptBuilder/i18n';
import UserManagement from './UserManagement/i18n';
import ComposedDocument from './ComposedDocument/i18n';

export default {
    cb: ConnectionBuilder,
    db: DocumentBuilder,
    de: DocumentEditor,
    fb: FlowBuilder,
    mb: MetaDocumentBuilder,
    method: MethodBuilder,
    ra: RestApiBuilder,
    sb: ScriptBuilder,
    user: UserManagement,
    cd: ComposedDocument,
};