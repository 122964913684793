<template>
	<div class="datetime-dynamic-group">
		<VInputSelect
			:options="options"
			:label="$t('general.value')"
			v-model:valueModel="value.field"
			@update:valueModel="() => inputChanged()"
		/>

		<VInputInteger
			:label="$t('general.plusMinus')"
			v-model:valueModel="value.offset"
			@update:valueModel="() => inputChanged()"
		/>

		<VInputSelect
			:options="unitOptions"
			:label="$t('general.unit')"
			v-model:valueModel="value.unit"
			@update:valueModel="() => inputChanged()"
		/>
	</div>
</template>

<script>
export default {
	name: 'DateTimeDynamic',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: [],
		},
		label: {
			type: String,
		},
	},
	data: function () {
		return {
			value: this.splitModel(this.valueModel || ''),
			modified: false,
			unitOptions: [
				{
					text: this.$t('datepicker.selectOptions.second'),
					value: 'second',
				},
				{
					text: this.$t('datepicker.selectOptions.minute'),
					value: 'minute',
				},
				{
					text: this.$t('datepicker.selectOptions.hour'),
					value: 'hour',
				},
				{
					text: this.$t('datepicker.selectOptions.day'),
					value: 'day',
				},
				{
					text: this.$t('datepicker.selectOptions.week'),
					value: 'week',
				},
				{
					text: this.$t('datepicker.selectOptions.month'),
					value: 'month',
				},
				{
					text: this.$t('datepicker.selectOptions.year'),
					value: 'year',
				},
			],
		};
	},
	mounted: function () {
		if (this.formatOutput() !== this.valueModel)
			this.$emit('update:valueModel', this.formatOutput());
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.formatOutput() !== '';
		},
		isValid: function () {
			return !this.isRequired || this.isActive;
		},
	},
	methods: {
		inputChanged: function () {
			this.modified = true;
			this.$emit('update:valueModel', this.formatOutput());
		},
		splitModel(model) {
			const result = {
				field: '',
				offset: null,
				unit: '',
			};

			const parts = model.split(' ');
			if (parts[0]) {
				result.field = parts[0];

				if (parts[1]) {
					result.offset = parseInt(parts[1]);

					if (parts[2]) {
						result.unit = parts[2].toLowerCase();
					}
				}
			}

			return result;
		},
		formatOutput() {
			// format output as, for example:
			// 	'NOW +4 HOUR'
			// 	'[CreatedDateTime] -1 YEAR'
			// 	'WEEK-START'
			let result = '';

			if (this.value.field) {
				result += this.value.field;

				if (this.value.offset && this.value.offset !== 0 && this.value.unit) {
					result += ' ';
					if (this.value.offset > 0) result += '+';
					result += `${this.value.offset} ${this.value.unit.toUpperCase()}`;
				}
			}

			return result;
		},
	},
};
</script>
