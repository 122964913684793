<template>
	<aside class="menu menu-side" @mouseleave="subMenulistExpanded = []">
		<div class="menu-logo">
			<img
				class="logo-n logo-letter"
				src="@assets/images/n.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-o-first logo-letter"
				src="@assets/images/o-first.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-m logo-letter"
				src="@assets/images/m.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-i logo-letter"
				src="@assets/images/i.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-n-second logo-letter"
				src="@assets/images/n.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-o"
				src="@assets/images/o.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-o-smile"
				src="@assets/images/o-smile.svg"
				:alt="$t('sideNavigation.logo')"
			/>
			<img
				class="logo-w logo-letter"
				src="@assets/images/w.svg"
				:alt="$t('sideNavigation.logo')"
			/>
		</div>
		<ul class="menu-list">
			<template v-for="(item, index) in menuItems" v-bind:key="index">
				<li class="menu-item" v-if="item.is_parent">
					<a
						class="menu-anchor sub-menu-trigger"
						:class="{'is-active': item.is_active}"
						@click.prevent.stop="(e) => toggleSubMenu(index)"
					>
						<VMenuItemContent
							:icon="item.meta.icon"
							:name="item.meta.title"
						/>
						<VIcon
							:name="subMenulistExpanded.includes(index)? 'chevron-up' : 'chevron-down'"
							classes="trigger-icon"
						/>
					</a>
					<ul 
						class="menu-list sub-menu-list"
						:class="{'sub-menu-list-expanded': subMenulistExpanded.includes(index)}"
					>
						<li class="menu-item" v-for="(item, index) in item.items" v-bind:key="index">
							<SideNavigationLink :item="item" />
						</li>
					</ul>
				</li>
				<li class="menu-item" v-else>
					<SideNavigationLink :item="item" />
				</li>
			</template>
		</ul>
		<ul class="menu-list is-logout">
			<li class="menu-item">
				 <RouterLink
					to="/profile"
					class="menu-anchor"
					active-class="is-active"
				>
					<VMenuItemContent
						icon="customers"
						:name="
							$t('sideNavigation.profile', {
								name: userName,
							})
						"
					/>
				</RouterLink>
			</li>
			<li class="menu-item">
				<a class="menu-anchor" @click.prevent.stop="logoutClicked">
					<VMenuItemContent
						icon="logout"
						:name="$t('sideNavigation.logoutButton')"
					/>
				</a>
			</li>
		</ul>
	</aside>
</template>

<script>
import { mapGetters } from 'vuex';
import { unref, watch } from 'vue';
import { reactive, toRefs } from '@vue/reactivity';
import ability, { userHasAccess } from '@assets/scripts/auth';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { routes } from '@assets/scripts/composables/useRoutes';
import { menuGroups } from '@assets/scripts/router';
import { useRoute } from 'vue-router';
import SideNavigationLink from '@materials/components/SideNavigationLink';

import useJWT from '@assets/scripts/composables/useJWT.js';

const { userName } = useJWT();

export default {
	name: 'TheSideNavigation',
	components: {
		SideNavigationLink
	},
	data: function () {
		return {
			subMenulistExpanded: [],
			userName,
		};
	},
	setup: function () {
		const route = useRoute();

		const state = reactive({
			menuItems: [],
		});

		const setGroupActiveState = () => {
			state.menuItems.map((item) => {
				// check if already group exist add the item to the group
				if(item.is_parent) {
					// set is_active to the parent if one of the childs is currently the active route
					item.is_active = item.items.find(i => route.fullPath.toLowerCase().includes(i.name.toLowerCase()));
				}
			})
		}

		const setMenuItems = () => {
			state.menuItems = unref(routes).filter((route) => {
				// only show menu items for routes that do not need
				// permissions, or if current user has correct permissions
				return userHasAccess(route) && route.meta.showInSideNav;
			});

			// compare a and b for sorting
			const compare = ( a, b ) => {
				if ( a.meta.order && b.meta.order && a.meta.order < b.meta.order ){
					return -1;
				}
				if ( a.meta.order && b.meta.order && a.meta.order > b.meta.order ){
					return 1;
				}
				return 0;
			}

			// make groups of items that has the same parent value
			state.menuItems.map((item) => {
				if (item.meta.parent) {
					// get parent object if already made
					const parent = state.menuItems.find(i => i.is_parent &&  i.name === item.meta.parent);
					// get the group defention object
					const groupObject = menuGroups.find(obj => obj.name === item.meta.parent);

					// check if already group exist add the item to the group
					if (parent) {
						parent.items.push(item);
						parent.items.sort(compare);
					} else {
						// if the group still not exist make one and add it to the menuitems array
						const parentObj = {
							name: item.meta.parent,
							is_parent: true,
							is_active: false,
							items: [item],
							meta:{
								title: groupObject.title,
								icon: groupObject.icon,
								order: groupObject.order
							}
						};
						state.menuItems.push(parentObj);
					}
				}
			});

			// delete grouped items
			state.menuItems = state.menuItems.filter(i => !i.meta || !i.meta.parent);

			// sort array by order
			state.menuItems.sort(compare)

			setGroupActiveState();
		};

		// watch for changes in available routes
		watch(routes, () => {
			setMenuItems();
		});

		// watch for changes in active route
		watch(route, () => {
			setGroupActiveState();
		});

		// subscribe to updates to abilities
		// https://casl.js.org/v6/en/guide/intro#update-rules
		ability.on('updated', () => {
			// set new access per route
			setMenuItems();
		});

		// initially set acces per route
		setMenuItems();

		return {
			...toRefs(state),
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether data loss is possible if user
			 * reloads, leaves, logs out, etc.
			 */
			dataLossPossible: getStoreGetter('DATA_LOSS_POSSIBLE'),
		}),
	},
	methods: {
		toggleSubMenu: function (i) {
			if (this.subMenulistExpanded.includes(i)) {
				this.subMenulistExpanded = this.subMenulistExpanded.filter(
					(item) => item !== i
				);
			} else {
				this.subMenulistExpanded.push(i);
			}
		},
		logoutClicked: function () {
			const logout = () => {
				this.$store.dispatch(getStoreAction('LOGOUT'));
			};

			if (!this.dataLossPossible) {
				// log out immediately if no data will be lost
				logout();
			} else {
				// ask confirmation before log out if data
				// could be lost
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('logout.confirm.title'),
					body: this.$t('logout.confirm.body'),
					confirmButtonText: this.$t(
						'logout.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// log out after confirmation
						logout();
					},
				});
			}
		},
	},
};
</script>
