<template>
	<VField
		:isFloating="isFloating"
		:label="label"
		:class="{ 'is-active': isActive }"
		:isError="showError"
	>
		<VSelect
			:options="options"
			v-model="value"
			@change="inputChanged"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="error"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
export default {
	name: 'VInputSelect',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
		},
		isFloating: {
			type: Boolean,
			default: true,
		},
		options: {
			type: Array,
			default: [],
		}
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'string' ? this.valueModel : '',
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.value !== '';
		},
		isValid: function () {
			return !this.isRequired || this.isActive;
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
		error: function () {
			return this.$t('error.chooseValue');
		},
	},
	methods: {
		inputChanged: function (ev) {
			this.modified = true;
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>
