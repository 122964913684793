import ConnectionBuilder from './ConnectionBuilder/auth';
import DocumentBuilder from './DocumentBuilder/auth';
import DocumentEditor from './DocumentEditor/auth';
import FlowBuilder from './FlowBuilder/auth';
import MethodBuilder from './MethodBuilder/auth';
import RestApiBuilder from './RestApiBuilder/auth';
import UserManagement from './UserManagement/auth';
import ComposedDocument from './ComposedDocument/auth';

export default {
    ConnectionBuilder,
    DocumentBuilder,
    DocumentEditor,
    FlowBuilder,
    MethodBuilder,
    RestApiBuilder,
    UserManagement,
    ComposedDocument,
};
