/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'Connection Builder',
	connectionList: {
		title: 'Connection Builder',
		addConnection: 'Add connection',
		table: {
			noResults: 'No connections found',
			columns: {
				doc_name: 'Name',
				type: 'Type',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this connection?',
				body: 'Are you sure you want to delete this connection?',
				confirmButtonText: 'Delete',
			},
		},
	},
	connDelete: {
		error: 'Something went wrong while deleting connection. Please try again.',
		success: 'Connection was succesfully deleted',
	},
	connDetails: {
		backToOverview: 'Back to overview',
		connName: 'Connection name',
		connectionSettings: 'Connection settings',
		description: 'Description',
		credentialSettings: 'Credential settings',
		close: {
			confirm: {
				title: 'Go back to connection overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		savedSuccessfully: 'Connection was successfully saved',
		publish: {
			success: 'Connection was successfully published',
			confirm: {
				title: 'Publish the Connection?',
				body: 'Are you sure you want to publish the Connection? It will overwrite the current published version, and this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
	newConnection: {
		title: 'Create new connection',
		whatName: 'Give your connection a name',
		giveDescription: 'Add a description for the new connection',
		nameInputLabel: 'Connection name',
		whatConnection: 'Which connection type will the connection credential be part of?',
		selectConnection: 'Select connection type',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new connection',
	},
	connOptions: {
		is_internal: 'Is internal',
		is_internal_info: 'Is internal explanation',
	},
	error: {
		loadConnection: 'Connection was not found',
	},
	validation: {
		connNameEmpty: 'Connection name can not be empty',
		connNameIsShort: 'Connection name should be at least 2 characters',
		connectionNameContainsSpace: 'Connection name contains a space',
		connNameNotUnique: 'Connection name already exists',
		connTypeNotExist: 'Connection type do not exists',
	},
	permissions: {
		label: 'Connection builder',
		info: 'Permissions connected to the Connection builder',
		readConnection: {
			label: 'Read connection',
			info: 'Permission to view all connections',
		},
		editConnection: {
			label: 'Edit connection',
			info: 'Permission to create and edit connections',
		},
	}
};
