import i18n from '@assets/i18n';
import Helpers from '@assets/scripts/helpers';
import { connectionMeta} from '@modules/ConnectionBuilder/endpoints';
import Validation from '@assets/scripts/components/validationChecks';
import { debug } from '@assets/scripts/components/notifications';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_CONNECTIONS,
	GET_CREDENTIAL_TYPES,
} from '@modules/ConnectionBuilder/endpoints';

// translate function of vue-i18n
const { t } = i18n.global;

/********************/
/*    VALIDATION    */
/********************/

const createErrorObject = (description) => {
	return Helpers.createErrorObject(description);
};

/**
 * Validates given connection and returns errors array
 *
 * @param {Object} connection
 *  Connection to validate
 *
 * @returns {Array}
 *	array of errors
 */
export const validateConnection = async (connection) => {
	debug('Validating', { connection });

	let output = [];

	const setError = (description) => {
		output.push(createErrorObject(description));
	};

	// check for empty connection name
	if (!Validation.stringNotEmpty(connection.name)) {
		setError(
			t('cb.validation.connNameEmpty')
		);
	} 
	// check if connection name has atleast 2 characters
	else if(!Validation.stringHasAtleastTwoCharacters(connection.name)){
		setError(
			t('cb.validation.connNameIsShort')
		);	
	}
	else {
		// check if field name without white spacing
		if (!Validation.stringWithoutSpacing(connection.name)) {
			setError(t('cb.validation.connectionNameContainsSpace'));
		}

		// get all connections
		const connections = await useApiAsync(GET_CONNECTIONS);

		if (connections) {
			const list = [];

			// create list of connection names, excluding connection that is
			// being validated
			connections.forEach((conn) => {
				if (conn.conn_guid !== connection.conn_guid) list.push(conn.name.toLowerCase());
			});

			// check if connection name already exists
			if (Validation.stringExistsInList(connection.name.toLowerCase(), list)) {
				setError(
					t('cb.validation.connNameNotUnique')
				);
			}
		}

		// get all credential types
		const credentialTypes = await useApiAsync(GET_CREDENTIAL_TYPES);

		if (credentialTypes) {
			const list = [];

			// create list of credentialTypes names
			credentialTypes.forEach((type) => {
				list.push(type.name);
			});
			
			// check if connection type already exists
			if (!Validation.stringExistsInList(connection.type, list)) {
				setError(
					t('cb.validation.connTypeNotExist')
				);
			}
		}
	}
	return output;
};

/**
 * Check if a given connection can be modified by current user
 *
 * @returns {Boolean}
 */
export const userCanModifyConn = () => {
	return usePermission('Upsert', 'ConnectionBuilder');
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about connections to use in Table in ConnectionList component
 *
 * @param {Array} connections
 *  Array of connection objects
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForTable = (connections) => {
	const result = [];

	// loop over fields
	connections.forEach((connection, key) => {
		// get last modified time
		const lastModified = connection.modified;

		result.push({
			key, // key, useful for handling clicks
			guid: connection.conn_guid,
			// name used for sorting
			name: connection.name,
			// type of the connection
			type: connection.type,
			// time as ISO string, used for sorting
			time: lastModified,
			// localized time for display
			last_time_edited: lastModified
				? Helpers.date.localeStringWithMinutes(lastModified)
				: t('general.dash'),
			edit: userCanModifyConn() ? 'edit' : 'view',
			delete: usePermission('Delete published', 'ConnectionBuilder'),
		});
	});

	return result;
};

/**
 * Returns a newly created connection
 *
 * @param {String} name
 *  Name of the Connection
 *
 * @param {String} description
 *  Description of the connection
 *
 * @param {String} type
 *  Type of connection
 *
 * @returns {Object}
 *  New connection
 */
export const createNewConnection = ({ name, description, type }) => {
	// get current date
	const dateIso = new Date().toISOString();

	// create and return new connection
	const result = Helpers.obj.create(connectionMeta, {
		name,
		description,
		type: type,
		conn_guid: Helpers.newGuid(),
		created: dateIso,
		modified: dateIso,
		is_new: true,
	});

	result.flow_credential.credential_guid = Helpers.newGuid();
	result.flow_credential.type = type;

	return result;
};