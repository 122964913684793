import { getStoreGetter } from '@assets/scripts/store/config';
import { IS_DEV } from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { constructFullFlow } from '@modules/FlowBuilder/components/flow';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	EDIT_MODE: 'editMode',
	TEST_MODE: 'testMode',
	CAN_BE_SAVED: 'canBeSaved',
	CAN_BE_PUBLISHED: 'canBePublished',
	CAN_BE_VALIDATED: 'canBeValidated',
	FLOW_ACTIVE: 'flowActive',
	CURRENT_FLOW: 'currentFlow',
	FULL_FLOW: 'fullFlow',
	BLOCK_ERRORS: 'blockErrors',
	FLOW_METHOD: 'flowMethod',
	IS_SCRIPT_FLOW: 'isScriptFlow',
	DATA_LOSS_POSSIBLE_HOOK: 'dataLossPossible',
};

export default {
	[names.EDIT_MODE]: ({ editMode, canBeModified }) => {
		return editMode && canBeModified;
	},
	[names.TEST_MODE]: ({ testMode }) => {
		return testMode;
	},
	[names.CAN_BE_SAVED]: ({ canBeModified, modified }) => {
		// can be saved if it can be modified &
		// a change has been made by the user
		return canBeModified && modified;
	},
	[names.CAN_BE_PUBLISHED]: ({ canBeModified }) => {
		// check for correct permissions
		return (canBeModified && usePermission('Publish', 'FlowBuilder'));
	},
	[names.CAN_BE_VALIDATED]: ({ canBeModified }) => {
		// if a flow can be modified, it can also be validated
		return canBeModified;
	},
	[names.FLOW_ACTIVE]: ({ currentFlow }) => {
		return Object.keys(currentFlow).length > 0;
	},
	[names.CURRENT_FLOW]: ({ currentFlow }) => {
		return currentFlow;
	},
	[names.FULL_FLOW]: ({ currentFlow }) => {
		return !isEmpty(currentFlow) && IS_DEV ? constructFullFlow() : {};
	},
	[names.BLOCK_ERRORS]:
		(state, getters, rootState, rootGetters) => (guid) => {
			const flowErrors = rootGetters[getStoreGetter('VALIDATION_ERRORS')];
			return flowErrors.filter((object) => object.blockGuid === guid);
		},
	[names.FLOW_METHOD]: ({ currentFlow }) => {
		return !isEmpty(currentFlow) ? currentFlow.method : false;
	},
	[names.IS_SCRIPT_FLOW]: ({ currentFlow }) => {
		return currentFlow.is_script || false;
	},
	[names.DATA_LOSS_POSSIBLE_HOOK]: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		const editMode = rootGetters[getStoreGetter('EDIT_MODE', 'FLOW')];
		const canBeSaved = rootGetters[getStoreGetter('CAN_BE_SAVED', 'FLOW')];
		const blockActive =
			rootGetters[getStoreGetter('BLOCK_ACTIVE', 'BLOCKS')];

		return editMode && (canBeSaved || blockActive);
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
