import modules from '@modules/i18n';

/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
const translations = {
	general: {
		ok: 'OK',
		cancel: 'Cancel',
		true: 'True',
		false: 'False',
		yes: 'Yes',
		no: 'No',
		none: 'None',
		close: 'Close',
		closeAll: 'Close all',
		clearSearch: 'Clear search',
		all: 'All',
		active: 'Active',
		activate: 'Activate',
		inactive: 'Inactive',
		deactivate: 'Deactivate',
		draft: 'Draft',
		published: 'Published',
		archived: 'Archived',
		search: 'Search',
		notApplicable: 'n.a.',
		dash: '-',
		summary: 'Summary',
		or: 'Or',
		parent: 'Parent',
		edit: 'Edit',
		delete: 'Delete',
		inspect: 'Inspect',
		input: 'Input',
		output: 'Output',
		asc: 'Ascending',
		ascending: 'Ascending',
		desc: 'Descending',
		descending: 'Descending',
		value: 'Value',
		plusMinus: 'Plus/minus',
		unit: 'Unit',
		save: 'Save',
		field: 'Field',
		fields: 'Fields',
		methods: {
			post: 'Write',
			get: 'Read',
			put: 'Put',
			delete: 'Delete',
		},
	},
	info: {
		autoMapping: '0 fields automatically matched | 1 field automatically matched. Please check to make sure the match is correct. | {count} fields automatically matched. Please check to make sure all matches are correct.',
	},
	error: {
		general: 'Something went wrong, please try again',
		loadFlow: 'Unable to load flow',
		setFlowName: 'An error occured while updating the name of the flow',
		enterInteger: 'Please enter an integer',
		enterIntegerWithMin:
			'Please enter an integer with a minimum value of {min}',
		enterValue: 'Please enter a value',
		chooseValue: 'Please choose a value',
		fieldNameError: {
			empty: 'Please enter a field name',
			exists: 'Field with this name already exists',
		},
		fieldTypeError: 'Please select a field type',
		updateError: 'Something went wrong while updating. Please try again.',
		api: {
			400: 'Bad request, please try again',
			404: 'Action not found on server',
			500: 'Internal server error occured, please try again',
			server: 'Error occured on the server, please try again',
			general: 'An error occured, please try again',
			pleaseLogin: 'Please login to perform this action',
		},
	},
	field: {
		name: 'Field name',
		type: 'Type',
		default_value: 'Default value',
		required_field: 'Required field',
		validation: 'Validation',
		is_returnable: 'Returnable',
		updateSuccess: 'Changes were saved successfully',
		newCreated: 'Added field <i>{field}</i>',
		alias: 'Alias',
	},
	baseOverlay: {
		json: 'json',
	},
	appOptions: {
		buttons: {
			save: 'Save',
			publish: 'Publish',
			validate: 'Validate',
			validated: 'Validated',
			validationInprogress: 'Validating...',
			requiresValidation: 'Requires validation',
			validationToggleWithCount: 'Validation ({count})',
			clearValidation: 'Clear validation',
			refreshValidation: 'Refresh',
			validationToggle: 'Validation',
		},
		notifications: {
			validationInProgressNotification: 'Validation in progress, please wait',
			isValidNotification: 'No errors were found',
			requiresValidationNotification: 'Validation is needed',
		},
	},
	fieldDetailsEditor: {
		fieldSettings: 'Field settings',
		isFieldRequired: 'Is the field required?',
		metaDocumentTitle: 'Choose a metadata document',
		metaDocument: 'Metadata document',
		elementsTypeTitle: 'Choose elements type',
		elementsType: 'Elements type',
		addDefaultValue: 'Do you want to add a default value?',
		whenToUseDefaultValue: 'When should the default value be used?',
		defaultValueType: 'Default type',
		useValidationQuestion: 'Do you want to validate the field?',
		useTool: 'Use tool from the reference list for validating',
		useForeignReferenceList: 'Use foreign reference list for validating',
		useReferenceList: 'Use reference list for validating',
		referenceList: 'Select a reference list',
		foreignReferenceList: 'Select a foreign reference list',
		selectTool: 'Select tool',
		useMaxLength: 'Set max length',
		useRegex: 'Set regular expression',
		fieldOptions: {
			is_filter: 'Is filter',
			is_filter_info: 'Can the field be used as filter?',
			is_id: 'Is ID',
			is_id_info: 'Marks field as an ID field',
			is_key: 'Is key',
			is_key_info: 'Marks the field as a key field',
			is_search: 'Is search',
			is_search_info: 'Can the field be used in search?',
			is_updatable: 'Is updatable',
			is_updatable_info: 'Can the value of the field be changed after creation?',
			is_returnable: 'Is returnable',
			is_returnable_info: 'If returnable it will be returned and stored in memory',
			is_map: 'Is mapping',
			is_map_info: 'Is this field used for mapping?',
		},
		fieldDefaultOptions: {
			insert: 'On insert',
			insert_info: 'Sets default value for newly created items',
			update: 'On update',
			update_info: 'Sets default value for updated items',
		}
	},
	fieldConfig: {
		addNewLabel: 'Name of the field',
		fieldType: 'Field type',
		addNewButton: 'Add field',
	},
	datepicker: {
		selectOptions: {
			second: 'Second',
			hour: 'Hour',
			minute: 'Minute',
			day: 'Day',
			week: 'Week',
			month: 'Month',
			year: 'Year',
		},
		dynamicOptions: {
			'now': 'Now',
			'week-start': 'Start of week',
			'month-start': 'Start of month',
			'year-start': 'Start of year',
		},
		inputLabel: 'Number',
		dateFieldlabel: 'Date',
		selectLabel: 'Choose keywords',
		boxText:
			'This is a long text with information about the date component and its inputs.',
	},
	validation: {
		maxlength: 'Max length',
		maxlengthWithValue: 'Max length: {max}',
		regex: 'Regular expression',
		foreignReference: 'Foreign reference list',
		referenceList: 'Reference list',
		functionList: 'Function list',
	},
	varType: {
		string: 'String',
		array: 'Array',
		date: 'Date',
		datetime: 'Date & time',
		number: 'Number',
		guid: 'GUID',
		decimal: 'Decimal',
		boolean: 'Boolean',
		object: 'Object',
		longnumber: 'Long number',
		basictypearray: 'Basic type array',
		keyvalue: 'Key value',
		documenttype: 'Document type',
		documentarray: 'Document array',
		json: 'JSON',
		password: 'Password',
	},
	vueSelect: {
		placeholder: 'Type to filter',
	},
	defaultType: {
		newid: 'New GUID',
		newnumid: 'New numeric ID',
		getdate: 'Current date & time',
		currentuser: 'Current user',
		staticvalue: 'Static',
		script: 'Script',
	},
	defaultValue: {
		string: {
			label: 'Default value',
			requiredError: 'Field is required',
		},
		integer: {
			label: 'Default value',
		},
		boolean: {
			label: 'Default value',
			requiredError: 'Field is required',
		},
	},
	sideNavigation: {
		logo: 'Nominow - Logo',
		logoutButton: 'Log out',
		profile: 'Profile ({name})',
		dashboard: 'Dashboard',
		home: 'Home',
		customers: 'Customers',
		segment: 'Segment Builder',
		data: 'Datacleansing',
		report: 'Reporting',
		queue: 'Queue',
		pageTitle: '{page} - Nominow',
		pageTitleDefault: 'Nominow',
		adminGroup: 'Admin Modules',
		builderGroup: 'Admin Builder',
		managementGroup: 'Management',
		leave: {
			confirm: {
				title: 'Leave page?',
				body: 'Are you sure you want to leave this page? Unsaved data will be lost.',
				confirmButtonText: 'Leave',
				cancelButtonText: 'Stay',
			},
		},
	},
	pages: {
		home: {
			pageTitle: 'Home',
			pageText:
				'Welcome to the Nominow application. Please choose a page from the menu to get started.',
		},
		profile: {
			pageTitle: 'Profile',
			pageText:
				'Welcome to your profile',
		},
		dashboard: {
			pageTitle: 'Dashboard',
			pageText:
				'Welcome to the Nominow dashboard. Please choose a page from the menu to get started.',
		},
		login: {
			pageTitle: 'Login',
		},
		requestNewPassword: {
			pageTitle: 'Request new password',
		},
		resetPassword: {
			pageTitle: 'Reset your password',
		},
	},
	requestNewPassword: {
		noEmail: 'Please enter your email address',
		noValidEmail: 'Please enter a valid email address',
		notification: 'The email address does not exist',
		success: 'Check your email to reset your password',
		title: 'Request new password',
		label: {
			email: 'Email',
		},
		helpText:
			'Enter your email address to recive an email and reset your password',
		button: 'Send',
	},
	resetPassword: {
		noNewPassword: 'Please enter your new password',
		noConfirmPassword: 'Please confirm your password',
		noTwoFA: 'Please enter your two factor authentication code',
		passwordNoMatch: "Passwords don't match",
		passwordNoMatchRequirements: 'Your password does not match the requirements',
		notification:
			'An error occured, please try again or contact your admin',
		success: 'Password was successfully changed',
		title: 'Reset your password',
		label: {
			newPassword: 'New password',
			confirmPassword: 'Confirm your password',
		},
		helpText: 'Your password must be 8 characters or longer and must contain at least 1 lowercase character, 1 uppercase character, 1 numeric character and 1 special character.',
		button: 'Save',
		nextButton: 'Next',
	},
	deviceEnrollment: {
		title: 'Enable your two factor authentication',
		description: 'Open your preferred Authenticator app on your phone and scan this QR code.',
		alternativeDescription: 'Or enter the following code manually:',
	},
	twoFA: {
		label: 'Two factor authentication code',
		placeholder: '000000',
	},
	changePassword: {
		noCurrentPassword: 'Please enter your current password',
		noNewPassword: 'Please enter your new password',
		noConfirmPassword: 'Please confirm your password',
		passwordNoMatch: "Passwords don't match",
		passwordNoMatchRequirements: 'Your new password does not match the requirements',
		notification:
			'An error occured, please try again or contact your admin',
		success: 'Password was successfully changed',
		title: 'Change your password',
		label: {
			currentPassword: 'Current password',
			newPassword: 'New password',
			confirmPassword: 'Confirm your password',
		},
		currentPasswordHelpText: 'Type your current password to be able to change you password',
		newPasswordHelpText: 'Your password must be 8 characters or longer and must contain at least 1 lowercase character, 1 uppercase character, 1 numeric character and 1 special character.',
		changePasswordButton: 'Change password',
	},
	login: {
		noEmail: 'Please enter your email address',
		noValidEmail: 'Please enter a valid email address',
		noTwoFA: 'Please enter your two factor authentication code',
		noPassword: 'Please enter your password',
		notification: 'The email address and password do not match',
		twoFANotification: 'The two factor authentication code is incorrect',
		success: 'You are logged in',
		title: 'Log in',
		label: {
			email: 'Email',
			password: 'Password',
			twoFA: 'Two factor authentication code',
		},
		placeholder: {
			twoFAPlaceholder: '000000',
		},
		button: 'Log in',
		nextButton: 'Next',
		forgotPassword: 'Forgot your password?',
	},
	logout: {
		success: 'You are succesfully logged out',
		autoLoggedOut: 'You are automatically logged out',
		confirm: {
			title: 'Log out?',
			body: 'Are you sure you want to log out? Unsaved data will be lost.',
			confirmButtonText: 'Log out',
		},
	},
	jsonform: {
		placeholder: {
			oldPassword: 'Old password',
		},
	},
}

// loop over modules
for (const module in modules) {
	// add module translations to existing messages
	Object.assign(translations, {
		[module]: modules[module]
	});
}

export default translations;
