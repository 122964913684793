import { getStoreGetter } from '@assets/scripts/store/config';
import { IS_DEV } from '@assets/scripts/helpers';
import usePermission from '@assets/scripts/composables/usePermission';

export const names = {
	MODIFIED: 'modified',
    SHOW_CREATE_NEW: 'showCreateNew',
    CONNECTION_ACTIVE: 'connectionActive',
    DATA_LOSS_POSSIBLE_HOOK: 'dataLossPossible',
	CAN_BE_EDITED: 'canBeEdited',
	CAN_BE_PUBLISHED: 'canBePublished',
	CURRENT_CONNECTION: 'currentConnection',
};

export default {
	[names.MODIFIED]: ({ modified }) => {
		return modified;
	},
	[names.CURRENT_CONNECTION]: ({ currentConnection }) => {
		return currentConnection;
	},
	[names.CAN_BE_EDITED]: ({ canBeModified }) => {
		// can be edited if it has never been published yet
		return canBeModified;
	},
	[names.CAN_BE_PUBLISHED]: (
		{ canBeModified, modified },
		getters,
		rootState,
		rootGetters
	) => {
		return (
			(canBeModified || modified) &&
			usePermission('Upsert', 'ConnectionBuilder')
		);
	},
    [names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
    [names.CONNECTION_ACTIVE]: ({ currentConnection }) => {
		return Object.keys(currentConnection).length > 0;
	},
    [names.DATA_LOSS_POSSIBLE_HOOK]: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		return (
			rootGetters[getStoreGetter('MODIFIED', 'CB')]
		);
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
