import i18n from '@assets/i18n';
const TheFlowBuilder = () => import('@modules/FlowBuilder/materials/TheFlowBuilder.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/flowbuilder',
		component: TheFlowBuilder,
		name: 'flowBuilder',
		meta: {
			title: t('fb.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'flow',
			module: 'FlowBuilder',
			order: 1,
		},
	},
];