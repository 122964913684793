<template>
	<VInputInteger
		v-model:valueModel="value"
		v-model:validModel="isValid"
		:isRequired="true"
		:label="$t('defaultValue.integer.label')"
		:isDisabled="isDisabled"
		:classes="classes"
	/>
</template>

<script>
export default {
	name: 'LongnumberDefaultValue',
	props: {
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		classes: {
			type: String,
		}
	},
	data: function () {
		return {
			value: this.valueModel,
			isValid: this.validModel,
		};
	},
	watch: {
		value() {
			this.$emit('update:valueModel', this.value);
		},
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
};
</script>
