import jwt from 'jsonwebtoken';
import { ref } from 'vue';

const loginLevel = ref(0);
const permissions = ref(false);
const mfaEnabled = ref(false);
const is_admin = ref(false);
const userName = ref('');
const jsonWebToken = ref(false);
const tokenExpiration = ref(false);

const getMfaStatus= (decodedToken) => {
    if (decodedToken.amr) {
        const amr = JSON.parse(decodedToken.amr);
        if (amr.find(string => string === 'mfa')) {
            return true;
        } else {
            return false;
        }
    }
}
export default () => {

    const setJWT = (token) => {
        let decodedToken = false;

        if(token) {
            jsonWebToken.value = token;
            decodedToken = jwt.decode(token);
        }
        
        // set login level
        loginLevel.value = decodedToken && decodedToken.acr ? Number(decodedToken.acr.split('')[1]) : 0;

        // set user name
        userName.value = decodedToken && decodedToken.sub ? decodedToken.sub : '';

        // set permissions
        permissions.value = decodedToken && decodedToken.entitlements ? JSON.parse(decodedToken.entitlements) : false;

        // set mfaEnabled
        mfaEnabled.value = decodedToken ? getMfaStatus(decodedToken) : false;

        // set is_admin
        is_admin.value = decodedToken && decodedToken.admin && decodedToken.admin === 'true' ? true : false;

        // set expiration time 
        tokenExpiration.value = decodedToken && decodedToken.exp ? new Date(decodedToken.exp * 1000) : false;

        // save to localstorage
        if(token) {
            localStorage.setItem('token', token);
        } else {
            // remove token from localstorage
            localStorage.removeItem('token');
        }
    }

    const removeJWT = () => {
        // call setJWT function with false as parameter to reset the payload values
        setJWT(false);
    }

    const init = () => {
        const token = localStorage.getItem('token');
        if (token) {
            setJWT(token);
        }
    }

    return {
        loginLevel,
        userName,
        permissions,
        mfaEnabled,
        is_admin,
        token: jsonWebToken,
        tokenExpiration,
        setJWT,
        removeJWT,
        init
    }
}