<template>
	<VField
		:isFloating="isFloating"
		:label="label"
		:class="[{ 'is-active': isActive }, { 'has-hidden-label': labelHidden && isActive }, fieldClass]"
		:isError="showError"
	>
		<VInput
			v-model="value"
			:placeholder="label"
			:disabled="disabled || null"
			type="text"
			@input="inputChanged"
		/>
	</VField>

	<div class="notification-wrapper" v-if="showError">
		<VNotification
			:text="error"
			:isFullwidth="false"
			:hasClose="false"
			type="danger"
		/>
	</div>
</template>

<script>
import Field from '@assets/scripts/components/field';
export default {
	name: 'VInputString',
	props: {
		fieldClass: {
			type: String,
			default: '',
		},
		valueModel: {
			type: null,
		},
		validModel: {
			type: Boolean,
			default: false,
		},
		trim: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
		},
		isFloating: {
			type: Boolean,
			default: true,
		},
		trimFunction: {
			type: Function,
			default: Field.trimChildName,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelHidden: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			value: typeof this.valueModel === 'string' ? this.valueModel : '',
			modified: false,
		};
	},
	mounted: function () {
		if (this.value !== this.valueModel)
			this.$emit('update:valueModel', this.value);
		this.$emit('update:validModel', this.isValid);
	},
	watch: {
		isValid() {
			this.$emit('update:validModel', this.isValid);
		},
	},
	computed: {
		isActive: function () {
			return this.value !== '';
		},
		isValid: function () {
			return !this.isRequired || this.isActive;
		},
		showError: function () {
			return this.modified && !this.isValid;
		},
		error: function () {
			return this.$t('error.enterValue');
		},
	},
	methods: {
		inputChanged: function () {
			this.modified = true;
			if (this.trim) {
				this.value = this.trimFunction(this.value);
			}
			this.$emit('update:valueModel', this.value);
		},
	},
};
</script>
