import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import { log } from '@assets/scripts/components/notifications';
import getDefaultState from './state';
import {
	userCanModifyFlow,
	setFlowName,
} from '@modules/FlowBuilder/components/flow';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	SET_CURRENT_FLOW: 'setCurrentFlow',
	SET_NAME: 'setName',
	FLOW_MODIFIED: 'flowModified',
	SET_PUBLISHED_STATE: 'setPublishedState',
	FLOW_DELETED: 'flowDeleted',
	FLOW_STATUS_UPDATED: 'flowStatusUpdated',
	RESET: 'reset',
};

export default {
	/**
	 * Sets the current flow to newly retrieved flow
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} flow
	 *  Retrieved flow from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_CURRENT_FLOW](state, flow) {
		// save new flow to state
		state.currentFlow = flow;

		// a newly loaded flow is not modified yet
		state.modified = false;

		// determine if flow can be modified
		state.canBeModified = userCanModifyFlow(flow);
	},
	/**
	 * Updates the name of the currently active flow
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {String} newName
	 *  Name to set for flow
	 *
	 * @returns {void}
	 */
	[names.SET_NAME](state, newName) {
		// check if currently in edit mode
		if (!this.getters[getStoreGetter('EDIT_MODE', 'FLOW')]) return;

		// check name, not much validating needed since users
		// want flexibility over strictness
		if (!newName || newName.length < 1) return;

		// set name on flow
		if (setFlowName(state.currentFlow, newName)) {
			// mark flow as modified on success
			this.commit(
				getStoreMutation('FLOW_MODIFIED', 'FLOW'),
				{},
				{
					root: true,
				}
			);
		} else {
			// show error
			log(t('error.setFlowName'), 'danger');
		}
	},
	/**
	 * Updates the published state of the current flow
	 *
	 * @param {Object} currentFlow
	 *	Currently active flow
	 *
	 * @param {Boolean} status
	 *  Boolean indicating whether current flow is
	 *  published
	 */
	[names.SET_PUBLISHED_STATE]({ currentFlow }, status = false) {
		currentFlow.is_published = status;
	},
	/**
	 * Marks the current flow as modified
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @returns {void}
	 */
	[names.FLOW_MODIFIED](state) {
		state.modified = true;

		// every change to the flow means that the flow should be validated again
		this.commit(
			getStoreMutation('SET_IS_VALID'), false, { root: true }
		);
	},
	[names.FLOW_DELETED]() {
		// do nothing
		// mutation only exists so components can subscribe to it
	},
	[names.FLOW_STATUS_UPDATED]() {
		// do nothing
		// mutation only exists so components can subscribe to it
	},
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
