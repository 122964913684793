<template>
	<div class="base-layer app-layer is-active">
		<aside class="sidebar" v-if="isLoggedIn">
			<TheSideNavigation />
		</aside>
		<main class="main-content">
			<RouterView />
		</main>
	</div>
	<div class="drawer-layer app-layer">
		<Drawers v-if="isLoggedIn" />
	</div>
	<div
		class="popup-layer app-layer"
		:class="{ 'is-active': confirmationActive }"
	>
		<!-- Popups (small modals, confirmations etc) will be placed here -->
		<TheConfirmation v-if="confirmationActive" />
	</div>
	<div class="notification-layer app-layer">
		<TheNotificationBar />
	</div>
	<div
		class="loader-layer app-layer has-background"
		:class="{ 'is-active': isLoading }"
	>
		<VLoader :active="isLoading" />
	</div>
</template>

<script>
import { IS_DEV } from '@assets/scripts/helpers';
import { mapGetters } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { setAbility } from '@assets/scripts/auth';
import { fetchAvailableModules } from '@assets/scripts/composables/useModules';
import useJWT from '@assets/scripts/composables/useJWT';

import TheSideNavigation from '@materials/TheSideNavigation.vue';
import TheNotificationBar from '@materials/TheNotificationBar.vue';
import TheConfirmation from '@materials/TheConfirmation.vue';
import Drawers from '@materials/components/drawer/Drawers.vue';
import ability, { userHasAccess } from '@assets/scripts/auth';
import { log } from '@assets/scripts/components/notifications';

export default {
	name: 'App',
	components: {
		TheSideNavigation,
		TheNotificationBar,
		TheConfirmation,
		Drawers,
	},
	data: function () {
		return {
			/**
			 * Boolean to indicate if the current visitor
			 * is logged in
			 */
			isLoggedIn: false,
			autoLoggedOut: false,
			routesInitialized: false,
		};
	},
	setup: function () {
		const route = useRoute();
		const router = useRouter();
		const { init, removeJWT} = useJWT();

		const redirectUrl = { name: 'home' };

		// subscribe to updates to abilities
		// https://casl.js.org/v6/en/guide/intro#update-rules
		ability.on('updated', () => {
			// set new access per route
			// checking if user has access when logging out and in redirect to home if no access
			if (!userHasAccess(route)) {
				router.push(redirectUrl);
			}
		});

		// fetch available modules
		fetchAvailableModules();

		return {
			redirectUrl,
			removeJWT,
			jwtInit: init,
		};
	},
	mounted: function () {
		this.$store.dispatch(getStoreAction('INIT'));

		this.jwtInit();

		this.$router.beforeEach((to) => {
			const closeAllDrawers = () => {
				// close all drawers
				this.$store.dispatch(
					getStoreAction('CLOSE_ALL_DRAWERS'),
					{},
					{ root: true }
				);
			};
			// check if user is logged in
			if (
				!this.isLoggedIn &&
				![
					'login',
					'resetPassword',
					'requestPassword'
				].includes(to.name)
			) {
				// redirect to login page
				this.removeJWT();
				return { name: 'login' };
			}

			if (
				to.name === 'resetPassword' &&
				Object.keys(to.query).length === 0
			) {
				return { name: 'requestPassword' };
			}

			if (
				this.isLoggedIn &&
				[
					'login',
					'resetPassword',
					'requestPassword'
				].includes(to.name)
			) {
				return this.redirectUrl;
			}

			if (!userHasAccess(to)) {
				// redirect to home if no access
				return this.redirectUrl;
			}

			// prevent triggering navigation guard on
			// initial page load
			if (!this.routesInitialized) {
				this.routesInitialized = true;
				return true;
			}

			if (!this.dataLossPossible) {
				// close all drawers before routing
				closeAllDrawers();
				return true;
			}

			return new Promise((resolve, reject) => {
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('sideNavigation.leave.confirm.title'),
					body: this.$t('sideNavigation.leave.confirm.body'),
					confirmButtonText: this.$t(
						'sideNavigation.leave.confirm.confirmButtonText'
					),
					cancelButtonText: this.$t(
						'sideNavigation.leave.confirm.cancelButtonText'
					),
					confirmFn: () => {
						// close all drawers before routing
						closeAllDrawers();
						resolve(true);
					},
					cancelFn: () => {
						reject();
					},
				});
			});
		});

		// get login status every 10 seconds
		this.getLoginStatus(true);
		setInterval(()=>{
			this.getLoginStatus(true)
		}, 10000);

		this.$store.subscribe((mutation) => {
			if (
				mutation.type === getStoreMutation('SET_USER') ||
				mutation.type === getStoreMutation('RESET_USER')
			) {
				this.getLoginStatus();
			}
		});

		// subscribe to the store action that is executed when
		// user logs out
		this.$store.subscribeAction((action) => {
			if (action.type === getStoreAction('LOGOUT')) {
				// wait for next tick because getter does not return
				// updated store data yet inside subscribeAction
				this.$nextTick(() => {
					this.getLoginStatus();
				});
			}
		});

		window.addEventListener('beforeunload', (e) => {
			// prevent confirm prompt for each auto-reload after
			// code change on dev env
			if (this.dataLossPossible && !IS_DEV) {
				e.preventDefault();
				// Chrome requires returnValue to be set
				e.returnValue = '';
			}
		});
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if a loader should
			 * be displayed
			 */
			isLoading: getStoreGetter('IS_LOADING'),
			/**
			 * Boolean to indicate whether the confirmation
			 * popup should be displayed
			 */
			confirmationActive: getStoreGetter('CONFIRMATION_ACTIVE'),
			/**
			 * Boolean to indicate whether data loss is possible if user
			 * reloads, leaves, logs out, etc.
			 */
			dataLossPossible: getStoreGetter('DATA_LOSS_POSSIBLE'),
		}),
	},
	methods: {
		getLoginStatus: function (initial = false) {
			this.$store.dispatch(getStoreAction('CHECK_LOGGED_IN'))
			this.isLoggedIn = this.$store.getters[getStoreGetter('IS_LOGGED_IN')];
			this.autoLoggedOut = initial;
		},
	},
	// watch logged in status to update user permissions accordingly
	watch: {
		isLoggedIn(newValue) {
			// user (auto) logged out
			if (!newValue) {
				// reset user to empty out token, permissions etc.
				this.$store.dispatch(getStoreAction('RESET'));

				// reset JWT payload values and remove token from localStorage
				this.removeJWT();

				// show message
				if(this.autoLoggedOut) {
					log(this.$t('logout.autoLoggedOut'), 'warning');
				}

				this.$router.push({ name: 'login' });
			}

			// set permissions for new user
			setAbility();
		},
	},
};
</script>
