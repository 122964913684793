/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
export default {
	pageTitle: 'Metadata Builder',
	metadataList: {
		title: 'Metadata Builder',
		schemaTypes: 'Schema types',
		addMetadata: 'Add metadata document',
		table: {
			noResults: 'No documents found',
			columns: {
				name: 'Name',
				schemaType: 'Schema type',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this metadata document?',
				body: 'Are you sure you want to delete this metadata document?',
				confirmButtonText: 'Delete',
			},
		},
	},
	docDelete: {
		error: 'Something went wrong while deleting metadata document. Please try again.',
		success: 'Metadata document was succesfully deleted',
	},
	error: {
		loadDocument: 'Meta document was not found',
		loadSchemaTypes: 'schemaTypes were not found',
		canNotDropHere: 'Can not drop row in this location'
	},
	validation: {
		docNameEmpty: 'Metadata document name can not be empty',
		docNameIsShort: 'Metadata document name should be at least 2 characters',
		docNameContainsSpace: 'Metadata document name contains a space',
		docNameNotUnique: 'Metadata document name already exists',
		noFieldsSet: 'No fields are added to the metadata document yet',
		field: {
			referenceListsUnknown: 'Chosen reference list does not exist',
			foreignReferenceListsUnknown: 'Chosen foreign reference list does not exist',
			fieldNameEmpty: 'Field name is empty',
			fieldNameIsShort: 'Field <strong>{name}</strong> name should be at least 2 characters',
			multipleFieldsWithSameName: 'Multiple fields with name <strong>{name}</strong> are set',
			fieldNameContainsSpace: 'Field name <strong>{name}</strong> contains a space',
			fieldTypeMissing: 'Field <strong>{name}</strong> has no type set',
			fieldTypeUnknown: 'Field type <strong>{type}</strong> of field <strong>{name}</strong> is unknown',
			functionListUnknown: 'Used validation tool for field <strong>{name}</strong> is unknown',
			typesNotMatchInFunctionList:
				'Type of key input in validation tool for field <strong>{name}</strong> does not match the type of the field',
			maxLengthsNotMatchInFunctionList:
				'Max length of key input in validation tool for field <strong>{name}</strong> does not match the max length of the field',
			defaultValueForStaticMissing: 'No default value is set for field <strong>{name}</strong>',
			fieldArrayElementTypeNotChosen: 'No Elements type is set for field <strong>{name}</strong>',
			metadatDocumentUnknown: 'Chosen metadata document for field <strong>{name}</strong> is unknown'
		},
	},
	newDocument: {
		title: 'Create new metadata document',
		whatName: 'Give your metadata document a name',
		giveDescription: 'Add a description for the new metadata document',
		nameInputLabel: 'Metadata document name',
		whatType: 'Of which type is the new metadata document?',
		selectType: 'Select type',
		submitButton: 'Next',
		createdSuccess: 'Succesfully created new Metadata Document',
	},
	docDetails: {
		backToOverview: 'Back to overview',
		docName: 'Metadata document name',
		documentSettings: 'Document settings',
		description: 'Description',
		addField: 'Add field',
		close: {
			confirm: {
				title: 'Go back to metadata document overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		table: {
			noResults: 'No fields',
			columns: {
				name: 'Name',
				type: 'Type',
				default: 'Default value',
				validation: 'Validation',
				required: 'Required',
			},
		},
		delete: {
			confirm: {
				title: 'Delete this field?',
				body: 'Are you sure you want to delete this field? This action can not be undone.',
				confirmButtonText: 'Delete',
			},
		},
		savedSuccessfully: 'Metadata Document was successfully saved',
		publish: {
			success: 'Metadata Document was successfully published',
			confirm: {
				title: 'Publish the metadata document?',
				body: 'Are you sure you want to publish the metadata document? this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
	fieldDetails: {
		saveButton: 'Save',
		close: {
			confirm: {
				title: 'Go back to metadata document?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you go back.',
				confirmButtonText: 'Ok',
			},
		},
	},
};
