/**
 * Sorts array based on ISO date string
 *
 * @param {String} a
 *  First date string to determine sort order for
 *
 * @param {String} b
 *  Second date string to determine sort order for
 *
 * @param {Boolean} isAsc
 *  Indicates whether sorting should be done ascending
 *
 * @returns {Integer}
 */
const sortByIsoDate = (a, b, isAsc) => {
	if (!isAsc) {
		return new Date(b).getTime() - new Date(a).getTime();
	} else {
		return new Date(a).getTime() - new Date(b).getTime();
	}
};

/**
 * Return date as localized string, like for instance
 * DD/MM/YYYY, HH:MM
 *
 * @param {String} date
 *  Date as ISO string
 *
 * @returns {String}
 *  Localized date, without seconds
 */
const localeStringWithMinutes = (date) => {
	// remove trailing Z from iso string to prevent
	// JS from taking user timezone into account
	if (date) {
		date = date.replace(/Z$/i, '');

		return new Date(date).toLocaleString([], {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		});
	}
};

export default {
	sortByIsoDate,
	localeStringWithMinutes,
};
