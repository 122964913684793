import i18n from '@assets/i18n';
const TheRestApiPage = () => import('@modules/RestApiBuilder/materials/TheRestApiPage.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/restapi',
		component: TheRestApiPage,
		name: 'restapi',
		meta: {
			title: t('ra.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'gear',
			module: 'RestApiBuilder',
			parent: 'builder',
			order: 5,
		},
	},
];

