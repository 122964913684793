import Helpers from '@assets/scripts/helpers';
import { staticValue } from '@assets/scripts/api/config';
import { debug } from '@assets/scripts/components/notifications';
import { simpleFieldMeta } from '@assets/scripts/api/config';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

const varTypes = {
	ARRAY: {
		name: 'Array',
		isParent: true,
	},
	BOOLEAN: {
		name: 'Boolean',
		isParent: false,
	},
	DATE: {
		name: 'DateOnly',
		isParent: false,
	},
	DATETIME: {
		name: 'DateTime',
		isParent: false,
	},
	DECIMAL: {
		name: 'Decimal',
		isParent: false,
	},
	GUID: {
		name: 'Guid',
		isParent: false,
	},
	LONGNUMBER: {
		name: 'Longnumber',
		isParent: false,
	},
	NUMBER: {
		name: 'Number',
		isParent: false,
	},
	OBJECT: {
		name: 'Object',
		isParent: true,
	},
	STRING: {
		name: 'String',
		isParent: false,
	},
	BASICTYPEARRAY: {
		name: 'BasicTypeArray',
		isParent: true,
		context: ['meta'],
	},
	KEYVALUE: {
		name: 'KeyValue',
		isParent: false,
		context: ['meta'],
	},
	DOCUMENTTYPE: {
		name: 'DocumentType',
		isParent: false,
		context: ['meta'],
	},
	DOCUMENTARRAY: {
		name: 'DocumentArray',
		isParent: false,
		context: ['meta'],
	},
	JSON: {
		name: 'JSON',
		isParent: false,
	},
	PASSWORD: {
		name: 'Password',
		isParent: false,
		context: ['meta'],
	},
};

const NAME_SPLIT = '.';

/**
 * Returns a newly created field
 *
 * @param {String} name
 *  Name of the new field
 *
 * @param {String} type
 *  Type of Variable
 *
 * @param {Boolean} required
 *  Indicates whether new variable is required
 *
 * @param {Int} maxlength
 *  Maxlength of variable, or false
 *  Only applicable for type String
 *
 * @param {?} value
 *  Value for new field
 *
 * @returns {Object}
 *  New Field
 */
const createNew = (field, meta = simpleFieldMeta, allowEmpty = false) => {
	// check if type is known
	if (!getVarType(field.type) && !allowEmpty) {
		debug('Variable type not found', field.type, 'danger');
		return false;
		// check if name is given
	} else if (!field.name && !allowEmpty) {
		debug('No name given for new variable', field.name, 'danger');
		return false;
	}

	return Helpers.obj.create(meta, field);
};

/**
 * map the field that have the same name and type 
 * of the mapped field with the mapped field automatically
 *
 * @param {Array} fieldsToMap
 *  all fields that can be mapped
 *
 * @param {Array} mappingObjects
 *  mapped data to match with
 *
 * @param {Array} fieldsToMapwith
 *  fields to match with
 * 
 * @returns {Number}
 *  Number of auto mapped fields
 */
const setAutoMapping = (fieldsToMap, mappingObjects, fieldsToMapwith) => {
	let autoMappingCounter = 0;
	fieldsToMap.forEach(obj => {
		// get field names filtered by type
		const fieldNames = getFieldNamesByType(obj.type, fieldsToMapwith);
		// loop on the mapped fields
		mappingObjects.forEach(row => {
			// get autoMatchedField name
			const autoMatchedField = fieldNames.find(field => getNameAsPath(field).toLowerCase() === getNameAsPath(row.to).toLowerCase())
			// check if row.to is the same as the obj.name
			// check if one of the fieldNames is the same as the row.to
			if(getNameAsPath(row.to).toLowerCase() === getNameAsPath(obj.name).toLowerCase() && autoMatchedField){
				// set the row.form as the matched field name
				row.from = autoMatchedField;
				// add one number to the counter
				autoMappingCounter ++;
			}
		});
	})
	return autoMappingCounter;
};

/**
 * Creates and returns a new field that can be used
 * as parent field
 *
 * @param {String} name
 *  Base name of new field
 *
 * @param {String} suffix
 *  Optional suffix to add to the field name
 *
 * @returns {Object}
 */
const createNewParent = (name, suffix = '') => {
	return createNew({
		name: name + suffix,
		type: varTypes.OBJECT.name, // give new field the object type
	});
};

/**
 * Changes a field to make it a child of another field
 *
 * @param {Object} child
 *  Field to set as Child
 *
 * @param {Object} parent
 *  Parent field
 *
 * @returns {Object}
 *  Updated child field
 */
const makeChild = (child, parent) => {
	// update name of child field
	child.name = getJoinedName([getName(parent), getName(child)]);
	return child;
};

/**
 * Changes a field to make it a sibling ot another field
 *
 * @param {Object} field
 *  Reference field that the other field should become
 *  a sibling of
 *
 * @param {Object} newSibling
 *  Field that should become a sibling
 *
 * @returns {Object}
 *  Updated field
 */
const makeSibling = (field, newSibling) => {
	// get parent name of ref field
	const parentName = getFullParentName(getName(field));
	if (parentName)
		newSibling.name = getJoinedName([parentName, getName(newSibling)]);
	return newSibling;
};

const trimName = (name) => {
	// replace double dots by single dot
	name = name.replace(NAME_SPLIT.concat(NAME_SPLIT), NAME_SPLIT);

	// remove leading dot
	const regex = new RegExp('^\\' + NAME_SPLIT);
	name = name.replace(regex, '');

	// only allow lower and uppercase letters, number, underscroe
	// and dot for parent/child relationship
	const regex2 = new RegExp('[^a-z0-9_' + NAME_SPLIT + ']', 'gi');
	return name.replace(regex2, '');
};

const trimChildName = (childName) => {
	// only allow lower and uppercase letters and numbers and underscroe
	const regex2 = new RegExp('[^a-z0-9_]', 'gi');
	return childName.replace(regex2, '');
};

/**
 * Validates a given field name
 * Checks for empty or duplicate, and also
 * checks if parent exists and is of correct
 * type if applicable
 *
 * @param {String} fieldName
 *  Field name to check. Parent/child concatted with dots.
 *
 * @param {Array} fields
 *  Array of existing fields
 *
 * @param {Integer} key
 *  Key of updated field, if applicable, to be
 *  able to exclude current when checking for
 *  duplicates
 *
 * @returns {String/Boolean}
 *  Error message if failed, true if passed
 */
const validateName = (fieldName, fields, key = false) => {
	fieldName = trimName(fieldName);

	// check if name is empty
	if (fieldName === '') {
		return t('error.fieldNameError.empty');
	}

	// check if field with same name already exists
	if (getByName(fieldName, fields, key)) {
		return t('error.fieldNameError.exists');
	}

	return true;
};

/**
 * Helper function to check if a name exists
 * as a non-empty string
 *
 * @param {String} name
 *  Name to check
 *
 * @returns {Boolean}
 */
const nameExists = (name = '') => {
	return typeof name === 'string' && name.trim() !== '';
};

/**
 * Checks if a field with a given name has
 * at least one child
 *
 * @param {String} fieldName
 *  Field name to check. Parent/child concatted with dots.
 *
 * @param {Array} fields
 *  Array of existing fields
 *
 * @returns {Boolean}
 */
const hasChildren = (fieldName, fields) => {
	if (!fieldName) return false;

	return fields.some((field) => {
		const existingName = getName(field).toLowerCase();
		return existingName.startsWith(fieldName.toLowerCase() + NAME_SPLIT);
	});
};

/**
 * Determine whether a given field is a parent field
 * N.B.: A field does not need to have children to be
 * a parent field. Just checks for field type here.
 *
 * @param {Object} field
 *  The field to check
 *
 * @returns {Boolean}
 */
const isParent = (field) => {
	return (field && varCanHaveChildren(field.type)) || false;
};

/**
 * Checks whether 2 fields with given names are siblings,
 * i.e.: have the same direct parent
 *
 * @param {String} nameA
 *  Name of first field
 *
 * @param {String} nameB 
 *  Name of second field
 *
 * @returns {Boolean}
 */
const fieldsAreSiblings = (nameA, nameB) => {
	return getFullParentName(nameA) === getFullParentName(nameB);
};

/**
 * Add ancestor fields to list of search query matches
 * When table of start fields is searched, a specific field
 * could match the query, but it's parent or other ancestor
 * might not. This could result in a visually 'orphaned'
 * result in the table. Therefor we need to add all ancestors
 * of matches to the array of matches as well
 *
 * @param {Array} matches
 *  Array of table row objects that match the
 *  current search query
 *
 * @param {Array} rows
 *  Array of all available table row objects
 *
 * @param {String} nameProp
 *  Name of the property of table rows to use to find ancestors
 *
 * @returns {void}
 *  Returns nothing, ancestors are added to matches array, since
 *  it is a reference
 */
const addAncestorsOfMatches = (matches, rows, nameProp = 'name') => {
	let ancestorNames = [];

	// get all ancestor names for all matches
	matches.forEach((match) => {
		ancestorNames = ancestorNames.concat(
			getAllAncestorNames(match[nameProp] || '')
		);
	});

	if (ancestorNames.length > 0) {
		// loop over all table rows
		rows.forEach((row) => {
			if (
				matches.indexOf(row) === -1 && // row was not in matches already
				row[nameProp] &&
				ancestorNames.indexOf(row[nameProp]) !== -1
			) {
				// add row to matches if name is found
				// between ancestor names
				matches.push(row);
			}
		});
	}
};

const getName = (field) => {
	return field.name || '';
};

/**
 * Returns direct parent name of a given name
 * I.e.: returns 'Foo.Bar' when given value is
 * Foo.Bar.ChildName
 *
 * @param {String} name
 *  Full name of field
 *
 * @returns {String}
 *  Direct parent name
 */
const getFullParentName = (name) => {
	const splittedParent = getSplittedName(name).slice(0, -1);
	return getJoinedName(splittedParent);
};

/**
 * Returns last name part of a given name
 * I.e.: returns 'ChildName' when given value is
 * Foo.Bar.ChildName
 *
 * @param {String} name
 *  Full name of field
 *
 * @returns {String}
 *  Child name
 */
const getChildName = (name) => {
	const splittedName = getSplittedName(name);
	return splittedName.length > 0 ? splittedName.pop() : '';
};

const getNameByLevel = (name, level = 0) => {
	const splittedName = getSplittedName(name);
	return splittedName[level] || '';
};

/**
 * Returns field name incl. ancestors in 'breadcrumb'
 * style with a divider of choice
 *
 * @param {String/Object} input
 *  Field as object, or only a field name
 *
 * @param {String} divider
 * 	Symbol to use between name parts
 *
 * @returns {String}
 *  Field name as Grandparent > parent > child
 */
const getNameAsPath = (input, divider = '>') => {
	const fieldName = typeof input === 'string' ? input : getName(input);
	return getSplittedName(fieldName).join(` ${divider} `);
};

/**
 * Returns a full name, incl. ancestors if applicable,
 * based on given array of name parts
 *
 * @param {Array} name
 *  Array of name parts in order
 *
 * @returns {String}
 *  Full joined name
 */
const getJoinedName = (name) => {
	return name.join(NAME_SPLIT);
};

/**
 * Returns a given field name with the used
 * part splitter at the end
 *
 * @param {String} name
 *  Name to add name splitter to
 *
 * @returns {String}
 *  Field name with splitter at the end, like 'FieldName.'
 */
const getNameWithSplitter = (name) => {
	return name + NAME_SPLIT;
};

/**
 * Returns whether a field of a given type
 * can have children
 *
 * @param {String} varType
 *  Var type as used as value in 'varTypes' object
 *
 * @returns {Boolean}
 */
const varCanHaveChildren = (varType) => {
	const type = getVarTypeObject(varType);
	if (typeof type.isParent === 'undefined') return false;
	return type.isParent;
};

/**
 * Returns type object
 *
 * @param {String} varType
 *  Var type as used as value in 'varTypes' object
 *
 * @returns {Object}
 */
const getVarTypeObject = (varType) => {
	let typeObj = false;
	for (const key in varTypes) {
		if (varTypes[key].name === varType) {
			typeObj = varTypes[key];
		}
	}
	return typeObj;
};

/**
 * Custom sorting function for lists that include
 * parent/child relations. Children should always be
 * sorted below it's parent, no matter the sorting order.
 * While elements at the same level should be ordered
 * according to sorting order.
 *
 * @param {String} a
 *  First name to compare for sorting
 *
 * @param {String} b
 *  Second name to compare for sorting
 *
 * @param {Boolean} isAsc
 *  Indicates sorting order
 *
 * @returns {Integer}
 *  Either -1, 0 or 1
 */
const orderParentChildList = (a, b, isAsc) => {
	// set reference and compare string based
	// on sorting direction
	// split name parts by dot notation
	let ref = isAsc ? getSplittedName(a) : getSplittedName(b);
	let comp = isAsc ? getSplittedName(b) : getSplittedName(a);

	let unequal = false;

	// set fallback return for parent/child pairs
	// based on sorting order
	// a child should ALWAYS be sorted after it's (grand)parent,
	// no matter the chosen sort order
	let fallback = isAsc ? 1 : -1;

	if (ref.length > comp.length) {
		unequal = true;
		// use same number of parts for ref as comp has
		ref = ref.slice(0, comp.length);
	} else if (ref.length < comp.length) {
		unequal = true;
		// use same number of parts for comp as ref has
		comp = comp.slice(0, ref.length);

		// reverse fallback if compare string is the child (i.e. is longer)
		fallback *= -1;
	}

	// get ordering result
	const order = getJoinedName(ref).localeCompare(getJoinedName(comp));

	// if number of parts is not the same, and order result is 0,
	// return the determined fallback sort order
	// in other cases, just return sorting result
	return unequal && order === 0 ? fallback : order;
};

/**
 * Gets level of deepest mutual ancestor for 2
 * given field names
 * 
 * Examples:
 *  Foo.Bar.Sub.Child & Foo.Bar.Lorem
 * 	Result: 2
 *
 *  Foo.Bar.Lorem & Ipsum.Foo
 *  Result: 0
 *
 * @param {String} nameA
 *  Name of first field
 *
 * @param {String} nameB 
 *  Name of second field
 *
 * @returns {Integer}
 */
const getHighestMutualLevel = (nameA, nameB) => {
	let result = 0;

	// get levels for both fields
	const levelA = getFieldLevel(nameA);
	const levelB = getFieldLevel(nameB);

	// set lowest level as maximum
	const max = Math.min(levelA, levelB);

	let parentA, parentB;
	let i = 0;

	while (i <= max) {
		// get ancestor at given level for both fields
		parentA = getParentNameForLevel(nameA, i);
		parentB = getParentNameForLevel(nameB, i);

		// check if ancestors are equal
		if (parentA === parentB) result = i;

		i++;
	}

	return result;
};

/**
 * Array sorting function for array of field objects
 * that all have a numerical 'order' attribute
 * Field array can contain parent/child relations, indicated
 * with a dot between the parent and child name
 * Children are sorted directly below their parent, but still ordered
 * relatively with their siblings.
 * 
 * @param {Object} fieldA
 *  First field to compare
 *
 * @param {Object} fieldB 
 *  Second field to compare
 *
 * @param {Array} fields
 *  Full list of all fields, needed to find
 *  ancestors of given fields
 *
 * @returns {Integer}
 * 	Either -1, 0, or 1
 */
const orderParentChildArrayBySortOrder = (fieldA, fieldB, fields) => {
	let result = 0;

	// get names of given fields
	const { name: nameA } = fieldA;
	const { name: nameB } = fieldB;

	// get highest mutual level for fields
	const mutLevel = getHighestMutualLevel(nameA, nameB);

	// get ancestor (or self) at highest mutual level + 1
	// for both fields
	const ancestorA = getByName(
		getParentNameForLevel(nameA, mutLevel + 1),
		fields
	);

	const ancestorB = getByName(
		getParentNameForLevel(nameB, mutLevel + 1),
		fields
	);

	// if fields at highest mutual level + 1 are the same field,
	// it means that one of the fields is a child of the other
	if (ancestorA === ancestorB) {
		// make sure the child (the one with a higher level) is always
		// ordered after the ancestor
		return fieldA.level < fieldB.level ? -1 : 1;
	}

	// order fields based on ancestor order
	if (ancestorA.order < ancestorB.order) result = -1;
	else if (ancestorA.order > ancestorB.order) result = 1;

	return result;
};

/**
 * Create a list of fields to be used in VSelect element as options
 * filtered by given type of field
 * 
 * @param {String} type
 *  type of the field to filter with
 *
 * @param {Array} fields 
 *  Array of fields
 *
 * @returns {Array}
 */
const createFieldOptions = (type, fields) => {
    const result = [];

    fields.forEach((field) => {
        const varType = field.type;

        if (varType !== type) return;

        const name = field.name;

        result.push({
            value: name,
            text: getNameAsPath(name),
        });
    });

    return result;
};

/**
 * get fields names
 * filterd by givin type of field
 * 
 * @param {String} type
 *  type of the field to filter with
 *
 * @param {Array} fields 
 *  Array of fields
 *
 * @returns {Array}
 */
const getFieldNamesByType = (type, fields) => {
   	const result = fields.filter(field => field.type === type);
	return result.map(field => field.name);
};

/**
 * Find simple variable type based on full type
 *
 * @param {String} type
 *  Full type to find simple type for
 *
 * @returns {String/Boolean}
 *  Found type or false
 */
const getVarType = (type) => {
	let result = false;
	for (const key in varTypes) {
		if (varTypes[key].name === type) result = key;
	}
	return result;
};

/**
 * Gets and returns the level (depth) of a field with
 * a given name. Will return 0 for 'FieldName', 1 for
 * 'Parent.FieldName' etc. Used in tables that contain
 * parent/child rows.
 *
 * @param {String} name
 *  Name to get the level for
 *
 * @returns {Integer}
 */
const getFieldLevel = (name) => {
	return getSplittedName(name).length - 1;
};

/**
 * Returns translated name of given variable type
 *
 * @param {String} type
 *  Var type as used as value in 'varTypes' object
 *
 * @returns {String}
 *  Translated name or false
 */
const translateVarType = (type) => {
	let result = false;

	if (typeof varTypes[type] === 'undefined') {
		type = getVarType(type);
	}

	if (type) result = t('varType.' + type.toLowerCase());

	return result;
};

/**
 * Returns translated name of variable type of
 * field with a given name
 *
 * @param {String} name
 *  Name of field to get var type for
 *
 * @param {Array} fields
 *  Array of fields to find field with given name in
 *
 * @returns {String}
 *  Translated name or false
 */
const getTranslatedVarTypeForField = (name, fields) => {
	const field = getByName(name, fields);
	return field ? translateVarType(field.type) : false;
};

/**
 * Function to remove all parent fields (i.e. objects and arrays)
 * from a given list of fields
 *
 * @param {Array} fields
 *  List of fields to filter
 *
 * @returns {Array}
 * 	Filtered list of fields
 */
const filterOutParentFields = (fields = []) => {
	return fields.filter((field) => {
		return !varCanHaveChildren(field.type);
	});
};

/**
 * Gets all sibling fields of a field
 * with a given name
 *
 * @param {String} name
 *  Name of field to get siblings for
 *
 * @param {Array} fields
 *  List of fields to search for siblings
 *
 * @returns {Array}
 *  Array of sibling fields, excluding field with
 *  given name
 */
const getSiblings = (name, fields = []) => {
	// get level of field
	const fieldLevel = getFieldLevel(name);

	// determine full ancestor prefix
	const parentPrefix = getNameWithSplitter(
		getFullParentName(name)
	).toLowerCase();

	return fields.filter((field) => {
		return (
			name.toLowerCase() !== field.name.toLowerCase() && // exclude self
			fieldLevel === getFieldLevel(field.name) && // check if level is the same
			(fieldLevel === 0 || // top level field
				field.name.toLowerCase().startsWith(parentPrefix)) // or if parent is the same
		);
	});
};

/**
 * Gets all descendant fields of a field
 * with a given name
 *
 * @param {String} name
 *  Name of field to get descendants for
 *
 * @param {Array} fields
 * 	List of fields to search for descendants
 *
 * @returns {Array}
 *  Array of descendant fields
 */
const getDescendants = (name, fields = []) => {
	const parentPrefix = getNameWithSplitter(name).toLowerCase();

	return fields.filter((field) => {
		return field.name.toLowerCase().startsWith(parentPrefix);
	});
};

/**
 * Gets all *direct* child fields of a field
 * with a given name
 *
 * @param {String} name
 *  Name of field to get children for
 *
 * @param {Array} fields
 * 	List of fields to search for children
 *
 * @returns {Array}
 *  Array of child fields
 */
const getChildren = (name, fields = []) => {
	const descendants = getDescendants(name, fields);
	const childLevel = getFieldLevel(name) + 1;

	return descendants.filter((field) => {
		return getFieldLevel(field.name) === childLevel;
	});
};

/**
 * Returns an array of ancestor names, based on
 * given name
 * For example: name Foo.Bar.Boo.Child will give an
 * array with these values:
 *  - Foo
 *  - Foo.Bar
 *  - Foo.Bar.Boo
 *
 * @param {String} name
 *  Name of field to get ancestor names for
 *
 * @returns {Array}
 *  Array of ancestor names
 */
const getAllAncestorNames = (name) => {
	const result = [];

	// get direct parent name
	const fullParentName = getFullParentName(name);
	if (!fullParentName) return result;

	// split parent name
	let splittedName = getSplittedName(fullParentName);

	if (splittedName) {
		for (let i = 0; splittedName.length > 0; i++) {
			result.push(getJoinedName(splittedName));
			splittedName = splittedName.slice(0, -1);
		}
	}

	// reverse to order array from highest ancestor
	// to lowest/last
	return result.reverse();
};

/**
 * Returns a field with a given name from a list of
 * fields. Unles the matching field is excluded.
 * Used for checking if a name of a new/updated field
 * already exists.
 *
 * @param {String} name
 *  Name of field to search for
 *
 * @param {Array} fields
 *  List of fields
 *
 * @param {Integer} exclude
 *  Key of field in list to exclude from search
 *
 * @returns {Object}
 * 	Found field or false
 */
const getByName = (name, fields, exclude = false) => {
	// loop over existing fields
	const matches = fields.filter((field, id) => {
		if (id === exclude) return false;
		// get name
		const existingName = getName(field).toLowerCase();
		// check if names match
		return existingName === name.toLowerCase();
	});

	return matches[0] || false;
};

/**
 * Gets and returns (translated if non-static) default value
 * for a given field
 *
 * @param {Object} field
 *	Field to get default value for
 *
 * @returns {String}
 */
const getDefaultValue = (field) => {
	let default_value = false;

	if (field.default.type === staticValue) {
		if (typeof field.default.value === 'boolean') {
			default_value = t('general.' + field.default.value.toString());
		} else {
			default_value = field.default.value;
		}
	} else if (
		field.default.type &&
		typeof field.default.type === 'string'
	) {
		default_value = t(
			'defaultType.' + field.default.type.toLowerCase()
		);
	}

	return default_value;
};

/**
 * Gets and returns (translated) validation methods used
 * for a given field, separated by comma, so only useful for
 * display purposes
 *
 * @param {Object} field
 *	Field to get default value for
 *
 * @returns {String}
 */
const getValidation = (field) => {
	const validation = [];

	// check for validation with function list
	if (
		field.validation.element.ref.guid &&
		typeof field.validation.element.ref.guid === 'string'
	) {
		validation.push(t('validation.functionList'));
	}

	// check for max length validation
	if (
		typeof field.validation.max === 'number' &&
		field.validation.max > 0
	) {
		validation.push(t('validation.maxlength'));
	}

	// check for RegEx validation or foreign reference list or reference list
	if (
		typeof field.validation.regex === 'string' &&
		field.validation.regex.length > 0 &&
		(!field.validation.type ||
			field.validation.type === 'regularexpression')
	) {
		validation.push(t('validation.regex'));
	} else if (
		typeof field.validation.regex === 'string' &&
		field.validation.regex.length > 0 &&
		field.validation.type === 'referencelist'
	) {
		validation.push(t('validation.referenceList'));
	} else if (
		typeof field.validation.regex === 'string' &&
		field.validation.regex.length > 0 &&
		field.validation.type === 'foreignreference'
	) {
		validation.push(t('validation.foreignReference'));
	}

	return validation.join(', ');
};

/**
 * Changes the nested child setup of a field array to
 * a flat structure where child fields have dot-separated names
 * So changes this:
 * - Parent
 * -- Subparent
 * --- Child1
 * -- Child2
 *
 * To this:
 * - Parent
 * - Parent.Subparent
 * - Parent.Subparent.Child1
 * - Parent.Child2
 *
 * @param {Array} startFields
 *  Multi-dimensional array of fields
 *
 * @param {String} childName
 *	Name of the field property that contains child
 *  fields in multi-dimensional array
 *
 * @returns {Array}
 *  Flattened array of fields
 */
const flattenFields = (startFields = [], childName = 'elements') => {
	const result = startFields;

	const addRecur = (fields, parents = [], level = 0) => {

		// loop over fields
		fields.forEach((field) => {
			const newParents = Helpers.cloneObject(parents);
			newParents.push(field.name);

			if (level > 0) {
				// create dot-separated name
				field.name = getJoinedName(newParents);

				// add field to fields list
				result.push(field);
			}
			
			if (field[childName]) {
				addRecur(field[childName], newParents, level + 1);
				delete field[childName];
			}
		});		
	};

	addRecur(result);

	return result;
};

/**
 * Changes the flat child setup of a field array to
 * a nested child setup
 *
 * So changes this:
 * - Parent
 * - Parent.Subparent
 * - Parent.Subparent.Child1
 * - Parent.Child2
 *
 * To this:
 * - Parent
 * -- Subparent
 * --- Child1
 * -- Child2
 *
 * @param {Array} fields
 *  Flat array of fields
 *
 * @param {String} childName
 *	Name of the field property that contains child
 *  fields in multi-dimensional array
 *
 * @returns {Array}
 *  Multi-dimensional array of fields
 */
const nestChildFields = (fields = [], childName = 'elements') => {
	// loop over fields
	fields.forEach((el) => {
		// add direct children to every field
		el[childName] = getChildren(el.name, fields);
	});

	// filter out non-root fields
	return fields.filter((el) => {
		if (getFieldLevel(el.name) === 0) return true;
		else {
			// field names are dot-separated at this point, like (Parent.Subparent.ChildField),
			// and this should be changed to only the child field
			el.name = getChildName(el.name);
			return false;
		}
	});
};

/********************/
/* TABLE FORMATTING */
/********************/

/**
 * Get info about fields formatted for
 * use in StartBlockConfig component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @param {Array} extraFields
 *  Fields that are not configured in the block, but will be added
 *  to the Blocks output because they are added by a configured Function List
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForStartBlockTable = (fields, extraFields) => {
	const result = [];

	// loop over fields
	fields.forEach((field, key) => {
		result.push({
			key, // key, useful for handling clicks
			// name used for sorting
			sort_name: field.name,
			level: getFieldLevel(field.name),
			// name of the field
			field_name: getChildName(field.name),
			// type of the field
			field_type: field.type,
			// default value of the field, if any
			default_value: getDefaultValue(field),
			// output types of validation
			validation: getValidation(field),
			// indicator whether field is required
			required_field: Helpers.trueish(field.validation.required)
				? t('general.yes')
				: t('general.no'),
			edit: true,
			// do not show delete button for fields with children
			delete: !hasChildren(field.name, fields),
		});
	});

	extraFields.forEach((field, key) => {
		result.push({
			key: `extra-${key}`,
			// name used for sorting
			sort_name: field.name,
			level: getFieldLevel(field.name),
			// name of the field
			field_name: getChildName(field.name),
			// type of the field
			field_type: field.type,
			edit: false,
			delete: false,
			disabled: true,
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in AddBlockConfig component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForAddBlockTable = (fields) => {
	const result = [];
	const ancestorRows = [];

	// loop over fields
	fields.forEach((field, key) => {
		result.push({
			key, // key, useful for handling clicks
			// name used for sorting
			sort_name: field.name,
			level: getFieldLevel(field.name),
			// name of the field
			field_name: getChildName(field.name),
			// type of the field
			field_type: translateVarType(field.type),
			append_type: field.add_type,
			output: field.function_name || field.value,
			edit: true,
			delete: true,
			disabled: false,
		});

		// get all ancestors of field, and loop over them
		// this adds rows to the table for each ancestor
		getAllAncestorNames(field.name).forEach((name) => {
			// do nothing if ancestor row already exists
			// for instance when multiple fields have the
			// same ancestor
			if (ancestorRows.includes(name)) return false;

			// add ancestor to list of existing ancestors
			ancestorRows.push(name);

			result.push({
				key: name,
				// name used for sorting
				sort_name: name,
				level: getFieldLevel(name),
				// name of the field
				field_name: getChildName(name),
				field_type: ' ',
				append_type: ' ',
				output: ' ',
				// do not show edit or delete button for parents
				edit: false,
				delete: false,
				disabled: true,
			});
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in PredefinedFieldsDrawer component table
 *
 * @param {Array} documents
 *  Array of Document definitions
 *
 * @param {Array} fields
 *  Existing fields on block, used to disable predefined
 *  fields if field with same name already exists
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForPredefinedFieldsTable = (documents, fields = []) => {
	const result = [];

	// loop over fields
	documents.forEach((document) => {
		result.push({
			key: 'document', // key, useful for handling clicks
			document: document.guid,
			// name used for sorting
			sort_name: document.name,
			level: 0,
			// name of the field
			field_name: document.name,
			// type of the field
			field_type: ' ',
			disabled: false,
		});

		document.fields.forEach((field, key) => {
			result.push({
				key, // key, useful for handling clicks
				document: document.guid,
				// name used for sorting
				sort_name: getJoinedName([document.name, field.name]),
				level: getFieldLevel(field.name) + 1,
				// name of the field
				field_name: getChildName(field.name),
				// type of the field
				field_type: field.type,
				disabled: fieldOrAncestorExists(field.name, fields),
			});
		});
	});

	return result;
};

/**
 * Get info about fields formatted for
 * use in ResultFieldsDrawer component table
 *
 * @param {Array} fields
 *  Fields to format for output in table
 *
 * @param {Array} selectedFields
 *  Fields already configured in result block
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForResultFieldsTable = (fields, selectedFields = []) => {
	const result = [];

	// loop over fields
	fields.forEach((field, key) => {
		result.push({
			key, // key, useful for handling clicks
			// name used for sorting
			sort_name: field.name,
			level: getFieldLevel(field.name),
			// name of the field
			field_name: getChildName(field.name),
			// type of the field
			field_type: translateVarType(field.type),
			disabled:
				selectedFields.filter((item) => item.name === field.name)
					.length > 0,
		});
	});

	return result;
};

/********************/
/* INTERNAL HELPERS */
/********************/
/**
 * Splits a given name into it's parts by
 * splitting it by the used 'part splitter'
 *
 * @param {String} name
 *  Name to split
 *
 * @returns {Array}
 *  Array containing all name parts in order
 */
const getSplittedName = (name) => {
	return name ? name.split(NAME_SPLIT) : [];
};

/**
 * Checks if a field with a given name or an ancestor
 * of that field exists in a given list of fields
 *
 * @param {String} name
 *  Name of field to check
 *
 * @param {Array} fields
 *  List of fields to search through
 *
 * @returns {Boolean}
 *  Result of search
 */
const fieldOrAncestorExists = (name, fields) => {
	// check if field itself exists
	if (getByName(name, fields) !== false) return true;

	let result = false;

	// check if any ancestor of field exists
	getAllAncestorNames(name).some((row) => {
		if (getByName(row, fields) !== false) {
			result = true;
			return true;
		} else {
			return false;
		}
	});

	return result;
};

/**
 * returns typeOptionsArray for select element filterd by context
 *
 * @param {String} context
 *  context of use
 *
 * @returns {Array}
 *  Array with options
 */
const getVarTypeOptionsByContext = (context = '') => {
	// create array of var types to use in
	// select fields
	const varTypeOptions = [];

	// loop over all types
	for (const key in varTypes) {
		if (
			!context ||
			!varTypes[key].context ||
			varTypes[key].context.includes(context)
		) {
			varTypeOptions.push({
				value: varTypes[key].name,
				text: translateVarType(key), // use translated value
			});
		}
	}
	// order options alphabetically on text value
	varTypeOptions.sort((a, b) => a.text.localeCompare(b.text));
	return varTypeOptions;
};

/**
 * Gets the ancestor name for a given field name
 * at a given level
 *
 * Example:
 * 	For name Foo.Bar.Sub.Child and level 2,
 *  it returns Foo.Bar
 * 
 * @param {String} name
 *  Full name inc. ancestors
 *
 * @param {Integer} level
 *  Level of ancestor to get
 *
 * @returns {String}
 *  Found ancestor name
 */
const getParentNameForLevel = (name, level = 1) => {
	const splittedParent = getSplittedName(name).slice(0, level);
	return getJoinedName(splittedParent);
};

export default {
	createNew,
	createNewParent,
	makeChild,
	makeSibling,
	isParent,
	fieldsAreSiblings,
	trimName,
	trimChildName,
	validateName,
	addAncestorsOfMatches,
	getName,
	getFullParentName,
	getChildName,
	getNameByLevel,
	getNameAsPath,
	getJoinedName,
	getNameWithSplitter,
	orderParentChildList,
	orderParentChildArrayBySortOrder,
	hasChildren,
	varCanHaveChildren,
	getVarType,
	nameExists,
	translateVarType,
	getFieldLevel,
	getTranslatedVarTypeForField,
	filterOutParentFields,
	getSiblings,
	getDescendants,
	getChildren,
	getAllAncestorNames,
	getByName,
	getDefaultValue,
	getValidation,
	flattenFields,
	nestChildFields,
	varTypes,
	getVarTypeOptionsByContext,
	getVarTypeObject,
	createFieldOptions,
	setAutoMapping,
};
