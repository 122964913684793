<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="saveField"
	>
		<Suspense>
			<FieldDetailsEditor
				:editMode="fieldCanBeEdited"
				:fields="fields"
				v-model:valueModel="field"
				@update:valueModel="markModified"
				v-model:validModel="isValid"
				:fieldKey="fieldKey"
				:fieldMeta="methodFieldMeta"
				:connGuid="method.conn_guid"
				:fieldParents="false"
				context="document"
				:isMethodOutput="isOutput"
			/>
		</Suspense>

		<template v-if="fieldCanBeEdited" #footer>
			<VButton
				:disabled="!modified || !isValid"
				class="button-modal-footer"
				:text="$t('method.fieldDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="saveField"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import {
	methodFieldMeta,
} from '@modules/MethodBuilder/endpoints';
import Helpers from '@assets/scripts/helpers';
import usePermission from '@assets/scripts/composables/usePermission';


export default {
	name: 'MethodFieldDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			type: Object,
			required: false,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			modified: false,
			isValid: true,
			methodFieldMeta
		};
	},
	computed: {
		drawerName: function () {
			return this.field.name || '...';
		},
	},
	setup: function(props) {
		const store = useStore();
		const { t } = useI18n();

		// Gets currently active Method
		const method = store.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')];

		// determine whether current field is used for output
		const isOutput = props.config && props.config.isOutput;

		const canBeEdited = usePermission('Upsert', 'MethodBuilder') && isOutput;

		// Get fields of method that is currently viewed/edited
		const fields = isOutput ?
			(method.output || []) :
			(method.input || []);

		// determine key of field in list
		const fieldKey = props.config && props.config.key ? props.config.key - 1 : false;

		// get field, or create a new field
		const field = (fields && typeof fields[fieldKey] !== 'undefined'
			? Helpers.cloneObject(fields[fieldKey])
			: Field.createNew({
				is_new: true,
			}, methodFieldMeta, true));

		return {
			method,
			isOutput,
			fields,
			fieldKey,
			field: ref(field),
			fieldCanBeEdited: canBeEdited,
		};
	},
	methods: {
		markModified: function () {
			this.modified = true;
		},
		saveField: function () {
			this.$store.dispatch(getStoreAction('SAVE_FIELD', 'METHOD'), {
				key: this.fieldKey, // key of current field in field list or false for new field
				drawerId: this.id, // id of drawer to close after save
				field: this.field,
				isOutput: this.isOutput,
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'method.fieldDetails.close.confirm.title'
					),
					body: this.$t(
						'method.fieldDetails.close.confirm.body'
					),
					confirmButtonText: this.$t(
						'method.fieldDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
