export default {
	INIT: 'init',
	LOGIN: 'login',
	LOGIN_TWO_F_A: 'loginTwoFA',
	ASK_FOR_NEW_PASSWORD: 'askForNewPassword',
	RESET_PASSWORD: 'resetPassword',
	CHANGE_PASSWORD: 'changePassword',
	CHECK_SECRET_KEY: 'checkSecretKey',
	LOGOUT: 'logout',
	RESET: 'reset',
	CLOSE_ALL_DRAWERS: 'closeDrawers',
	CONFIRM: 'confirm',
	CANCEL_CONFIRMATION: 'cancelConfirm',
	CLEAR_VALIDATION: 'clearValidation',
	PREPARE_VALIDATION: 'prepareValidation',
	FINISH_VALIDATION: 'finishValidation',
	CHECK_LOGGED_IN: 'checkLoggedIn',
};
