import isCtrlClicked from './isCtrlClicked';
import isAltClicked from './isAltClicked';

/**
 * Returns true if Ctrl+alt+n or cmd+alt+s were pressed.
 */
export default (event) => {
    if (isCtrlClicked(event) && isAltClicked(event)) {
        return event.code === 'KeyN';
    }
    return false;
};