import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import setLoader from '@assets/scripts/store/loader';
import { log, debug } from '@assets/scripts/components/notifications';
import { validateFlow } from '@modules/FlowBuilder/components/validation/validateFlow';
import {
	createNewFlow,
	addBlockToFlow,
	setStartGuid,
	setResultGuid,
	duplicateFlow,
	constructFullFlow,
} from '@modules/FlowBuilder/components/flow';
import {
	createNewBlock,
	getBlockGuid,
	updateBlockConnection,
} from '@modules/FlowBuilder/components/block';
import Helpers from '@assets/scripts/helpers';
import { nextTick } from 'vue';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import {
	GET_RESTFLOW_VERSION,
	DELETE_RESTFLOW,
	POST_RESTFLOW,
	SET_RESTFLOW_STATUS,
} from '@modules/FlowBuilder/endpoints';

import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export const names = {
	SET_FLOW: 'setFlow',
	NEW_FLOW: 'newFlow',
	UNSET_FLOW: 'unsetFlow',
	DELETE_FLOW: 'deleteFlow',
	SET_FLOW_STATUS: 'setFlowStatus',
	DUPLICATE_FLOW: 'duplicateFlow',
	SAVE_CURRENT_FLOW: 'saveCurrentFlow',
	PUBLISH_CURRENT_FLOW: 'publishCurrentFlow',
	LOAD_AND_SHOW_FLOW: 'loadAndShowFlow',
	VALIDATE_CURRENT_FLOW: 'validateCurrentFlow',
};

export default {
	/**
	 * Action triggered when a new Flow is loaded
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Object} flow
	 *  Retrieved Flow Object
	 *
	 * @returns {void}
	 */
	[names.SET_FLOW]({ commit, dispatch }, flow) {
		// commit mutation in root store
		commit(getStoreMutation('SET_CURRENT_FLOW', 'FLOW'), flow, {
			root: true,
		});
		// dispatch action in blocks module
		dispatch(getStoreAction('INIT', 'BLOCKS'), flow, { root: true });
	},
	/**
	 * Action triggered when a new Flow must be created
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} flow
	 *  Retrieved Flow Object
	 *
	 * @param {Object} settings
	 *  Settings for the new Flow
	 *
	 * @returns {void}
	 */
	[names.NEW_FLOW]({ commit, dispatch }, settings) {
		// show loader
		setLoader('new-flow');

		// create new flow object with given settings
		const newFlow = createNewFlow(settings);

		// helper function to add blocks to new flow
		const addBlock = (block) => {
			try {
				addBlockToFlow(newFlow, block);
			} catch (err) {
				log(err.message, 'danger', err);
			}
		};

		// create Start block and add it to Flow
		const startBlock = createNewBlock('START');
		const startGuid = getBlockGuid(startBlock);
		addBlock(startBlock);

		// set Flow.StartID
		setStartGuid(newFlow, startGuid);

		// create Result block and add it to Flow
		const resultBlock = createNewBlock('RESULT', [startGuid]);
		const resultGuid = getBlockGuid(resultBlock);
		addBlock(resultBlock);

		// update OUT port of Start block
		updateBlockConnection(startBlock, resultGuid, 'OUT', false);

		// set Flow.ResultID
		setResultGuid(newFlow, resultGuid);

		// create Close block and add it to Flow
		const closeBlock = createNewBlock('CLOSE', [resultGuid]);
		const closeGuid = getBlockGuid(closeBlock);
		addBlock(closeBlock);

		// update OUT port of Result block
		updateBlockConnection(resultBlock, closeGuid, 'OUT', false);

		// check if newly created flow is a Script flow
		if (newFlow.is_script) {
			// set result block option 'Full output' to true by
			// default for script flows
			Helpers.obj.setProp('config|full_output', resultBlock, true);

			// create new Config block, to be placed between
			// start and result block
			const configBlock = createNewBlock(
				'CONFIG',
				[startGuid],
				[resultGuid]
			);
			const configGuid = getBlockGuid(configBlock);
			addBlock(configBlock);

			// update OUT port of Start block
			updateBlockConnection(startBlock, configGuid, 'OUT', resultGuid);

			// update IN port of Result block
			updateBlockConnection(resultBlock, configGuid, 'IN', startGuid);
		}

		// remove loader
		setLoader('new-flow', false);

		// dispatch action to set new flow as current flow
		dispatch(getStoreAction('SET_FLOW', 'FLOW'), newFlow, { root: true });

		// mark new flow as modified so it can be saved immediately
		commit(
			getStoreMutation('FLOW_MODIFIED', 'FLOW'),
			{},
			{
				root: true,
			}
		);

		// show success message
		log(t('fb.newFlow.createdSuccess'), 'success');
	},
	[names.UNSET_FLOW]({ commit }) {
		// reset FLOW store
		commit(getStoreMutation('RESET', 'FLOW'), {}, { root: true });

		// reset BLOCKS store
		commit(getStoreMutation('RESET', 'BLOCKS'), {}, { root: true });
	},
	async [names.DELETE_FLOW]({ commit }, { guid, versionGuid }) {
		// do API call
		const result = await useApiAsync(DELETE_RESTFLOW, {
			keys: {
				guid,
				version: versionGuid
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful delete', {
				guid,
				versionGuid,
				result,
			});
	
			// show success message
			log(t('fb.flowDelete.success'), 'success');
	
			// dispatch mutation, so other components can act on it
			commit(getStoreMutation('FLOW_DELETED', 'FLOW'), guid, {
				root: true,
			});
		} else {
			// show debug message in console
			debug('error in delete', {
				guid,
				versionGuid,
				error,
			});
	
			// show error message
			log(t('fb.flowDelete.error'), 'danger');
		}
	},
	async [names.SET_FLOW_STATUS]({ commit }, { guid, status }) {
		// do API call
		const result = await useApiAsync(SET_RESTFLOW_STATUS, {
			keys: { guid },
			parameters: {
				active: status
			}
		});

		if (result !== false) {
			// show debug message in console
			debug('successful status update', {
				guid,
				status,
			});
	
			// show success message
			log(t('fb.flowStatusUpdate.success'), 'success');
	
			// dispatch mutation, so other components can act on it
			commit(getStoreMutation('FLOW_STATUS_UPDATED', 'FLOW'), guid, {
				root: true,
			});
		} else {
			// show debug message in console
			debug('error in status update', {
				guid,
				status,
				error,
			});
	
			// show error message
			log(t('fb.flowStatusUpdate.error'), 'danger');
		}
	},
	async [names.DUPLICATE_FLOW]({ commit, dispatch }, { guid, versionGuid }) {
		//show loader
		const loaderGuid = 'duplicateFlow';
		setLoader(loaderGuid);

		// dispatch action in API module
		const flow = await useApiAsync(GET_RESTFLOW_VERSION, {
			keys: {
				guid,
				version: versionGuid
			}
		});

		if (!flow) {
			// show error
			log(t('error.loadFlow'), 'danger');
			// remove loader
			setLoader(loaderGuid, false);

			// stop function execution
			return;
		}

		// get cloned flow
		const newFlow = duplicateFlow(flow);

		// set flow
		dispatch(getStoreAction('SET_FLOW', 'FLOW'), newFlow, { root: true });

		// close all overlays, drawers etc.
		dispatch(getStoreAction('CLOSE_ALL_DRAWERS'), {}, { root: true });

		// mark new flow as modified so it can be saved immediately
		commit(
			getStoreMutation('FLOW_MODIFIED', 'FLOW'),
			{},
			{ root: true }
		);

		// show success message
		log(t('fb.flowDuplicate.success'), 'success');

		// remove loader
		setLoader(loaderGuid, false);
	},
	/**
	 * Action triggered to save the current Flow
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	async [names.SAVE_CURRENT_FLOW]({ dispatch }) {
		const showSavingLoader = (status = true) => {
			setLoader('save-current-flow', status);
		};

		// show loader
		showSavingLoader();

		// get fully constructed flow object
		const flow = constructFullFlow();

		// post flow through api
		const result = await useApiAsync(POST_RESTFLOW, {
			parameters: {
				...flow
			}
		});

		if (result !== false) {
			debug('succesfully saved', result);
	
			// get current flow from store
			const currentFlow =
				this.getters[getStoreGetter('CURRENT_FLOW', 'FLOW')];
	
			// show success message
			log(t('fb.flow.savedSuccessfully'), 'success');
	
			// dispatch action to show updated version
			// of flow on the canvas
			await dispatch(
				getStoreAction('LOAD_AND_SHOW_FLOW', 'FLOW'),
				{
					guid: currentFlow.guid,
					versionGuid: currentFlow.version,
				},
				{ root: true }
			);
		}

		// stop loader
		showSavingLoader(false);
	},
	async [names.PUBLISH_CURRENT_FLOW]({ commit, dispatch }) {
		const showPublishLoader = (status = true) => {
			setLoader('publish-current-flow', status);
		};

		// mark flow as published
		commit(getStoreMutation('SET_PUBLISHED_STATE', 'FLOW'), true, {
			root: true,
		});

		// show loader
		showPublishLoader();

		// get fully constructed flow object
		const flow = constructFullFlow();

		// post flow through api
		const result = await useApiAsync(POST_RESTFLOW, {
			parameters: {
				...flow
			}
		});

		if (result !== false) {
			debug('successfully published', result);
	
			// get current flow from store
			const currentFlow =
				this.getters[getStoreGetter('CURRENT_FLOW', 'FLOW')];
	
			// show success message
			log(t('fb.flow.publish.success'), 'success');
	
			// dispatch action to show updated version
			// of flow on the canvas
			await dispatch(
				getStoreAction('LOAD_AND_SHOW_FLOW', 'FLOW'),
				{
					guid: currentFlow.guid,
					versionGuid: currentFlow.version,
				},
				{ root: true }
			);
		} else {
			// mark flow as not published if anything
			// went wrong
			commit(getStoreMutation('SET_PUBLISHED_STATE', 'FLOW'), false, {
				root: true,
			});
		}

		// stop loader
		showPublishLoader(false);
	},
	/**
	 * Action triggered to validate the current Flow
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {Object} store
	 *  Ref to store
	 *
	 * @returns {void}
	 */
	[names.VALIDATE_CURRENT_FLOW]({ dispatch, commit }, currentflow) {
		// prepare for validation
		dispatch(getStoreAction('PREPARE_VALIDATION'), null, { root: true });

		// setTimeout is needed becouse we use async functions and we want the dom to be updated to show the loader first
		// setTimeout without time given we use 1 ms just to be sure that it works everywhere
		// we should fix this with better solution in the future because the async function for a reason are blocking the dom update
		setTimeout(async () => {
			const errors = await validateFlow(currentflow);

			// loop errors and add to the errors array
			errors.forEach((error) => {
				commit(
					getStoreMutation('ADD_VALIDATION_ERROR'),
					error,
					{ root: true }
				);
			});
			// get GUID of start block
			const startGuid =
				this.getters[getStoreGetter('START_GUID', 'BLOCKS')];

			//Recursivly validate blocks
			await dispatch(
				getStoreAction('VALIDATE_BLOCK_RECURSIVE', 'BLOCKS'),
				startGuid,
				{ root: true }
			);
			
			// finish validation
			dispatch(getStoreAction('FINISH_VALIDATION'), null, { root: true });
		}, 1);
	},
	/**
	 * Action triggered to load an existing flow and
	 * display it on the canvas
	 *
	 * @param {Function} commit
	 *  Ref to store.commit
	 *
	 * @param {Function} dispatch
	 *  Ref to store.dispatch
	 *
	 * @param {String} guid
	 *  GUID of REST Flow to load
	 *
	 * @param {String} versionGuid
	 *  Version GUID of REST Flow to load
	 *
	 * @returns {void}
	 */
	async [names.LOAD_AND_SHOW_FLOW](
		{ commit, dispatch },
		{ guid, versionGuid }
	) {
		// load flow from api
		const flow = await useApiAsync(GET_RESTFLOW_VERSION, {
			keys: {
				guid,
				version: versionGuid
			}
		});

		if (!flow) {
			log(t('error.loadFlow'), 'danger');
			return;
		}

		// reset blocks store
		commit(getStoreMutation('RESET', 'BLOCKS'), {}, { root: true });

		// await next tick before setting new flow
		await nextTick();

		// set flow
		dispatch(
			getStoreAction('SET_FLOW', 'FLOW'),
			Helpers.cloneObject(flow),
			{ root: true }
		);

		// close all overlays, drawers etc.
		dispatch(getStoreAction('CLOSE_ALL_DRAWERS'), {}, { root: true });
	},
};
