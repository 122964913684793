import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

import { store } from '@assets/scripts/components/store-proxy';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate add block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateAddBlock = async (block) => {
	const output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};

	const inputNames = block.config.add.map((input) => {
		return input.name.toLowerCase();
	});

	const checkedInputs = [];

	// get all block inputs
	const blockInputs = store.getters[getStoreGetter('BLOCK_INPUT', 'BLOCKS')](
		block.guid
	);

	const blockInputsNames = blockInputs.map((input) => {
		return input.name.toLowerCase();
	});

	// for loop used becouse of the await inside
	for (const input of block.config.add) {
		// check if a field has type
		if (!Validation.fieldHasType(input)) {
			setBlockError(
				t('fb.flowErrors.fieldTypeMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if system default of a field has value except Function default this will have a different check with mapping and start block
		if (!Validation.fieldHasSystemDefaultInAddBlock(input)) {
			setBlockError(
				t('fb.flowErrors.defaultValueMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if static default of a field has value except Function default this will have a different check with mapping and start block
		if (!Validation.fieldHasStaticDefaultInAddBlock(input)) {
			setBlockError(
				t('fb.flowErrors.defaultValueMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if default function of a field is chosen
		if (!Validation.fieldHasFunctionDefault(input)) {
			setBlockError(
				t('fb.flowErrors.defaultFunctionMissing', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if input name exist
		if (!Validation.stringNotEmpty(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameIsEmpty', {
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if input name has atleast 2 characters
		if (!Validation.stringHasAtleastTwoCharacters(input.name)) {
			setBlockError(
				t('fb.flowErrors.fieldNameIsShort', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if input name without whitspacing
		if (!Validation.stringWithoutSpacing(input.name)) {
			setBlockError(
				t('fb.flowErrors.nameContainsSpace', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if name is duplicated or used more than one time
		if (
			!checkedInputs.includes(input.name.toLowerCase()) &&
			!Validation.stringIsUniqueInList(input.name.toLowerCase(), inputNames)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedName', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		// check if name is duplicated or used already in flow vars
		if (
			Validation.stringExistsInList(
				input.name.toLowerCase(),
				blockInputsNames
			)
		) {
			setBlockError(
				t('fb.flowErrors.duplicatedNameInFlowVars', {
					fieldName: Field.getNameAsPath(input.name),
					block: t('fb.blockTypes.add'),
				})
			);
		}

		if (
			input.add_type === 'Function' &&
			(input.function_guid != null || input.function_name != '')
		) {
			// get function
			const fieldFunction = await store.dispatch(
				getStoreAction('FUNCTION_BY_ID', 'FB'),
				input.function_guid
			);

			// check if function list exists
			if (!Validation.isNonEmptyObject(fieldFunction)) {
				setBlockError(
					t('fb.flowErrors.functionNotExist', {
						fieldName: Field.getNameAsPath(input.name),
						block: t('fb.blockTypes.add'),
						fieldFunction: input.function_name,
					})
				);
			} else {
				// check if requirds field are mapped in default function of a field
				if (
					!Validation.requiredFieldsMappedInFunction(
						fieldFunction,
						input.mapping || []
					)
				) {
					setBlockError(
						t('fb.flowErrors.defaultFunctionMappingMissing', {
							fieldName: Field.getNameAsPath(input.name),
							fieldFunction: input.function_name,
							block: t('fb.blockTypes.add'),
						})
					);
				}

				input.mapping.forEach((mappingObject) => {
					const { fromField, toField } = Helpers.getFromToFields(
						blockInputs,
						mappingObject,
						fieldFunction
					);

					// check if mapped field exist in the flow vars
					if (!Validation.mappedFieldExists(mappingObject, blockInputs)) {
						setBlockError(
							t('fb.flowErrors.FunctionMappingInputNotExist', {
								fieldName: Field.getNameAsPath(input.name),
								mappingObject: Field.getNameAsPath(
									mappingObject.from
								),
								fieldFunction: input.function_name,
								block: t('fb.blockTypes.add'),
							})
						);
					}

					// check if block input type match the function input type
					if (!Validation.mappedFieldTypesMatch(fromField, toField)) {
						setBlockError(
							t('fb.flowErrors.typeNotMatchInFunction', {
								fieldName: Field.getNameAsPath(input.name),
								mappingObject: Field.getNameAsPath(
									mappingObject.to
								),
								block: t('fb.blockTypes.add'),
								fieldFunction: input.function_name,
							})
						);
					}

					// check if input max length match the function max length and regex
					if (fromField) {
						if (
							!Validation.fieldValidationsMaxLengthMatch(
								fromField.validation.max,
								toField.validation.max,
								true
							)
						) {
							setBlockError(
								t(
									'fb.flowErrors.maxLengthNotMatchInObjectDefinition',
									{
										fromField: Field.getNameAsPath(fromField.name),
										fromFieldMaxLength: fromField.validation.max || t('general.none'),
										toField: Field.getNameAsPath(toField.name),
										toFieldMaxLength: toField.validation.max,
										block: t('fb.blockTypes.add'),
									}
								)
							);
						}
					}
				});
			}
		}
		// add input to already checked inputs array
		checkedInputs.push(input.name.toLowerCase());
	}
	return output;
};
