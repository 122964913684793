import { getStoreGetter } from '@assets/scripts/store/config';
import { IS_DEV } from '@assets/scripts/helpers';

export const names = {
	SHOW_CREATE_NEW: 'showCreateNew',
	DATA_LOSS_POSSIBLE_HOOK: 'dataLossPossible',
};

export default {
	[names.SHOW_CREATE_NEW]: ({ showCreateNew }) => {
		return showCreateNew;
	},
	[names.DATA_LOSS_POSSIBLE_HOOK]: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		return rootGetters[getStoreGetter('SHOW_CREATE_NEW', 'FB')];
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
