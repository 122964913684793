<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="saveField"
	>
		<Suspense>
			<FieldDetailsEditor
				:editMode="fieldCanBeEdited"
				:fields="fields"
				v-model:valueModel="field"
				@update:valueModel="markModified"
				v-model:validModel="isValid"
				:fieldKey="fieldKey"
				:fieldMeta="fieldMeta"
				:fieldParents="parents"
				:defaultConfigOptions="defaultConfigOptions"
				context="meta"
			/>
		</Suspense>

		<template v-if="fieldCanBeEdited" #footer>
			<VButton
				:disabled="!modified || !isValid"
				class="button-modal-footer"
				:text="$t('mb.fieldDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="saveField"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { metadocFieldMeta } from '@modules/MetaDocumentBuilder/endpoints';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'MetadataDocumentFieldDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			type: Object,
			required: false,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			modified: false,
			isValid: true,
		};
	},
	computed: {
		drawerName: function () {
			return Field.getNameAsPath(this.field.name) || '...';
		},
	},
	setup: function (props) {
		const store = useStore();
		const { t } = useI18n();

		// Gets currently active Document
		const document = store.getters[getStoreGetter('CURRENT_META_DOCUMENT', 'MB')];

		// determine used meta for doc fields
		const fieldMeta = metadocFieldMeta;

		const fields = document.elements;

		// determine key of field in list
		const fieldKey = props.config && props.config.key ? props.config.key - 1 : false;

		const parents =
			// filter for parent fields
			fields.filter((field) => {
				return Field.isParent(field);
			});

		// get field, or create a new field
		const field = (fields && typeof fields[fieldKey] !== 'undefined'
				? Helpers.cloneObject(fields[fieldKey])
				: Field.createNew({
					is_new: true,
					order: props.config?.order ?? -1,
				}, fieldMeta, true));

		let defaultConfigOptions = [];

		// add additional default value field options
		['insert', 'update'].forEach((value) => {
			defaultConfigOptions.push({
				value,
				label: t('fieldDetailsEditor.fieldDefaultOptions.' + value),
				info: t('fieldDetailsEditor.fieldDefaultOptions.' + value + '_info'),
			});
		});

		return {
			document,
			fieldMeta,
			defaultConfigOptions,
			fields,
			fieldKey,
			parents,
			field: ref(field),
			fieldCanBeEdited:
				store.getters[getStoreGetter('CAN_BE_EDITED', 'MB')],
		};
	},
	methods: {
		markModified: function () {
			this.modified = true;
		},
		saveField: function () {
			this.$store.dispatch(getStoreAction('SAVE_FIELD', 'MB'), {
				key: this.fieldKey, // key of current field in field list or false for new field
				drawerId: this.id, // id of drawer to close after save
				field: this.field,
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('mb.fieldDetails.close.confirm.title'),
					body: this.$t('mb.fieldDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'mb.fieldDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
