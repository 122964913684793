import { createWebHistory, createRouter } from 'vue-router';
import i18n from '@assets/i18n';
import { nextTick, watch } from 'vue';
import modules from '@assets/scripts/composables/useModules';
import { update as updateRoutes } from '@assets/scripts/composables/useRoutes';
import moduleRoutes from '@modules/routes';

// page components
const TheHomepage = () => import('@materials/pages/TheHomepage.vue');
const LoginForm = () => import('@materials/structures/LoginForm.vue');

// These can not be lazy loaded for some reason. Visitor gets redirected to Login page
// if he enters these pages directly when they are being lazy loaded
import AskForNewPassword from '@materials/structures/AskForNewPassword.vue';
import ResetPassword from '@materials/structures/ResetPassword.vue';
import TheProfilepage from '@materials/pages/TheProfilepage.vue';

// translate function of vue-i18n
const { t } = i18n.global;

const routes = [
	{
		path: '/login',
		component: LoginForm,
		name: 'login',
		meta: {
			title: t('pages.login.pageTitle'),
			showInSideNav: false,
			showInHome: false,
		},
	},
	{
		path: '/reset-password',
		component: ResetPassword,
		name: 'resetPassword',
		meta: {
			title: t('pages.resetPassword.pageTitle'),
			showInSideNav: false,
			showInHome: false,
		},
	},
	{
		path: '/request-password',
		component: AskForNewPassword,
		name: 'requestPassword',
		meta: {
			title: t('pages.requestNewPassword.pageTitle'),
			showInSideNav: false,
			showInHome: false,
		},
	},
	{
		path: '/',
		component: TheHomepage,
		name: 'home',
		meta: {
			title: t('sideNavigation.home'),
			showInSideNav: true,
			showInHome: false,
			icon: 'diamond',
			order: 0,
		},
	},
	{
		path: '/profile',
		component: TheProfilepage,
		name: 'profile',
		meta: {
			title: t('pages.profile.pageTitle'),
			showInSideNav: false,
			showInHome: false,
		},
	},
	// {
	// 	path: '/dashboard',
	// 	name: 'dashboard',
	// 	meta: {
	// 		title: t('sideNavigation.dashboard'),
	// 		showInSideNav: true,
	// 		icon: 'dashboard',
	// 		module: 'Dashboard',
	// 	},
	// },
	// {
	// 	path: '/customers',
	// 	name: 'customers',
	// 	meta: {
	// 		title: t('sideNavigation.customers'),
	// 		showInSideNav: true,
	// 		icon: 'customers',
	// 		module: 'Customers',
	// 	},
	// },
	// {
	// 	path: '/segment-builder',
	// 	name: 'segmentBuilder',
	// 	meta: {
	// 		title: t('sideNavigation.segment'),
	// 		showInSideNav: true,
	// 		icon: 'segment',
	// 		module: 'SegmentBuilder',
	// 	},
	// 	isExternal: true,
	// 	href: 'http://www.google.com',
	// },
	// {
	// 	path: '/datacleansing',
	// 	name: 'dataCleansing',
	// 	meta: {
	// 		title: t('sideNavigation.data'),
	// 		showInSideNav: true,
	// 		icon: 'data',
	// 		module: 'DataCleansing',
	// 	},
	// },
	// {
	// 	path: '/reporting',
	// 	name: 'reporting',
	// 	meta: {
	// 		title: t('sideNavigation.report'),
	// 		showInSideNav: true,
	// 		icon: 'report',
	// 		module: 'Reporting',
	// 	},
	// },
	// {
	// 	path: '/queue',
	// 	name: 'queue',
	// 	meta: {
	// 		title: t('sideNavigation.queue'),
	// 		showInSideNav: true,
	// 		icon: 'queue',
	// 		module: 'Queue',
	// 	},
	// },
];

export const menuGroups = [
	{
        name: 'builder',
        title: t('sideNavigation.builderGroup'),
        icon: 'customers',
        order: 2,
    },
	{
        name: 'management',
        title: t('sideNavigation.managementGroup'),
        icon: 'customers',
        order: 3,
    },
	{
        name: 'admin',
        title: t('sideNavigation.adminGroup'),
        icon: 'customers',
        order: 4,
    },
];

// loop over modules
for (const module in moduleRoutes) {
	// add module routes to existing routes
	routes.push(...moduleRoutes[module]);
}

// create router, without routes for now
const router = createRouter({
	history: createWebHistory(),
	routes: [],
});

// update page title after each route load
router.afterEach((to) => {
	nextTick(() => {
		document.title = to.meta.title
			? t('sideNavigation.pageTitle', { page: to.meta.title })
			: t('sideNavigation.pageTitleDefault');
	});
});

// set routes, based on available modules
const setRoutes = (modules = []) => {
	// remove all existing routes
	router.getRoutes().forEach((route) => {
		router.removeRoute(route.name);
	});

	// loop over all routes
	routes.forEach((route) => {
		// check if route is available
		if (
			!route.meta?.module ||
			modules.includes(route.meta.module)
		) {
			// add route to router if it is available
			router.addRoute(route);
		}
	});

	// replace current route with newly available route
	router.push({
		replace: true,
		...router.currentRoute.value
	});

	// set new routes in useRoutes composable
	updateRoutes(router.getRoutes());
};

// watch for change in available modules
watch(modules, () => {
	// update available routes
	setRoutes(modules.value);
});

// initially set routes
setRoutes(modules.value);

export default router;
