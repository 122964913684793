<template>
	<div class="is-flex">
		<VField
			:label="$t('fb.conditions.chooseField')"
			:isFloating="true"
			:class="{ 'is-active': filter.name }"
			class="has-margin-right-1"
		>
			<VSelect
				:options="fieldOptions"
				v-model="filter.name"
				@change="fieldUpdated"
			/>
		</VField>

		<VField
			:label="$t('fb.conditions.chooseOperator')"
			:isFloating="true"
			:class="{ 'is-active': filter.operator }"
			v-if="field"
		>
			<VSelect
				:options="operatorOptions"
				v-model="filter.operator"
				@change="filterUpdated"
			/>
		</VField>
	</div>

	<VField
		v-if="showRuleTypeChoice"
		:label="$t('fb.conditions.chooseType')"
		:isGrouped="true"
	>
		<VOption
			v-for="(item, key) in ruleTypeOptions"
			:isButton="true"
			:key="key"
			:value="item.value"
			:label="item.label"
			:disabled="item.disabled"
			v-model="filter.rule_type"
			@update:modelValue="ruleTypeUpdated"
			type="radio"
		/>
	</VField>

	<template v-if="showValueComponent">
		<div
			v-for="(item, key) in filter.value"
			class="is-flex is-align-items-center has-margin-bottom-1"
			:key="`${rerenderCounter}-${key}`"
		>
			<Component
				:is="valueComponent"
				:label="valueLabel(key)"
				v-model:valueModel="filter.value[key]"
				@update:valueModel="() => valueUpdated()"
				:isRequired="false"
				v-bind="valueComponentArgs"
			/>

			<VButton
				v-if="showExtraValueComponent && key < filter.value.length - 1"
				:title="$t('general.delete')"
				:isTool="true"
				icon="delete"
				tabindex="-1"
				@click.prevent.stop="deleteValue(key)"
				class="has-margin-left-1"
			/>
		</div>
	</template>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { operators } from '@modules/FlowBuilder/components/filtering';
import { filterMeta } from '@modules/FlowBuilder/endpoints';
import { mapGetters } from 'vuex';
import { getStoreGetter } from '@assets/scripts/store/config';

import DateTimeDynamic from '@modules/FlowBuilder/materials/components/DateTimeDynamic.vue';

export default {
	name: 'ConditionEditor',
	props: {
		/**
		 * The current config of the filter
		 */
		modelValue: {
			type: Object,
			required: true,
			default: () => Helpers.obj.create(filterMeta, {}),
		},
		/**
		 * The fields that can be filtered on
		 */
		fields: {
			type: Array,
			required: true,
			default: () => [],
		},
		/**
		 * The fields that can be used in comparisons
		 */
		dynamic: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data() {
		return {
			filter: Helpers.cloneObject(this.modelValue),
			rerenderCounter: 0,
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
		}),
		fieldOptions: function () {
			if (!this.fields || Field.filterOutParentFields(this.fields).length < 1) return [];

			const options = [];

			Field.filterOutParentFields(this.fields).forEach((row) => {
				options.push({
					key: row.name,
					name: Field.getNameAsPath(row.name),
				});
			});

			// map available fields into list for VSelect
			return Helpers.mapObjectArrayForSelect(options, {
				key: 'key',
				text: 'name',
			});
		},
		compareFieldOptions() {
			if (this.filter.type) {
				const result = [];
				const extraOptions = [];
				const filterType = Field.getVarType(this.filter.type);
				const isDateOrTime = ['DATE', 'DATETIME'].includes(filterType);

				if (isDateOrTime) {
					// Date and DateTime fields can also be compared to 'NOW' a.o., which
					// is interpreted as the time of execution of the flow
					['NOW', 'WEEK-START', 'YEAR-START', 'MONTH-START'].forEach((val) => {
						extraOptions.push({
							key: val,
							name: this.$t('datepicker.dynamicOptions.' + val.toLowerCase()),
						});
					})
				}

				this.dynamic.forEach((field) => {
					if (field.type && field.type === this.filter.type) {
						// dynamic Date(Time) options need '[]' around the field name, to
						// distinguish them from internal names
						// i.e. 'NOW - 7 DAYS' vs '[CreatedDateTime] - 7 DAYS'
						result.push({
							key: isDateOrTime ? `[${field.name}]` : field.name,
							name: Field.getNameAsPath(field.name),
						});
					}
				});

				// map fields into list for VSelect, with extra options at the top of the list
				return Helpers.mapObjectArrayForSelect(extraOptions, {
					key: 'key',
					text: 'name',
				}).concat(Helpers.mapObjectArrayForSelect(result, {
					key: 'key',
					text: 'name',
				}));
			}

			return false;
		},
		dynamicOptionAvailable() {
			return this.compareFieldOptions && this.compareFieldOptions.length > 0;
		},
		ruleTypeOptions() {
			return [
				{
					label: this.$t('fb.conditions.static'),
					value: 'Static',
					disabled: false,
				},
				{
					label: this.$t('fb.conditions.dynamic'),
					value: 'Dynamic',
					disabled: !this.dynamicOptionAvailable,
				},
			];
		},
		// returns currently selected field
		field() {
			return (
				this.fields.find((field) => field.name === this.filter.name) ||
				false
			);
		},
		// returns type of currently selected field
		fieldType() {
			if (!this.field) return false;
			return Field.getVarType(this.field.type);
		},
		operator() {
			return (
				operators.find(
					(operator) => operator.value === this.filter.operator
				) || false
			);
		},
		operatorsAvailable() {
			return operators.filter((operator) =>
				operator.types.includes(this.fieldType)
			);
		},
		operatorOptions() {
			// map available actions into list for VSelect
			return Helpers.mapObjectArrayForSelect(this.operatorsAvailable, {
				key: 'value',
				text: 'name',
			});
		},
		showRuleTypeChoice() {
			return this.operator && this.operator.inputCount !== 0;
		},
		showValueComponent() {
			return this.valueComponent && this.filter.rule_type;
		},
		valueComponent() {
			if (
				!this.filter.type ||
				!this.showRuleTypeChoice
			)
				return false;

			let result = false;

			if (this.filter.rule_type === 'Dynamic') {
				if (['DATE', 'DATETIME'].includes(this.fieldType)) {
					// dynamic date(time) fields
					result = DateTimeDynamic;
				} else {
					// other dynamic values use VInputSelect
					result = 'VInputSelect';
				}
			} else if (['maxlength', 'minlength'].includes(this.filter.operator)) {
				// operators max/min length should always use integer > 0
				result = 'VInputInteger';
			} else {
				switch (this.fieldType) {
					case 'NUMBER':
					case 'LONGNUMBER':
						result = 'VInputInteger';
						break;
					case 'STRING':
					case 'JSON':
					case 'GUID':
						result = 'VInputString';
						break;
					case 'DECIMAL':
						result = 'VInputDecimal';
						break;
					case 'DATE':
					case 'DATETIME':
						result = 'VInputDate';
						break;
				}
			}

			return result;
		},
		valueComponentArgs() {
			if (
				!this.filter.type ||
				!this.showRuleTypeChoice
			)
				return false;

			// dynamic values use VInputSelect with compareFieldOptions
			// as options
			if (this.filter.rule_type === 'Dynamic') {
				return { options: this.compareFieldOptions };
			} else if (['maxlength', 'minlength'].includes(this.filter.operator)) {
				// operators max/min length should always use integer > 0
				return { min: 1 };
			} else if (this.fieldType === 'DATETIME') {
				return { setTimeInOutput: true };
			} else {
				return false;
			}
		},
		showExtraValueComponent() {
			return this.operator && this.operator.inputCount === -1;
		},
	},
	mounted() {
		this.valueUpdated(true);

		// check if input count for current operator is higher
		// than current configured values
		if (
			this.operator &&
			this.operator.inputCount > this.filter.value.length
		) {
			// add as many empty inputs as needed
			for (
				let i = 0;
				this.operator.inputCount > this.filter.value.length;
				i++
			) {
				this.addNewValueInput();
			}
		}
	},
	methods: {
		filterUpdated() {
			this.$emit('update:modelValue', this.filter);
		},
		fieldUpdated() {
			// set correct field type
			this.filter.type = this.field ? this.field.type : false;

			// unset operator if current operator is not available
			// for newly selected field
			if (
				this.filter.operator &&
				(this.operatorsAvailable.length < 1 ||
					!this.operatorsAvailable.includes(this.operator))
			) {
				this.filter.operator = false;
			}

			this.unsetValueInputs();
			this.filterUpdated();
		},
		ruleTypeUpdated() {
			this.unsetValueInputs();
			this.filterUpdated();
		},
		valueUpdated(initial = false) {
			if (
				this.showExtraValueComponent &&
				this.filter.value[this.filter.value.length - 1] !== ''
			) {
				this.addNewValueInput();
			}

			if (!initial) this.filterUpdated();
		},
		valueLabel(key) {
			if (!this.operator) return false;

			const pluralization =
				this.operator.inputCount === -1 ? 2 : this.operator.inputCount;

			return this.$t('fb.conditions.valueLabel', pluralization, {
				index: key + 1,
			});
		},
		unsetValueInputs() {
			this.rerenderCounter++;
			this.filter.value = [];

			if (this.operator) {
				switch (this.operator.inputCount) {
					case 0:
						this.filter.rule_type = 'Static';
						break;
					case -1:
						this.addNewValueInput();
						break;
					default:
						for (let i = 1; i <= this.operator.inputCount; i++) {
							this.addNewValueInput();
						}
						break;
				}
			}
		},
		addNewValueInput() {
			this.filter.value.push('');
		},
		deleteValue(key) {
			this.filter.value.splice(key, 1);
			this.rerenderCounter++;
			this.valueUpdated();
		},
	},
	watch: {
		operator() {
			this.unsetValueInputs();
		},
		dynamicOptionAvailable(newVal) {
			if (!newVal) this.filter.rule_type = 'Static';
		},
	},
};
</script>
