import Validation from '@assets/scripts/components/validationChecks';

import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';
import { operators } from '@modules/FlowBuilder/components/filtering';

import { store } from '@assets/scripts/components/store-proxy';
import { getStoreGetter } from '@assets/scripts/store/config';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * Validate check block and returns erros array
 * for a given block
 *
 * @param {Object} block
 *  Block to get errors
 *
 * @returns {Array}
 *	array of errors
 */
export const validateCheckBlock = async (block) => {
	let output = [];

	const setBlockError = (description) => {
		output.push(Helpers.createErrorObject(description, block.guid));
	};
	if (!Validation.isNonEmptyArray(block.config.rules)) {
		setBlockError(
			t('fb.flowErrors.noConditionGroup', {
				block: t('fb.blockTypes.check'),
			})
		);
	} else {
		block.config.rules.map((conditionsGroup) => {
			if (!Validation.isNonEmptyArray(conditionsGroup.rules)) {
				setBlockError(
					t('fb.flowErrors.noCondition', {
						block: t('fb.blockTypes.check'),
					})
				);
			} else {
				conditionsGroup.rules.map((condition) => {
					// get all block inputs
					const blockInputs = store.getters[
						getStoreGetter('BLOCK_INPUT', 'BLOCKS')
					](block.guid);

					// check if used field in condition exist in the flow vars
					if (
						!Validation.fieldInConditionExists(condition, blockInputs)
					) {
						setBlockError(
							t('fb.flowErrors.fieldInConditionNotExist', {
								fieldInCondition: Field.getNameAsPath(
									condition.name
								),
								block: t('fb.blockTypes.check'),
							})
						);
					}

					// check if a filter condition has an operator
					if (!Validation.conditionHasOperator(condition)) {
						setBlockError(
							t('fb.flowErrors.noOperatorChosen', {
								fieldName: Field.getNameAsPath(condition.name),
								block: t('fb.blockTypes.check'),
							})
						);
					} else {
						// check if a filter condition has a right operator
						if (
							!Validation.conditionHasCorrectOperator(
								condition,
								operators
							)
						) {
							setBlockError(
								t('fb.flowErrors.wrongOperatorChosen', {
									fieldName: Field.getNameAsPath(
										condition.name
									),
									block: t('fb.blockTypes.check'),
								})
							);
						}

						// check if operator has correct amount of values
						if (
							!Validation.conditionHasCorrectAmountOfValues(
								condition,
								operators
							)
						) {
							setBlockError(
								t('fb.flowErrors.wrongValueAmountForOperator', {
									fieldName: Field.getNameAsPath(
										condition.name
									),
									operator: condition.operator,
									block: t('fb.blockTypes.check'),
								})
							);
						}

						if (condition.operator === 'between') {
							// check if operator between value 1 is gretter than value 2
							if (
								!Validation.conditionValue2IsGreaterThanValue1(
									condition
								)
							) {
								setBlockError(
									t(
										'fb.flowErrors.wrongValueOrderForOperatorBetween',
										{
											fieldName: Field.getNameAsPath(
												condition.name
											),
											operator: condition.operator,
											block: t('fb.blockTypes.check'),
										}
									)
								);
							}
						}
					}
				});
			}
		});
	}
	return output;
};
