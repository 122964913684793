<template>
	<div class="flow-summary">
		<VActiveIndicator v-if="flowActive" class="has-margin-bottom-05" />
		<VTitle v-if="flowName" :size="2" :text="flowName" />

		<div class="content">
			<p v-if="flowPublishDate">
				{{
					$t('fb.flowSummary.lastTimePublished', {
						date: flowPublishDate,
					})
				}}
			</p>

			<p>
				{{ $t('fb.flowSummary.restApi') }}<br />
				<strong>
					{{ flow.api_name || '-' }}
				</strong>
			</p>

			<p>
				{{ $t('fb.flowSummary.method') }}<br />
				<strong>
					{{ flow.method || '-' }}
				</strong>
			</p>

			<div class="status-info">
				<p>
					{{ $t('fb.flowSummary.status') }}<br />
					<strong>
						{{ flowActiveLabel }}
					</strong>
				</p>

				<VButton v-if="canSetStatus"
					:text="flowStatusToggleButtonLabel"
					@clicked="setFlowStatus"
				/>
			</div>

		</div>
	</div>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getFlowActive } from '@modules/FlowBuilder/components/flow';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

export default {
	name: 'FlowSummary',
	props: {
		/**
		 * Object of the flow
		 */
		flow: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	computed: {
		flowName: function () {
			return this.flow.name;
		},
		flowActive: function () {
			return getFlowActive(this.flow);
		},
		flowPublishDate: function () {
			const date = this.flow.published;
			return date ? Helpers.date.localeStringWithMinutes(date) : false;
		},
		flowActiveLabel: function () {
			return this.flow.is_active ? this.$t('general.active') : this.$t('general.inactive');
		},
		flowStatusToggleButtonLabel: function () {
			return this.flow.is_active ? this.$t('general.deactivate') : this.$t('general.activate');
		},
		canSetStatus: function () {
			return usePermission('Publish', 'FlowBuilder');
		}
	},
	methods: {
		setFlowStatus () {
			// ask confirmation to change flow status
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.flow.is_active ? this.$t('fb.flowStatusUpdate.confirmDeactivate.title') : this.$t('fb.flowStatusUpdate.confirmActivate.title'),
				body: this.flow.is_active ? this.$t('fb.flowStatusUpdate.confirmDeactivate.body') : this.$t('fb.flowStatusUpdate.confirmActivate.body'),
				confirmButtonText: this.flow.is_active ? this.$t('fb.flowStatusUpdate.confirmDeactivate.confirmButtonText') : this.$t('fb.flowStatusUpdate.confirmActivate.confirmButtonText'),
				confirmFn: () => {
					// do toggle status after confirmation
					this.$store.dispatch(
						getStoreAction('SET_FLOW_STATUS', 'FLOW'),
						{
							guid: this.flow.guid,
							status: !this.flow.is_active
						}
					);
				},
			});
		}
	}
};
</script>
