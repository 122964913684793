import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

export const operators = [
	{
		name: t('fb.conditions.operators.equals'),
		value: '=',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.notEquals'),
		value: '!=',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
		],
	},
	{
		name: t('fb.conditions.operators.greaterThan'),
		value: '>',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.greaterEquals'),
		value: '>=',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.smallerThan'),
		value: '<',
		inputCount: 1,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.smallerEquals'),
		value: '<=',
		inputCount: 1,
		types: [
			'DATE',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.between'),
		value: 'between',
		inputCount: 2,
		types: [
			'DATE',
			'DATETIME',
			'DECIMAL',
			'LONGNUMBER',
			'NUMBER',
		],
	},
	{
		name: t('fb.conditions.operators.contains'),
		value: 'contains',
		inputCount: 1,
		types: ['STRING', 'JSON'],
	},
	{
		name: t('fb.conditions.operators.notContains'),
		value: 'notcontains',
		inputCount: 1,
		types: ['STRING', 'JSON'],
	},
	{
		name: t('fb.conditions.operators.in'),
		value: 'in',
		inputCount: -1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.notIn'),
		value: 'notin',
		inputCount: -1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.isSet'),
		value: 'set',
		inputCount: 0,
		types: [
			'BOOLEAN',
			'DATE',
			'DATETIME',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
			'JSON',
		],
	},
	{
		name: t('fb.conditions.operators.notSet'),
		value: 'notset',
		inputCount: 0,
		types: [
			'BOOLEAN',
			'DATE',
			'DATETIME',
			'DECIMAL',
			'GUID',
			'LONGNUMBER',
			'NUMBER',
			'STRING',
			'JSON',
		],
	},
	{
		name: t('fb.conditions.operators.isTrue'),
		value: 'istrue',
		inputCount: 0,
		types: ['BOOLEAN'],
	},
	{
		name: t('fb.conditions.operators.isFalse'),
		value: 'isfalse',
		inputCount: 0,
		types: ['BOOLEAN'],
	},
	{
		name: t('fb.conditions.operators.startsWith'),
		value: 'startswith',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.endsWith'),
		value: 'endswith',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.maxLength'),
		value: 'maxlength',
		inputCount: 1,
		types: ['STRING'],
	},
	{
		name: t('fb.conditions.operators.minLength'),
		value: 'minlength',
		inputCount: 1,
		types: ['STRING'],
	},
];

export const getOperatorName = (operator) => {
	let result = false;

	operators.some((item) => {
		if (item.value === operator) {
			result = item.name;
			return true;
		}
	});

	return result;
};
