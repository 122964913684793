import DocumentBuilder from './DocumentBuilder/materials/drawers';
import FlowBuilder from './FlowBuilder/materials/drawers';
import MetaDocumentBuilder from './MetaDocumentBuilder/materials/drawers';
import MethodBuilder from './MethodBuilder/materials/drawers';
import RestApiBuilder from './RestApiBuilder/materials/drawers';
import UserManagement from './UserManagement/materials/drawers';
import ComposedDocument from './ComposedDocument/materials/drawers';

export default {
    DocumentBuilder,
    FlowBuilder,
    MetaDocumentBuilder,
    MethodBuilder,
    RestApiBuilder,
    UserManagement,
    ComposedDocument,
};