<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="drawerName || null"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="saveField"
	>
		<Suspense>
			<FieldDetailsEditor
				:editMode="editMode"
				:fields="fields"
				v-model:valueModel="field"
				@update:valueModel="markModified"
				v-model:validModel="isValid"
				:fieldKey="fieldKey"
				:fieldMeta="fieldMeta"
				:fieldParents="parents"
				context="flow"
			/>
		</Suspense>

		<template v-if="editMode" #footer>
			<VButton
				:disabled="!modified || !isValid"
				class="button-modal-footer"
				:text="$t('fb.startFieldDetails.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="saveField"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import {
	getStoreAction,
	getStoreGetter,
	getStoreMutation,
} from '@assets/scripts/store/config';
import Field from '@assets/scripts/components/field';
import { startBlockInputFieldMeta } from '@modules/FlowBuilder/endpoints';
import Helpers from '@assets/scripts/helpers';

export default {
	name: 'StartFieldDetailsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		config: {
			required: true,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			fieldMeta: startBlockInputFieldMeta,
			modified: false,
			isValid: true,
			drawerName: '',
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
			/**
			 * Get all parent fields for current block
			 */
			parents: getStoreGetter(
				'PARENT_FIELDS_FROM_CURRENT_BLOCK',
				'BLOCKS'
			),
		}),
	},
	setup: function(props) {
		const store = useStore();

		/**
		 * Get start block fields of block that is currently viewed/edited
		 */
		const fields = store.getters[getStoreGetter('START_BLOCK_FIELDS', 'BLOCKS')];

		const fieldKey = props.config || 0;

		const field = (fields && typeof fields[fieldKey] !== 'undefined'
				? Helpers.cloneObject(fields[fieldKey])
				: Field.createNew({}, startBlockInputFieldMeta, true));

		return { fields, fieldKey, field };
	},
	mounted: function () {
		this.setDrawerName();
	},
	methods: {
		markModified: function () {
			this.modified = true;
			this.setDrawerName();
		},
		setDrawerName: function () {
			this.drawerName = Field.getNameAsPath(this.field.name);
		},
		saveField: function () {
			this.$store.dispatch(getStoreAction('UPDATE_FIELD', 'BLOCKS'), {
				key: this.fieldKey, // key of current field in field list
				drawerId: this.id, // id of drawer to close after save
				field: this.field,
				type: 'start',
			});
		},
		closeDrawer: function () {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
