import Helpers from '@assets/scripts/helpers';
import {
	simpleConnectionMeta,
	validationMeta,
} from '@assets/scripts/api/config';

// take simpleConnectionMeta as starting point
export const connectionMeta = Helpers.cloneObject(simpleConnectionMeta);

// extend meta with needed fields
Object.assign(connectionMeta, {
	type: {
		name: 'Type',
		type: 'string',
		default: false,
	},
	is_internal: {
		name: 'IsInternal',
		type: 'boolean',
		default: false,
	},
	description: {
		name: 'Description',
		type: 'string',
		default: false,
		omitIfEmpty: true,
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	flow_credential: {
		name: 'FlowCredential',
		type: 'object',
		default: {},
		children: {
			settings: {
				name: 'Settings',
				type: 'object',
				default: {},
			},
			type: {
				name: 'Type',
				type: 'string',
				default: false,
			},
			credential_guid: {
				name: 'FlowCredentialGuid',
				type: 'string',
				default: false,
			},
		},
	},
});

const credentialTypeMeta = {
	name: {
		name: 'Name',
		type: 'string',
		default: '',
	},
	fields: {
		name: 'Elements',
		type: 'array',
		default: [],
		children: {
			name: {
				name: 'Name',
				type: 'string',
				default: '',
			},
			type: {
				name: 'Type',
				type: 'string',
				default: '',
			},
			description: {
				name: 'Description',
				type: 'string',
				default: '',
			},
			validation: validationMeta,
		},
	},
};

// File exports all available Endpoints
const apiBase = 'UIServices/Connection';

// Connections
export const GET_CONNECTIONS = {
	path: 'GetConnections',
	realm: apiBase,
	meta: connectionMeta,
};

// Credential types
export const GET_CREDENTIAL_TYPES = {
	path: 'CredentialTypes',
	realm: apiBase,
	meta: credentialTypeMeta,
};

// Connection
export const GET_CONNECTION = {
	path: 'GetConnectionByGuid/{guid}',
	realm: apiBase,
	meta: connectionMeta,
};

// Save or publish Connection
export const POST_CONNECTION = {
	path: 'PostConnection',
	method: 'post',
	realm: apiBase,
	meta: connectionMeta
};

// Delete Connection
export const DELETE_CONNECTION = {
	path: 'DeleteConnection/{guid}',
	method: 'delete',
	realm: apiBase,
};