import {
	setBlockPosition,
	setBlockChildCol,
	setBlockOutput,
	resetBlockPositionFields,
	updateBlockConnection,
} from '@modules/FlowBuilder/components/block';
import { getStoreGetter } from '@assets/scripts/store/config';
import getDefaultState from './state';
import Helpers from '@assets/scripts/helpers';

export const names = {
	SET_IDS: 'setIDs',
	SET_NEW_BLOCK_OPTIONS: 'setNewBlockOptions',
	UPDATE_BLOCKS: 'updateBlocks',
	UPDATE_BLOCK_POSITION: 'updateBlockPosition',
	UPDATE_BLOCK_CHILD_COL: 'updateBlockChildCol',
	UPDATE_BLOCK_CONNECTIONS: 'updateBlockConnections',
	UPDATE_BLOCK_OUTPUT: 'updateBlockOutput',
	RESET_RESULT_BLOCK_OUTPUT: 'resetResultBlockOutput',
	UPDATE_FLOW_BLOCK_START: 'updateFlowBlockStart',
	SELECT_BLOCK: 'selectBlock',
	BLOCK_CONFIG_MODIFIED: 'blockConfigModified',
	CLOSE_BLOCK_DETAILS: 'closeBlockDetails',
	SAVE_CURRENT_BLOCK: 'saveCurrentBlock',
	ADD_FIELD: 'addField',
	UPDATE_FIELD: 'updateField',
	RESET: 'reset',
};

export default {
	/**
	 * Saves pieces of newly retrieved flow
	 * to the state
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} flow
	 *  Retrieved flow from Nominow REST API
	 *
	 * @returns {void}
	 */
	[names.SET_IDS](state, flow) {
		state.resultId = Helpers.obj.getProp('flow|result_block', flow);
		state.startId = Helpers.obj.getProp('flow|start_block', flow);
	},
	/**
	 * Stores information about the position in the
	 * flow where the next block will be added
	 *
	 * @param {Object} state
	 *  Ref to current store state
	 *
	 * @param {Object} options
	 *  Object with the following options:
	 *
	 * 	 @param {String} srcGuid
	 * 	  GUID of parent of new block
	 *
	 * 	 @param {String} trgtGuid
	 * 	  GUID of child of new block
	 *
	 * 	 @param {Boolean} isFalseExit
	 * 	  Indicates whether new block should be
	 * 	  connected to FALSE exit of parent block
	 *
	 * 	 @param {Boolean} isAfterResult
	 * 	  Indicates whether new block will be
	 * 	  added to flow after Result block, and not before
	 *
	 * @returns {void}
	 */
	[names.SET_NEW_BLOCK_OPTIONS](state, options) {
		state.newBlockOptions = options;
	},
	/**
	 * Adds and removes blocks to/from current block list
	 *
	 * @param {Array} blocks
	 *  Ref to state.blocks
	 *
	 * @param {Array} blocksToAdd
	 *  Array of blocks to add to flow (normalized)
	 *
	 * @param {Array} blocksToDelete
	 *  Array of blocks to delete from flow
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCKS]({ blocks }, { blocksToAdd, blocksToDelete }) {
		if (
			(!blocksToAdd || blocksToAdd.length < 1) &&
			(!blocksToDelete || blocksToDelete.length < 1)
		)
			return;

		if (blocksToAdd && blocksToAdd.length > 0) {
			// add new blocks to list
			blocksToAdd.forEach((block) => {
				blocks.push(block);
			});
		}

		if (blocksToDelete && blocksToDelete.length > 0) {
			// remove blocks from list
			blocksToDelete.forEach((block) => {
				const index = blocks.indexOf(block);
				if (index !== -1) blocks.splice(index, 1);
			});
		}

		// reset position fields for all blocks
		blocks.forEach((block) => {
			resetBlockPositionFields(block);
		});
	},
	/**
	 * Updates one of the coordinates for a block with
	 * a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {String} guid
	 *  GUID of block to update
	 *
	 * @param {String} pos
	 *  Coordinate to update, either 'x' or 'y'
	 *
	 * @param {Int} value
	 *  Value to update the coordinate to
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCK_POSITION](state, { guid, pos = 'x', value = 0 }) {
		const block =
			this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);

		setBlockPosition(block, pos, value);
	},
	/**
	 * Updates one of the child column counters
	 * for a block with a given GUID
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {String} guid
	 *  GUID of block to update
	 *
	 * @param {String} side
	 *  Side to update, either 'TRUE' or 'FALSE'
	 *
	 * @param {Int} count
	 *  Value to update the counter to
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCK_CHILD_COL](state, { guid, side = 'TRUE', count = 0 }) {
		const block =
			this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);

		setBlockChildCol(block, side, count);
	},
	/**
	 * Updates one of the connection ports of a block by
	 * either adding a new block GUID or replacing an existing
	 * block GUID on that port
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {String} guid
	 *  GUID of block to update connection for
	 *
	 * @param {String/Boolean} newGuid
	 *  New GUID to add to connection port of block, or
	 *  false if an existing GUID should just be deleted
	 *
	 * @param {String} connection
	 *  Name of connection port to add new GUID to
	 *  So one of: In, Out or OutFalse
	 *
	 * @param {Boolean/String} replace
	 *  If false it indicates new GUID should be added to
	 *  connection port.
	 *  Else it represents the GUID that should be replaced
	 *  by new GUID.
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCK_CONNECTIONS](
		state,
		{ guid, newGuid, connection, replace }
	) {
		// get block to update
		const block =
			this.getters[getStoreGetter('BLOCK_BY_GUID', 'BLOCKS')](guid);

		if (!block) return;

		updateBlockConnection(block, newGuid, connection, replace);
	},
	/**
	 * Updates/sets the Flow JSON output for a given block
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @param {Object} block
	 *  Block to update
	 *
	 * @param {Array} output
	 *  Output of block
	 *
	 * @returns {void}
	 */
	[names.UPDATE_BLOCK_OUTPUT](state, { block, output = [] }) {
		setBlockOutput(block, output);
	},
	/**
	 * Unsets the configured output for current block, if
	 * that block is a Result block
	 *
	 * @param {Object} currentBlock
	 *  Ref to current block
	 *
	 * @returns {void}
	 */
	[names.RESET_RESULT_BLOCK_OUTPUT]({ currentBlock}) {
		Helpers.obj.setProp('config|output|fields', currentBlock, []);
	},
	[names.UPDATE_FLOW_BLOCK_START](state, newX) {
		state.xFlowBlockStart = newX;
	},
	[names.SELECT_BLOCK](state, block) {
		// set reference to current block
		state.blockRef = block;

		// clone given block to be able to manipulate
		// it without affecting current flow
		state.currentBlock = Helpers.cloneObject(block);

		// mark block as not modified
		state.modified = false;
	},
	[names.BLOCK_CONFIG_MODIFIED](state) {
		// mark block as modified
		state.modified = true;
	},
	[names.CLOSE_BLOCK_DETAILS](state) {
		// unset reference
		state.blockRef = false;

		// unset current block
		state.currentBlock = {};

		// mark block as not modified
		state.modified = false;
	},
	[names.SAVE_CURRENT_BLOCK](state) {
		Object.assign(state.blockRef, state.currentBlock);
	},
	/**
	 * Adds a given Start, Add or Result field to the currently
	 * viewed/edited block
	 *
	 * @param {Object} state
	 *  Ref to module state
	 *
	 * @param {Object} field
	 *  Start field to add to current block
	 *
	 * @param {String} type
	 *  Type of field, either 'start', 'add' or 'result'
	 */
	[names.ADD_FIELD](state, { field, type = 'start' }) {
		let getter;

		if (type === 'add') getter = 'ADD_BLOCK_FIELDS';
		else if (type === 'result') getter = 'RESULT_BLOCK_FIELDS';
		else getter = 'START_BLOCK_FIELDS';

		// get current fields
		const fields = this.getters[getStoreGetter(getter, 'BLOCKS')];

		// push new field to list
		fields.push(field);
	},
	/**
	 * Updates a Start or Add field with a given key of
	 * the currently viewed/edited block
	 *
	 * @param {Object} state
	 *  Ref to module state
	 *
	 * @param {Object} param
	 *  Parameters, not destructured since the object
	 *  is used to signal success/failure back to
	 *  caller function
	 *
	 *   @param {Object} field
	 *    Field object to save
	 *   @param {Integer} key
	 *    Key of field to update in field list
	 *	 @param {String} type
	 *    Type of field, either 'start' or 'add'
	 *   @param {Boolean} result
	 *    Indicates success/failure of update
	 *
	 *  Start field to add to current block
	 */
	[names.UPDATE_FIELD](state, param) {
		const { field, key, type } = param;
		let getter;

		if (type === 'add') getter = 'ADD_BLOCK_FIELDS';
		else getter = 'START_BLOCK_FIELDS';

		// get current fields
		const fields = this.getters[getStoreGetter(getter, 'BLOCKS')];

		if (typeof fields[key] !== 'undefined') {
			// replace field with new field
			fields[key] = field;
			// mark update as success
			param.result = true;
		} else {
			// mark update as failed
			param.result = false;
		}
	},
	/**
	 * Resets module state to default
	 *
	 * @param {Object} state
	 *  Ref to current module state
	 *
	 * @returns {void}
	 */
	[names.RESET](state) {
		Object.assign(state, getDefaultState());
	},
};
