/**
 * Purpose of this file is to register all components that
 * need to be available globally
 */

// focus-trap from focus-trap-vue library https://github.com/posva/focus-trap-vue
import { FocusTrap } from 'focus-trap-vue'
// adding property name to component because it does not exist and we needit for registeration
FocusTrap.name = 'FocusTrap'

// Basics
import VActiveIndicator from '@materials/basics/VActiveIndicator.vue';
import VButton from '@materials/basics/VButton.vue';
import VFlag from '@materials/basics/VFlag.vue';
import VIcon from '@materials/basics/VIcon.vue';
import VLink from '@materials/basics/VLink.vue';
import VLoader from '@materials/basics/VLoader.vue';
import VMenuItemContent from '@materials/basics/VMenuItemContent.vue';
import VNotification from '@materials/basics/VNotification.vue';
import VTag from '@materials/basics/VTag.vue';
import VTitle from '@materials/basics/VTitle.vue';
import VTooltip from '@materials/basics/VTooltip.vue';

// Forms
import VField from '@materials/basics/forms/VField.vue';
import VInput from '@materials/basics/forms/VInput.vue';
import VInputDate from '@materials/basics/forms/VInputDate.vue';
import VInputDecimal from '@materials/basics/forms/VInputDecimal.vue';
import VInputInteger from '@materials/basics/forms/VInputInteger.vue';
import VInputSelect from '@materials/basics/forms/VInputSelect.vue';
import VInputString from '@materials/basics/forms/VInputString.vue';
import VOption from '@materials/basics/forms/VOption.vue';
import VOptionBoolean from '@materials/basics/forms/VOptionBoolean.vue';
import VSearch from '@materials/basics/forms/VSearch.vue';
import VSelect from '@materials/basics/forms/VSelect.vue';
import VSelectWithSearch from '@materials/basics/forms/VSelectWithSearch.vue';
import VTextarea from '@materials/basics/forms/VTextarea.vue';

// Components
import DrawerItem from '@materials/components/drawer/DrawerItem.vue';
import VTabItem from '@materials/components/tabs/TabItem.vue';
import VTabs from '@materials/components/tabs/Tabs.vue';
import ConfigOptions from '@materials/components/ConfigOptions.vue';
import ContentBlock from '@materials/components/ContentBlock.vue';
import VOverlay from '@materials/components/Overlay.vue';
import ParentSelector from '@materials/components/ParentSelector.vue';
import FieldConfigMain from '@materials/components/FieldConfigMain.vue';
import SortableDataTable from '@materials/components/SortableDataTable';
import TwoFAInput from '@materials/components/TwoFAInput';

// Structures
import FieldDetailsEditor from '@materials/structures/FieldDetailsEditor.vue';
import Validation from '@materials/structures/Validation.vue';
import ErrorsList from '@materials/structures/ErrorsList.vue';

// Default value
import BooleanDefaultValue from '@materials/components/defaults/BooleanDefaultValue.vue';
import NumberDefaultValue from '@materials/components/defaults/NumberDefaultValue.vue';
import LongnumberDefaultValue from '@materials/components/defaults/LongnumberDefaultValue.vue';
import StringDefaultValue from '@materials/components/defaults/StringDefaultValue.vue';

// Table - Buefy
import BTable from '@materials/basics/buefy/Table';
import BTableColumn from '@materials/basics/buefy/TableColumn';
import BInput from '@materials/basics/buefy/Input';

/**
 * N.B.: Add all imported components to this array
 * Only components in this array will be registered globally
 */
const globalComponents = [
	// focus-trap 
	FocusTrap,

	// Basics
	VActiveIndicator,
	VButton,
	VFlag,
	VIcon,
	VLink,
	VLoader,
	VMenuItemContent,
	VNotification,
	VTag,
	VTitle,
	VTooltip,

	// Forms
	VField,
	VInput,
	VInputDate,
	VInputDecimal,
	VInputInteger,
	VInputSelect,
	VInputString,
	VOption,
	VOptionBoolean,
	VSearch,
	VSelect,
	VSelectWithSearch,
	VTextarea,

	// Components
	DrawerItem,
	VTabItem,
	VTabs,
	ConfigOptions,
	ContentBlock,
	VOverlay,
	ParentSelector,
	FieldConfigMain,
	SortableDataTable,
	TwoFAInput,

	// Structures
	FieldDetailsEditor,
	Validation,
	ErrorsList,

	// Default value
	BooleanDefaultValue,
	NumberDefaultValue,
	LongnumberDefaultValue,
	StringDefaultValue,

	// Table - Buefy
	BTable,
	BTableColumn,
	BInput,
];

/**
 * Function to register components globally. Used both by
 * Vue project and by Storybook
 *
 * @param {Vue Instance} app
 *	Resulting Object of Vue.createApp()
 *
 * @returns {void}
 */
const registerComponentsGlobally = (app) => {
	if (globalComponents.length < 1) return;
	
	globalComponents.forEach((component) => {
		app.component(component.name, component);
	});
};

export default registerComponentsGlobally;
