<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.checkConditionDrawer.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="false"
		:closeOnBackgroundClick="true"
		:onClose="closeDrawer"
		@keydown.meta.s.prevent.stop="saveCondition"
	>
		<ConditionEditor
			v-model="condition"
			:fields="config.fields"
			@update:modelValue="conditionChanged"
		/>

		<template v-if="editMode" #footer>
			<VButton
				:disabled="!modified"
				class="button-modal-footer"
				:text="$t('fb.checkConditionDrawer.saveButton')"
				icon="chevron-right"
				:iconOnRight="true"
				@clicked.prevent.stop="saveCondition"
			/>
		</template>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { filterMeta } from '@modules/FlowBuilder/endpoints';
import { mapGetters } from 'vuex';
import { getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

import ConditionEditor from '@modules/FlowBuilder/materials/structures/ConditionEditor.vue';

export default {
	name: 'CheckConditionDrawer',
	components: {
		ConditionEditor,
	},
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Config for the drawer
		 */
		config: {
			default: {
				fields: [],
				condition: {},
				callback: () => {},
			},
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
	},
	data: function () {
		return {
			modified: false,
			condition: Helpers.obj.create(filterMeta, this.config.condition || {}),
		};
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate if flow is in Edit mode
			 */
			editMode: getStoreGetter('EDIT_MODE', 'FLOW'),
		}),
	},
	methods: {
		markModified: function () {
			this.modified = true;
		},
		conditionChanged() {
			this.markModified();
		},
		saveCondition() {
			if (typeof this.config.callback === 'function') {
				// filter out empty/null values
				this.condition.value = this.condition.value.filter(
					(val) => !['', null].includes(val)
				);

				// use callback fn with condition
				// as param
				this.config.callback(this.condition);

				this.closeDrawer(true);
			}
		},
		closeDrawer: function (force = false) {
			const closeDrawer = () => {
				this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
			};

			if (!this.modified || force) {
				// close immediately if config has not been modified
				closeDrawer();
			} else {
				// ask confirmation before closing if a change has been made
				// to the field config
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t(
						'fb.blockDetails.configDetails.confirmClose.title'
					),
					body: this.$t(
						'fb.blockDetails.configDetails.confirmClose.body'
					),
					confirmButtonText: this.$t(
						'fb.blockDetails.configDetails.confirmClose.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeDrawer();
					},
				});
			}
		},
	},
};
</script>
