/**
 * Translations for English
 * See https://vue-i18n.intlify.dev/guide/ on how to use
 */
 export default {
	pageTitle: 'Flow Builder',
	buttons: {
		addblock: 'Add block',
	},
	blocks: {
		title: {
			add: 'Extra fields',
			check: 'Check',
			close: 'Close',
			config: 'Config',
			error: 'Error',
			external: 'Save and send',
			read: 'Read',
			result: 'Result',
			start: 'Start',
			write: 'Write',
		},
		description: {
			fieldsSpecified:
				'0 fields specified | 1 field specified | {count} fields specified',
			fieldsSelected:
				'0 fields selected | 1 field selected | {count} fields selected',
			fieldsAdded:
				'0 fields added | 1 field added | {count} fields added',
			conditionalsSet:
				'No conditionals set | 1 conditional set | {count} conditionals set',
			method: 'Method: {method}',
			errorCode: 'Error code: {code}',
			external: 'Connection: {selection}',
			fullOutput: 'Full output used',
		},
		delete: 'Delete block',
		deleteConfirm: {
			title: 'Delete this block?',
			body: 'Are you sure you want to delete this block?',
			confirmButtonText: 'Delete',
		},
	},
	chooseNewBlockDrawer: {
		drawerName: 'Add a building block',
		text: 'Select which action you want to take on your defined fields in the start',
		blockItems: {
			add: {
				title: 'Extra field(s)',
				text: 'Add fields to the data',
			},
			check: {
				title: 'Check',
				text: 'Check if certain conditions match',
			},
			config: {
				title: 'Config',
				text: 'Used to paste configuration in JSON format',
			},
			write: {
				title: 'Write',
				text: 'Write data to Nominow',
			},
			external: {
				title: 'Save and Send',
				text: 'Send data to other systems',
			},
			read: {
				title: 'Read',
				text: 'Use existing data from Nominow',
			},
		},
	},
	blockDetails: {
		title: {
			start: 'Start',
			add: 'Extra fields',
			check: 'Check',
			result: 'Result',
			close: 'Close',
			external: 'Save and send',
			read: 'Read',
			write: 'Write',
			error: 'Error',
			config: 'Config',
			errorList: 'Errors {count}',
		},
		description: {
			start: 'Fields specified: {count}',
			add: 'Fields specified: {count}',
			result: 'Fields specified: {count}',
		},
		saveButton: 'Save and proceed',
		close: {
			confirm: {
				title: 'Go back to flow overview?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this overlay.',
				confirmButtonText: 'Ok',
			},
		},
		addBlock: {
			table: {
				columns: {
					appendType: 'Append type',
				},
				noResults: 'There are no fields yet',
			},
			delete: {
				confirm: {
					title: 'Delete this field?',
					body: 'Are you sure you want to delete this field?',
					confirmButtonText: 'Delete',
				},
			},
		},
		startBlock: {
			choosePredefined: 'Choose predefined field(s)',
			addedThroughFunctionList: 'Automatically added by a Function List',
			table: {
				noResults: 'There are no fields yet',
			},
			delete: {
				confirm: {
					title: 'Delete this field?',
					body: 'Are you sure you want to delete this field?',
					confirmButtonText: 'Delete',
				},
			},
		},
		readBlock: {
			firstMatchOnly: 'First match only',
			firstMatchOnlyExplanation:
				'Retrieve only one record or all records',
			chooseAction: 'Choose action',
			chooseConnection: 'Choose connection',
			filtering: 'Filtering',
			addFilterGroup: 'Add filter group',
			addCondition: 'Add condition',
			mapping: 'Matching',
			sortOrder: 'Sort order',
			chooseField: 'Add field(s)',
			sortingTable: {
				columns: {
					field: 'Field',
					direction: 'Direction',
				},
				noResults: 'No sorting fields configured',
			},
			filterGroupDelete: {
				confirm: {
					title: 'Delete this group?',
					body: 'Are you sure you want to delete this filter group? This action can not be undone.',
					confirmButtonText: 'Delete',
				},
			},
			filterDelete: {
				confirm: {
					title: 'Delete this filter?',
					body: 'Are you sure you want to delete this filter? This action can not be undone.',
					confirmButtonText: 'Delete',
				},
			},
			filteringTable: {
				columns: {
					field: 'Field',
					fieldType: 'Field type',
					operator: 'Operator',
					type: 'Type',
					value: 'Value(s)',
				},
				noResults: 'No filters configured',
			},
			noFilters: 'No filters configured',
		},
		resultBlock: {
			chooseField: 'Choose field(s)',
			isArray: 'Is array',
			isArrayExplanation:
				'Always result as array (also single records). Multiple records will always be sent as array by software',
			isIgnore: 'Ignore nulls',
			isIgnoreExplanation:
				'Empty values will be removed from the result when checkbox is off. If IgnoreNulls is true, fields with empty values will be null.',
			fullOutput: 'Full output',
			fullOutputExplanation: 'Use all available variables as output',
			duplicateError: 'Field with this name already exist',
			fullOutputOn: 'All available fields will be added to output, because the Full output option is enabled',
			delete: {
				confirm: {
					title: 'Delete this field?',
					body: 'Are you sure you want to delete this field?',
					bodyMultiple:
						'Are you sure you want to delete this field? Child fields will be deleted as well.',
					confirmButtonText: 'Delete',
				},
			},
		},
		writeBlock: {
			chooseAction: 'Choose action',
			chooseConnection: 'Choose connection',
			field: 'Field',
			flowField: 'Flow field',
			systemField: 'System field',
			required: 'Required',
			isKey: 'Is key field',
			table: {
				noResults: 'Please choose an Action first',
			},
		},
		externalConnectorBlock: {
			chooseConnection: 'Choose Connection',
			chooseSystem: 'Choose System',
			field: 'Field',
			flowField: 'Flow field',
			systemField: 'System field',
			required: 'Required',
			table: {
				noResults: 'Please choose system and connection first',
			},
		},
		checkBlock: {
			conditions: 'Conditions',
			addConditionGroup: 'Add condition group',
			addCondition: 'Add condition',
			noConditions: 'No conditions configured',
			conditionsTable: {
				columns: {
					field: 'Field',
					fieldType: 'Field type',
					operator: 'Operator',
					type: 'Type',
					value: 'Value(s)',
				},
				noResults: 'No conditions configured',
			},
			conditionGroupDelete: {
				confirm: {
					title: 'Delete this group?',
					body: 'Are you sure you want to delete this condition group? This action can not be undone.',
					confirmButtonText: 'Delete',
				},
			},
			conditionDelete: {
				confirm: {
					title: 'Delete this condition?',
					body: 'Are you sure you want to delete this condition? This action can not be undone.',
					confirmButtonText: 'Delete',
				},
			},
		},
		errorBlock: {
			errorCode: 'Error code',
			setErrorCode: 'Set error code',
			errorMessage: 'Error message',
			setErrorMessage: 'Set error message',
		},
		configBlock: {
			pasteJson: 'Paste JSON here',
			enterValidJson: 'Please enter valid JSON',
			formatJson: 'Format JSON',
			jsonNotSaved: 'Configuration is not saved, because the JSON could not be parsed',
			functionGuid: 'Function guid',
			type: 'Object type',
			functionName: 'Function name',
			className: 'Class name',
			objectGuid: 'Object guid',
			guid: 'Action guid',
			properties: 'Properties',
			input_mapping: 'Input mapping',
			config: 'Config',
		},
		configDetails: {
			confirmClose: {
				title: 'Close without saving?',
				body: 'Are you sure you want to close? Your changes are not saved and will be lost if you close this drawer.',
				confirmButtonText: 'Close',
			},
		},
	},
	startFieldDetails: {
		saveButton: 'Save changes',
	},
	addFieldDetails: {
		saveButton: 'Save changes',
		chooseFieldValue: 'What should be the value of the field?',
		chooseSystemValue: 'Choose system value',
		selectFunction: 'Select function',
		valueOptions: {
			system: 'A system value',
			static: 'A static value',
			function: 'Calculated value from a chosen function',
		},
	},
	predefinedFields: {
		chooseConnection: 'Choose connection',
		document: 'Select document',
		drawerName: 'Predefined field list',
		addButton: 'Add selected field(s)',
		newAdded: '0 fields added | 1 field added | {count} fields added',
		fieldsSelected: 'Fields selected: {count}',
		table: {
			noResults: 'Please select a document',
			disabledExplanation:
				'Can not be selected because a field with this name or ancestors name already exists',
			columns: {
				field_name: 'Field name',
				field_type: 'Type',
			},
		},
	},
	resultFields: {
		drawerName: 'Available fields',
		addButton: 'Add selected field(s)',
		newAdded: 'Added field <i>{field}</i>',
		fieldsSelected: 'Fields selected: {count}',
		table: {
			noResults: 'No available fields',
			disabledExplanation: 'Field is already added',
			columns: {
				field_name: 'Field name',
				field_type: 'Type',
			},
		},
	},
	actionSortingFields: {
		drawerName: 'Available sorting fields',
		addButton: 'Add selected field(s)',
		newAdded: 'Added sorting field <i>{field}</i>',
		fieldsSelected: 'Fields selected: {count}',
		table: {
			noResults: 'No available fields',
			disabledExplanation: 'Field is already added',
			columns: {
				field_name: 'Field name',
				field_type: 'Type',
			},
		},
	},
	functions: {
		drawerName: 'Functions',
		connections: 'Connections',
		loadingError: 'An error occured while loading your chosen function',
		mapping: {
			chooseField: 'Choose field',
			configureMapping: 'Configure mapping for chosen function',
			columns: {
				systemField: 'System field',
				flowField: 'Flow field',
				required: 'Required',
				type: 'Type',
				key: 'Is key field',
			},
		},
		table: {
			noResults: 'No available functions',
			columns: {
				field_name: 'Name',
				field_description: 'Description',
				field_output: 'Output type',
				connection: 'Connection',
			},
		},
	},
	functionLists: {
		drawerName: 'Reference list tools',
		connections: 'Connections',
		table: {
			noResults: 'No available tools',
			columns: {
				field_name: 'Name',
				field_key: 'Match by key field',
				field_input: 'Input type',
				field_validation: 'Validation',
				connection: 'Connection',
			},
		},
	},
	flowErrors: {
		flowNameEmpty: 'Flow name can not be empty',
		flowNameIsShort: 'Flow name should be at least 2 characters',
		flowNameContainsSpace: 'Flow name contains a space',
		flowNameDoesNotMatchRegex: 'Flow name can only contain letters, numbers, underscores and forward slashes. It can not start or end with a forward slash, or contain double forward slashes.',
		fieldTypeMissing: 'No type selected for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		duplicatedName: '<strong>{fieldName}</strong> is used more than one time in <strong>{block}</strong>',
		duplicatedOutputName: '<strong>{block}</strong> outputs <strong>{fieldName}</strong> more than one time',
		duplicatedNameInFlowVars: '<strong>{fieldName}</strong> already used in the flow in <strong>{block}</strong>',
		duplicatedAlias: '<strong>{fieldName}</strong> alias is used more than one time in <strong>{block}</strong>',
		defaultValueOfStaticMissing: 'No default value of static default for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		defaultValueMissing: 'No default value for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		defaultFunctionMissing: 'Choose a function for default value for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		noOperatorChosen: 'Choose an operator for the condition you added for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		noOperatorValue: 'Add value for operator <strong>({operator})</strong> for the condition you added for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		noMappingFields: 'Not all requireds fields for <strong>{externalConnectorName}</strong> are mapped in <strong>{block}</strong>',
		defaultFunctionMappingMissing: 'Not all requireds fields for <strong>{fieldFunction}</strong> in <strong>{fieldName}</strong>  are mapped in <strong>{block}</strong>',
		noMappingByKeyField: 'Select a flow field to map with, for at least one key field in matching in <strong>{block}</strong>',
		noMappingByKeyFieldInWrite: 'Select a flow field to map with, for at least one key field in <strong>{block}</strong>',
		typeNotExist: 'Type <strong>({type})</strong> is not allowed for <strong>{fieldName}</strong> in <strong>{block}</strong>',
		FunctionMappingInputNotExist: 'The flow field <strong>{mappingObject}</strong> does not exist in the flow in <strong>{block}</strong>',
		inputOfOutputNotExist: 'In <strong>{fieldName}</strong> in function <strong>{fieldFunction}</strong> the flow field <strong>{mappingObject}</strong> does not exist in the flow in <strong>{block}</strong>',
		actionMappingInputNotExist:'In action <strong>{action}</strong> the flow field <strong>{mappingObject}</strong> does not exist in the flow in <strong>{block}</strong>',
		ExternalConnectorMappingInputNotExist: 'In <strong>{externalConnectorName}</strong> the flow field <strong>{mappingObject}</strong> does not exist in the flow in <strong>{block}</strong>',
		nameIsEmpty: 'There is one field without a name in <strong>{block}</strong>',
		nameIsShort: 'Field <strong>{fieldName}</strong> name should be at least 2 characters in <strong>{block}</strong>',
		nameContainsSpace: '<strong>{fieldName}</strong> contains a whitespace(s) in <strong>{block}</strong>',
		aliasIsEmpty: 'There is one field without a alias in <strong>{block}</strong>',
		aliasContainsSpace: '<strong>{fieldName}</strong> alias contains a whitespace(s) in <strong>{block}</strong>',
		aliasIsShort: 'Field <strong>{fieldName}</strong> alias should be at least 2 characters in <strong>{block}</strong>',
		functionListNotExist: 'Function for validation with name <strong>{fieldFunction}</strong> does not exist anymore in <strong>{fieldName}</strong> in <strong>{block}</strong>',
		connectionGuidNotMatch: 'Connection guid in function for validation with the name <strong>{fieldFunction}</strong> does not match the connection guid in <strong>{fieldName}</strong> in <strong>{block}</strong>',
		typeNotMatchInFunctionList: 'Type of key input in validation function <strong>{fieldFunction}</strong> does not match the type of <strong>{fieldName}</strong> in <strong>{block}</strong>',
		maxLengthNotMatchInFunctionList: 'Max length in <strong>{objectDefinition}</strong> ({objectDefinitionMaxLength}) does not match the validation of <strong>{fieldName}</strong> ({fieldMaxLength}) in <strong>{block}</strong>',
		maxLengthNotMatchInObjectDefinition: 'Max length of <strong>{fromField}</strong> ({fromFieldMaxLength}) not set or exceeds the max length of <strong>{toField}</strong> ({toFieldMaxLength}) in <strong>{block}</strong>',
		maxLengthNotMatchInAction: 'Max length of <strong>{fromField}</strong> ({fromFieldMaxLength}) not set or exceeds the max length of <strong>{toField}</strong> ({toFieldMaxLength}) in <strong>{block}</strong>',
		functionNotExist: 'Function with name <strong>{fieldFunction}</strong> does not exist anymore in <strong>{fieldName}</strong> in <strong>{block}</strong>',
		typeNotMatchInFunction: 'Type of input in function <strong>{fieldFunction}</strong> does not match the type of <strong>{mappingObject}</strong> in <strong>{fieldName}</strong> in <strong>{block}</strong>',
		typeNotMatchInAction: 'Type of input in action <strong>{action}</strong> does not match the type of <strong>{mappingObject}</strong> in <strong>{block}</strong>',
		actionNotExist: 'Action with name <strong>{action}</strong> does not exist anymore in <strong>{block}</strong>',
		actionNotChosen: 'Select an action in <strong>{block}</strong>',
		actionMappingMissing: 'Not all requireds fields for <strong>{action}</strong> are mapped in <strong>{block}</strong>',
		noMappingByFields: 'Select at least two flow fields to map with two system fields in <strong>{block}</strong>',
		noErrorCode: 'Set an error code in <strong>{block}</strong>',
		noConfigJson: 'Please fill in the config field <strong>{block}</strong>',
		noPropertiesJson: 'Please fill in the properties field <strong>{block}</strong>',
		noInputMappingJson: 'Please fill in the input mapping field <strong>{block}</strong>',
		externalConnectorNotChosen: 'Select an connection in <strong>{block}</strong>',
		externalConnectorNotExist: 'External Connector with name <strong>{externalConnectorName}</strong> does not exist anymore in <strong>{block}</strong>',
		typeNotMatchInExternalConnector: 'Type of input in external connector <strong>{externalConnectorName}</strong> does not match the type of <strong>{mappingObject}</strong> in <strong>{block}</strong>',
		maxLengthNotMatchInExternalConnector: 'Max length of <strong>{fromField}</strong> ({fromFieldMaxLength}) not set or exceeds the max length of <strong>{toField}</strong> ({toFieldMaxLength}) in <strong>{block}</strong>',
		noConditionGroup: 'Add some condition group in <strong>{block}</strong>',
		noCondition: 'There is empty condition group(s) in <strong>{block}</strong>',
		fieldInConditionNotExist: 'The flow field <strong>{fieldInCondition}</strong> does not exist in the flow in <strong>{block}</strong>',
		fieldValueInConditionNotExist: 'The flow field <strong>{fieldInCondition}</strong> used for the value does not exist in the flow in <strong>{block}</strong>',
		typeNotMatchInCondition: 'In condition type of input <strong>{fieldName}</strong> does not match the type of <strong>{fieldValue}</strong> in <strong>{block}</strong>',
		fieldInConditionNotExistInAction: 'The field <strong>{fieldInCondition}</strong> does not exist in the action in <strong>{block}</strong>',
		wrongOperatorChosen: 'Wrong operator for the condition you added for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		wrongValueAmountForOperator: 'Add value for operator <strong>({operator})</strong> for the condition you added for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		wrongValueOrderForOperatorBetween: 'Value 1 is greater than Value 2 for operator <strong>({operator})</strong> for the condition you added for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		noSortDirection: 'In Sort order choose direction for field <strong>{fieldName}</strong> in <strong>{block}</strong>',
		noFieldsOrFullOutput: 'Please choose at least one field or check full output in <strong>{block}</strong>',
		restMethodFlowExist: 'Flow already exist with the same name, method and REST API',
	},
	blockTypes: {
		add: 'Add block',
		check: 'Check block',
		close: 'Close block',
		external: 'External block',
		read: 'Read block',
		error: 'Error block',
		result: 'Result block',
		start: 'Start block',
		write: 'Write block',
		config: 'Config block',
	},
	flowOptions: {
		back: 'Back to flow overview',
		flowName: 'Flow name',
		confirmLeave: {
			title: 'Your changes are not saved or published yet',
			body: 'Are you sure you want to go back to the flow overview? This means that all your changes will be discarded.',
			confirmButtonText: 'Yes, go back to the overview',
		},
	},
	flow: {
		savedSuccessfully: 'Your flow has been successfully saved',
		publish: {
			error: 'Something went wrong while publishing flow. Please try again.',
			success: 'Flow was succesfully published',
			confirm: {
				title: 'Publish this version?',
				body: 'Are you sure you want to publish this version of the flow? It will overwrite the current published version, and this action can not be undone.',
				confirmButtonText: 'Publish',
			},
		},
	},
	flowSummary: {
		lastTimePublished: 'Last time published: {date}',
		restApi: 'REST API:',
		method: 'Method:',
		status: 'Status:',
	},
	flowHistory: {
		flowHistory: 'Flow history',
		table: {
			noResults: 'No records',
			inspectFlow: 'Inspect',
			duplicateFlow: 'Duplicate',
			deleteFlow: 'Delete',
			columns: {
				publish_date: 'Publish date',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
	},
	flowDelete: {
		error: 'Something went wrong while deleting flow. Please try again.',
		success: 'Flow was succesfully deleted',
		confirm: {
			title: 'Delete this version?',
			body: 'Are you sure you want to delete this version of the flow? This action can not be undone.',
			confirmButtonText: 'Delete',
		},
	},
	flowStatusUpdate: {
		error: 'Something went wrong while updating flow status. Please try again.',
		success: 'Flow status was succesfully updated',
		confirmActivate: {
			title: 'Activate this flow?',
			body: 'Are you sure you want to activate this flow?',
			confirmButtonText: 'Activate',
		},
		confirmDeactivate: {
			title: 'Dectivate this flow?',
			body: 'Are you sure you want to deactivate this flow?',
			confirmButtonText: 'Deactivate',
		},
	},
	welcomeFlow: {
		title: 'Flow Builder',
		newFlowButton: 'Create new flow',
		search: 'Search',
		restApis: "REST API's",
		status: 'Status',
		filter: {
			all: 'All',
			active: 'Active',
			published: 'Published',
		},
		table: {
			noResults: 'No records',
			columns: {
				flow_name: 'Flow name',
				rest_api: 'REST API',
				method: 'Method',
				last_time_edited: 'Last time edited',
				status: 'Status',
			},
		},
	},
	newFlow: {
		title: 'Create new flow',
		whatMethod: 'Which method does the new flow use?',
		whatName: 'Give your flow a name',
		nameInputLabel: 'Flow name',
		isScript: 'Uses Script Service',
		isScriptInfo: 'Enable option if the flow uses the Script Service Workflow',
		whatApi: 'Which REST API will the flow be part of?',
		selectApi: 'Select REST API',
		submitButton: 'Next, make the flow',
		createdSuccess: 'Successfully created a new flow',
	},
	flowDuplicate: {
		success: 'Flow was succesfully duplicated',
		confirm: {
			title: 'Duplicate this version?',
			body: 'Are you sure you want to duplicate this version of the flow?',
			confirmButtonText: 'Duplicate',
		},
		blocked: {
			title: 'Not possible to duplicate',
			body: 'It is not possible to create a new draft because this flow already has a draft version. Delete or publish that draft and then try again.',
			confirmButtonText: 'Ok',
		},
	},
	actionMethod: {
		read: 'Read',
		write: 'Write',
		upsert: 'UpSert',
		createifnotexists: 'Create if not exists',
		createifnotexistsorstopifexists: 'Create if not exists or stop if exists',
		createalways: 'Create always',
	},
	readFilterDrawer: {
		drawerName: 'Configure filter',
		saveButton: 'Save',
	},
	checkConditionDrawer: {
		drawerName: 'Configure condition',
		saveButton: 'Save',
	},
	conditions: {
		chooseField: 'Choose field',
		chooseOperator: 'Choose operator',
		chooseType: 'Choose type',
		static: 'Static',
		dynamic: 'Dynamic',
		valueLabel: 'Value | Value | Value {index}',
		operators: {
			equals: 'Equals',
			notEquals: 'Not equals',
			greaterThan: 'Greater than',
			greaterEquals: 'Greater / Equals',
			smallerThan: 'Smaller than',
			smallerEquals: 'Smaller / Equals',
			between: 'Between',
			contains: 'Contains',
			notContains: 'Not contains',
			in: 'In',
			notIn: 'Not in',
			isSet: 'Is set',
			notSet: 'Not set',
			isTrue: 'Is true',
			isFalse: 'Is false',
			startsWith: 'Starts with',
			endsWith: 'Ends with',
			maxLength: 'Max length',
			minLength: 'Min length',
		},
	},
	permissions: {
		label: 'Flow builder',
		info: 'Permissions connected to the Flow builder',
		readFlow: {
			label: 'Read flow',
			info: 'Permission to view all flows',
		},
		editFlow: {
			label: 'Edit flow',
			info: 'Permission to create and edit flows',
		},
		deleteFlowDraft: {
			label: 'Delete flow draft',
			info: 'Permission to delete any flow draft',
		},
		publishFlow: {
			label: 'Publish flow',
			info: 'Permission to publish any flow',
		},
	},
};
