// naming used in definition of Api Users
export const apiUserMeta = {
	api_token: {
		name: 'ApiToken',
		default: '',
	},
	guid: {
		name: 'UserGuid',
		default: '',
	},
	username: {
		name: 'UserName',
		default: '',
	},
	is_active: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_api_user: {
		name: 'IsApiUser',
		type: 'boolean',
		default: true,
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	is_deleted: {
		name: 'IsDeleted',
		type: 'boolean',
		default: false,
	},
	roles: {
		name: 'RoleGuids',
		type: 'array',
		default: [],
	},
};

// naming used in definition of REST API List
export const restApiMeta = {
	guid: {
		name: 'FlowRestApiGuid',
		default: '',
	},
	name: {
		name: 'Name',
		default: '',
	},
	description: {
		name: 'Description',
		type: 'string',
		default: '',
	},
	return_type: {
		name: 'ReturnType',
		type: 'string',
		default: '',
	},
	is_active: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	is_internal: {
		name: 'IsInternal',
		type: 'boolean',
		default: false,
	},
	api_authentication: {
		name: 'ApiAuthentication',
		type: 'string',
		default: '',
	},
	users: {
		name: 'Users',
		type: 'array',
		default: [],
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	settings: {
        name: 'Settings',
        default: null,
    },
	is_new: {
		name: 'IsNew',
		type: 'boolean',
		default: false,
		omitOnConstruct: true,
	},
};

// File exports all available Endpoints

const apiBase = 'UIServices/RestApi';

// Api Users
export const GET_API_USERS = {
	path: 'GetApiUsers',
	realm: apiBase,
	meta: apiUserMeta,
};

export const POST_API_USER = {
	path: 'PostApiUser',
	method: 'post',
	realm: apiBase,
	meta: apiUserMeta,
};

export const DELETE_API_USER = {
	path: 'DeleteApiUser/{guid}',
	method: 'delete',
	realm: apiBase,
};

// Get REST API
export const GET_REST_API_LIST = {
	path: 'GetRestApi',
	realm: apiBase,
	meta: restApiMeta,
};

export const GET_REST_API_BY_ID = {
	path: 'GetRestApiByGuid/{guid}',
	realm: apiBase,
	meta: restApiMeta,
};

// api return types
export const GET_API_RETURN_TYPES = {
	path: 'getApiReturnType',
	realm: apiBase,
};

// api authentications
export const GET_API_AUTHENTICATIONS = {
	path: 'GetApiAuthentication',
	realm: apiBase,
};

// Save or publish REST API
export const POST_REST_API = {
	path: 'PostRestApi',
	method: 'post',
	realm: apiBase,
	meta: restApiMeta
};

// Delete REST API
export const DELETE_REST_API = {
	path: 'DeleteRestApi/{guid}',
	method: 'delete',
	realm: apiBase,
};