import {
    defineAbility,
    AbilityBuilder,
    Ability,
} from '@casl/ability';

import useJWT from '@assets/scripts/composables/useJWT';

const { permissions, is_admin } = useJWT();

// create empty ability object
// actual abilities will be added later, based
// on current user
const ability = defineAbility(() => { });

/**
 * Used to set abilities (permissions) based on
 * the user
 *
 * @returns {void}
 */
export const setAbility = () => {
    const { can, rules } = new AbilityBuilder(Ability);
    if (is_admin.value) {
        // administrator can do everything
        can('manage', 'all');
    } else if (permissions.value) {
        // loop over permissions of user
        for (const module in permissions.value) {
            for (const permission in permissions.value[module]) {
                // grant permission in context
                can(permission, module);
            }
        }
    }

    // set new abilities, these overwrite all
    // currently set abilities
    ability.update(rules);
};

export const userHasAccess = (route) => {
    return (
        !route.meta.permission ||
        ability.can(route.meta.permission, route.meta.module)
    );
};

export default ability;
