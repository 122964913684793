<template>
    <a
        v-if="item.isExternal"
        class="menu-anchor"
        :href="item.href"
        @click.prevent.stop="() => externalLinkClicked(item.href)"
    >
        <VMenuItemContent
            :icon="item.meta.icon"
            :name="item.meta.title"
        />
    </a>
    <RouterLink
        v-else
        :to="item.path"
        class="menu-anchor"
        active-class="is-active"
    >
        <VMenuItemContent
            :icon="item.meta.icon"
            :name="item.meta.title"
        />
    </RouterLink>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreMutation,
    getStoreGetter,
} from '@assets/scripts/store/config';

export default {
	name: 'SideNavigationLink',
    props: {
		item: {
			type: Object,
            required: true,
		}
	},
    computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether data loss is possible if user
			 * reloads, leaves, logs out, etc.
			 */
			dataLossPossible: getStoreGetter('DATA_LOSS_POSSIBLE'),
		}),
	},
	methods: {
		externalLinkClicked: function (href) {
			const go = () => {
				window.location = href;
			};

			if (!this.dataLossPossible) {
				go();
				return;
			}

			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('sideNavigation.leave.confirm.title'),
				body: this.$t('sideNavigation.leave.confirm.body'),
				confirmButtonText: this.$t(
					'sideNavigation.leave.confirm.confirmButtonText'
				),
				cancelButtonText: this.$t(
					'sideNavigation.leave.confirm.cancelButtonText'
				),
				confirmFn: () => {
					go();
				},
			});
		},
	},
};
</script>
