import i18n from '@assets/i18n';
const TheDocumentEditor = () => import('@modules/DocumentEditor/materials/TheDocumentEditor.vue');

// translate function of vue-i18n
const { t } = i18n.global;

export default [
	{
		path: '/documenteditor',
		component: TheDocumentEditor,
		name: 'documentEditor',
		meta: {
			title: t('de.pageTitle'),
			permission: 'Read',
			showInSideNav: true,
			showInHome: true,
			icon: 'edit',
			parent: 'management',
			module: 'DocumentEditor',
			order: 2,
		},
	},
];