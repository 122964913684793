/**
 * File offers functionality to handle Nominows 'Function Lists'
 * These are simple functions that return a single value gotten from
 * a look up based on the input. For instance get the full country
 * name (The Netherlands) based on the given iso code (nl)
 */
import Helpers from '@assets/scripts/helpers';
import Field from '@assets/scripts/components/field';
import { functionListRefFieldsMeta, GET_FUNCTION_LIST_BY_ID } from '@assets/scripts/api/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

const getFunctionList = async (guid) => {
	// load Function List
	return await useApiAsync(GET_FUNCTION_LIST_BY_ID, {
		keys: {
			guid: guid
		}
	});
};

/**
 * Returns the variables returned by a Function List
 * that is possibly referenced in the given config
 *
 * @param {Object} input
 *  Variable from block config (normalized) that
 *  might contain a reference to a Function List
 *
 * @returns {Array}
 *  Array of found variables
 */
export const getVariablesFromFunctionList = async (input) => {
	// check if ref to Function List exists by
	// getting the GUID
	const guid = Helpers.obj.getProp(
		'validation|element|ref|guid',
		input,
		false
	);

	// return false if no guid found
	if (!guid) return false;

	// prepare default result
	const result = [];

	// load Function List
	const list = Helpers.cloneObject(await getFunctionList(guid));

	if (list) {
		/**
		 * Variables returned by a Function List should be added to the
		 * flow as siblings of the configured field that uses the Function List
		 */

		// get key field of Function List
		const listKeyField = getKeyFieldForFunctionList(list);

		// add found variables to result
		Helpers.obj.getProp('fields', list, []).forEach((field) => {
			// if field is the key field of the Function List, and its
			// name matches the name of the configured field that uses the
			// Function List, do not add the field to the result
			// this to prevent having 2 Flow Variables named GenderCode for instance
			if (
				field === listKeyField &&
				field.name.toLowerCase() === Field.getChildName(input.name).toLowerCase()
			) return;

			result.push(Field.makeSibling(input, field));
		});
	}

	return result;
};

/**
 * Get info about Function Lists formatted for
 * use in FunctionListsDrawer component table
 *
 * @param {Array} lists
 *  Array of Function Lists definitions
 *
 * @param {Array} connections
 *  Array of Connections
 *
 * @param {String} fieldType
 *  Variable type that user wants to use as input for
 *  Function List. Used to disable all table rows that
 *  require different input type
 *
 * @returns {Array}
 *  Array of objects per table row
 */
export const formatForFunctionListsTable = (lists, connections = [], fieldType = false) => {
	const result = [];

	// loop over function lists
	lists.forEach((list) => {
		const keyField = getKeyFieldForFunctionList(list);
		if (keyField) {
			const { type, name, validation } = keyField;

			result.push({
				guid: list.guid,
				field_name: list.name,
				field_key: name || false,
				field_input: type ? Field.translateVarType(type) : false,
				field_validation:
					typeof validation.max === 'number'
						? t('validation.maxlengthWithValue', {
								max: validation.max,
						})
						: false,
				conn_guid: list.conn_guid || false,
				connection: Helpers.getPropValueByProp(connections, 'conn_guid', 'name', list.conn_guid || false),
				disabled: fieldType && type && type !== fieldType,
			});
		}
	});

	return result;
};

/**
 * Checks if the Function List with a given GUID is
 * available for the given var type
 *
 * @param {String} guid
 *  GUID of Function List
 *
 * @param {String} fieldType
 *  Var type to check
 *
 * @returns {Boolean}
 */
export const functionListAvailableForType = async (guid, fieldType) => {
	// load Function List
	const list = await getFunctionList(guid);

	// check if Function list exists
	if (!list) return false;

	// get type of Key field of Function list
	const { type } = getKeyFieldForFunctionList(list);

	// compare key field type and given field type
	return type === fieldType;
};

/**
 * Returns the key field from the Function List
 *
 * @param {Object} list
 *  Instance of Function List
 *
 * @returns {Object}
 *  Found key field or false
 */
export const getKeyFieldForFunctionList = (list) => {
	let result = false;

	list.fields.some((field) => {
		if (field.is_key) {
			result = field;
			return true;
		}
	});

	return result;
};

/**
 * Returns the map field from the Function List
 *
 * @param {Object} list
 *  Instance of Function List
 *
 * @returns {Object}
 *  Found key field or false
 */
const getMapFieldForFunctionList = (list) => {
	let result = false;

	list.fields.some((field) => {
		if (field.is_map) {
			result = field;
			return true;
		}
	});

	return result;
};

/**
 * Adds a given list to validation of given field
 * as reference
 *
 * @param {Object} list
 *  Instance of Function List
 *
 * @param {Object} field
 *  Field to set list as reference in
 *
 * @returns {void}
 */
export const setFunctionListAsRef = (list = false, field) => {
	// create empty object
	const functionListRefFields = Helpers.obj.create(functionListRefFieldsMeta, {});
	
	// set values if list is given
	if (list) {
		functionListRefFields.guid = list.guid || '';
		functionListRefFields.conn_guid = list.conn_guid || '';

		const listKeyField = getKeyFieldForFunctionList(list);
		if (listKeyField) functionListRefFields.key = listKeyField.name;

		const listMapField = getMapFieldForFunctionList(list);
		if (listMapField) functionListRefFields.map = listMapField.name;
	}

	// add reference to given field
	Helpers.obj.setProp('validation|element|ref', field, functionListRefFields, true);
};