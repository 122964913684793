<template>
	<DrawerItem
		:id="id"
		:drawerIndex="drawerIndex"
		:title="$t('fb.functions.drawerName')"
		:hasJsonButton="false"
		:hasFullwidthBody="true"
		:closeOnBackgroundClick="true"
		:hasScrollableContent="true"
	>
		<div class="content-header has-padding has-bottom-divider">
			<div class="field is-grouped is-flex-grow-1">
				<VField
					:label="$t('fb.functions.connections')"
					:isFloating="true"
					class="has-margin-right-1"
					:class="{ 'is-active': connectionValue }"
				>
					<VSelect :options="connectionOptions" v-model="connectionValue" />
				</VField>

				<VSearch v-model="search" />
			</div>
		</div>
		<SortableDataTable
			:rowClickedFn="rowClicked"
			defaultSort="field_name"
			tableClasses="scrollable-content"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('fb.functions.table.noResults')"
		/>
	</DrawerItem>
</template>

<script>
import Helpers from '@assets/scripts/helpers';
import { getStoreMutation } from '@assets/scripts/store/config';
import { formatForFunctionsTable } from '@modules/FlowBuilder/components/functions';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_FUNCTIONS } from '@modules/FlowBuilder/endpoints';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'FunctionsDrawer',
	props: {
		/**
		 * Index of this drawer
		 */
		drawerIndex: {
			type: Number,
			required: true,
			default: 0,
		},
		/**
		 * Unique key of this drawer
		 */
		id: {
			type: String,
			required: true,
		},
		/**
		 * Variable type the drawer is opened for
		 */
		config: {
			default: {
				type: false,
				callback: () => {},
			},
		},
	},
	data: function () {
		return {
			functions: [],
			connections: [],
			// default value for Connection filter
			connectionValue: this.$t('general.all'),
			// values for Connection filter, will be extended
			// on mount with all available Connections
			connectionOptions: [
				{
					value: this.$t('general.all'),
					text: this.$t('general.all'),
				},
			],
			search: '',
			columns: [
				{
					label: this.$t('fb.functions.table.columns.field_name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.table.columns.field_description'),
					field: 'field_description',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('fb.functions.table.columns.field_output'),
					field: 'field_output',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t(
						'fb.functions.table.columns.connection'
					),
					field: 'connection',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
			],
		};
	},
	mounted: async function () {
		// get all available functions
		this.functions = await useApiAsync(GET_FUNCTIONS);

		// get all available connections
		this.connections = await useApiAsync(GET_CONNECTIONS);

		if (this.connections && this.connections.length > 0) {
			// map Connection objects to use in select field
			this.connectionOptions.push(
				...Helpers.mapObjectArrayForSelect(
				this.connections,
					{
						key: 'conn_guid',
						text: 'name',
					}
				)
			);
		}
	},
	computed: {
		tableFields: function () {
			return formatForFunctionsTable(
				this.functions,
				this.connections,
				this.config.type || false
			);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all table rows
			const newData = this.tableFields.filter((row) => {
				// filter on Connection
				return  Helpers.filterByValue(
					row,
					'conn_guid',
					this.connectionValue,
					this.$t('general.all')
				);
			});

			// return if no rows match
			if (this.search.length === 0 || newData.length === 0) return newData;

			// filter on search string
			return Helpers.filterByString(
				newData,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		closeDrawer: function () {
			this.$store.commit(getStoreMutation('CLOSE_DRAWER'), this.id);
		},
		getRowClass: function (row) {
			return row.disabled ? 'is-disabled' : false;
		},
		rowClicked: function (row) {
			if (row.disabled) return false;

			// use callback fn with Function
			// GUID as param
			this.config.callback(row.guid);

			// close drawer
			this.closeDrawer();
		},
	},
};
</script>
