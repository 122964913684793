import { getStoreGetter } from '@assets/scripts/store/config';
import { IS_DEV } from '@assets/scripts/helpers';
import { isEmpty } from 'lodash';
import { constructFullRole } from '@modules/UserManagement/components/role';

export const names = {
	ROLE_MODIFIED: 'roleModified',
	USER_MODIFIED: 'userModified',
	CURRENT_ROLE: 'currentRole',
	CURRENT_USER: 'currentUser',
	DATA_LOSS_POSSIBLE_HOOK: 'dataLossPossible',
	FULL_ROLE: 'fullRole',
};

export default {
	[names.ROLE_MODIFIED]: ({ roleModified }) => {
		return roleModified;
	},
	[names.USER_MODIFIED]: ({ userModified }) => {
		return userModified;
	},
	[names.CURRENT_ROLE]: ({ currentRole }) => {
		return currentRole;
	},
	[names.CURRENT_USER]: ({ currentUser }) => {
		return currentUser;
	},
	[names.DATA_LOSS_POSSIBLE_HOOK]: (
		state,
		getters,
		rootState,
		rootGetters
	) => {
		return (
			rootGetters[getStoreGetter('ROLE_MODIFIED', 'USER')]
		);
	},
	[names.FULL_ROLE]: ({ currentRole }) => {
		return !isEmpty(currentRole) && IS_DEV ? constructFullRole(currentRole) : {};
	},
	debug: (state) => {
		// output whole state for debugging since Vue Devtools plugin
		// not (always) shows the updated state of the store, but it
		// does show the correct value of all getters
		return IS_DEV ? state : {};
	},
};
