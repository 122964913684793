import {
	defaultValueMeta,
	validationMeta,
} from '@assets/scripts/api/config';

export const actionDocumentReferenceMeta = {
	name: {
		name: 'DocumentName',
		default: '',
	},
	guid: {
		name: 'DocumentGuid',
		default: '',
	},
	key: {
		name: 'KeyField',
		default: '',
	},
	child: {
		name: 'ChildField',
		default: '',
	},
};

export const documentFieldMeta = {
	type: {
		name: 'Type',
		type: 'string',
		default: false,
	},
	name: {
		name: 'Name',
		type: 'string',
		default: false,
	},
	is_id: {
		name: 'IsIdentity',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	is_key: {
		name: 'IsKey',
		type: 'boolean',
		default: false,
	},
	is_updatable: {
		name: 'IsUpdatable',
		type: 'boolean',
		default: false,
	},
	is_filter: {
		name: 'IsFilter',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	is_search: {
		name: 'IsSearchField',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	is_map: {
		name: 'IsMapping',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['list'],
	},
	is_returnable: {
		name: 'IsReturnable',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['list'],
	},
	is_new: {
		name: 'IsNew',
		type: 'boolean',
		default: false,
		restrictToSelectors: [],
	},
	order: {
		name: 'SortOrder',
		type: 'number',
		default: null,
		omitOnConstruct: true,
	},
	validation: validationMeta,
	default: {
		...defaultValueMeta
	},
};

documentFieldMeta.default.children.insert = {
	name: 'Insert',
	type: 'boolean',
	default: false,
	restrictToSelectors: ['action'],
};

documentFieldMeta.default.children.update = {
	name: 'Update',
	type: 'boolean',
	default: false,
	restrictToSelectors: ['action'],
};

export const connectionDocumentMeta = {
	name: {
		name: 'Name',
		default: '',
	},
	id: {
		name: '_id',
		default: '',
		omitIfEmpty: true,
	},
	guid: {
		name: 'DocumentGuid',
		default: '',
	},
	description: {
		name: 'Description',
		default: '',
	},
	type: {
		name: 'DocumentType',
		default: '',
	},
	conn_guid: {
		name: 'FlowConnectionGuid',
		default: '',
	},
	created: {
		name: 'CreatedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	is_published: {
		name: 'IsActive',
		type: 'boolean',
		default: false,
	},
	modified: {
		name: 'LastModifiedDateTime',
		default: false,
		omitIfEmpty: true,
	},
	create_always: {
		name: 'CreateAlways',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	create_if_not_exists: {
		name: 'CreateIfNotExists',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	upsert: {
		name: 'UpSert',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	read: {
		name: 'Read',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	create_or_stop: {
		name: 'CreateIfNotExistsOrStopIfExists',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	only_composed: {
		name: 'IsOnlyPartOfComposedDocument',
		type: 'boolean',
		default: false,
		restrictToSelectors: ['action'],
	},
	references: {
		name: 'References',
		type: 'array',
		default: [],
		restrictToSelectors: ['action'],
		children: actionDocumentReferenceMeta,
	},
	actions: {
		name: 'DocumentActions',
		type: 'object',
		default: {},
		omitIfEmpty: true,
		restrictToSelectors: ['action'],
		children: {
			create_always: {
				name: 'CreateAlways',
				default: '',
				omitIfEmpty: true,
			},
			upsert: {
				name: 'UpSert',
				default: '',
				omitIfEmpty: true,
			},
			create_if_not_exists: {
				name: 'CreateIfNotExists',
				default: '',
				omitIfEmpty: true,
			},
			read: {
				name: 'Read',
				default: '',
				omitIfEmpty: true,
			},
			create_or_stop: {
				name: 'CreateIfNotExistsOrStopIfExists',
				default: '',
				omitIfEmpty: true,
			},
		},
	},
	fields: {
		name: 'Elements',
		type: 'array',
		default: [],
		restrictToSelectors: ['action', 'list'],
		children: documentFieldMeta,
	},
};

// File exports all available Endpoints
const apiBase = 'UIServices/DocumentBuilder';

// Connection Documents
export const GET_DOCUMENTS = {
	path: 'GetConnectionDocuments/{connection}',
	realm: apiBase,
	meta: connectionDocumentMeta,
};

// Connection Documents by type
export const GET_DOCUMENTS_BY_TYPE = {
	path: 'GetConnectionDocumentsByType/{connection}',
	realm: apiBase,
	parameters: {
		type: 'Type'
	},
	meta: connectionDocumentMeta,
};

// Connection Document
export const GET_DOCUMENT = {
	path: 'GetConnectionDocument/{connection}/{guid}',
	realm: apiBase,
	meta: connectionDocumentMeta,
};

// Save or publish Connection Document
export const POST_DOCUMENT = {
	path: 'PostConnectionDocument/{connection}/{guid}',
	method: 'post',
	realm: apiBase,
};

// Delete Connection Document
export const DELETE_DOCUMENT = {
	path: 'DeleteConnectionDocument/{connection}/{guid}',
	method: 'delete',
	realm: apiBase,
};